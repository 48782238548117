import { fetchBaseApi } from "../../../Infrastructure/Api/BaseApi";
import { RootState } from "../../../Infrastructure/Redux/store";
import { FeedbackRequest } from "../Models/FeedbackRequest";
import { FeedbackResponse } from "../Models/FeedbackResponse";

export const postFeedback = async (
  request: FeedbackRequest,
  dispatch: (action: { payload: undefined; type: string }) => {
    payload: undefined;
    type: string;
  },
  rootState: RootState
): Promise<FeedbackResponse> => {
  var feedbackResult = await fetchBaseApi(request, "feedback", "POST", dispatch, rootState);
  var mappedResult: FeedbackResponse = await mapFeedbackResult(feedbackResult);
  return mappedResult;
};

const mapFeedbackResult = async (feedbackResponse: any): Promise<FeedbackResponse> => {
  let feedbackResultToReturn: FeedbackResponse = { success: false };
  const parsedResponse = await feedbackResponse.json();

  feedbackResultToReturn.success = parsedResponse.success;

  return feedbackResultToReturn;
};
