import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { download } from "../../Download/DownloadService";
import { createReport } from "../Api/CreateReport";
import { CreateReportRequestDto } from "../Models/CreateReportRequestDto";
import { RootState } from "../../Redux/store";

export const createReportFromBackend = createAsyncThunk(
  "createReport/initialize",
  async (createReportRequest: CreateReportRequestDto, thunkAPI) => {
    const abortControllerForThisRequest = new AbortController();
    thunkAPI.dispatch(setAbortController(abortControllerForThisRequest));

    try {
      const response = await createReport(
        createReportRequest,
        abortControllerForThisRequest,
        thunkAPI.dispatch,
        thunkAPI.getState() as RootState
      );

      var date = new Date();
      var dateStr =
        date.getFullYear() +
        "_" +
        ("00" + (date.getMonth() + 1)).slice(-2) +
        "_" +
        ("00" + date.getDate()).slice(-2) +
        "_" +
        ("00" + date.getHours()).slice(-2) +
        "_" +
        ("00" + date.getMinutes()).slice(-2) +
        "_" +
        ("00" + date.getSeconds()).slice(-2);

      var reportString = "Report_" + dateStr.toString() + ".zip";

      download(response, reportString);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface CreateReportBackendState {
  abortController: AbortController | null;
}

export const initialStateCrateReportBackendState: CreateReportBackendState = {
  abortController: null,
};

export const createReportSlice = createSlice({
  name: "graphData",
  initialState: initialStateCrateReportBackendState,
  reducers: {
    setAbortController: (state, action: PayloadAction<AbortController>) => {
      state.abortController = action.payload;
    },
  },
});

export default createReportSlice.reducer;
export const { setAbortController } = createReportSlice.actions;
