import { Typography, Button, Grow, CardContent, Card } from "@mui/material";
import { WizardItems } from "../../../../../Infrastructure/Analytics/Model/Categories/WizardEnums";
import { analyse_navigateWizard } from "../../../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Infrastructure/Redux/hooks";
import { increaseCurrentStepId } from "../../Redux/WizardSlice";
import { FiArrowRight } from "react-icons/fi";
import { StartCheckboxList } from "./Components/StartCheckboxList";

const Start = () => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const { checked } = useAppSelector((store) => store.startConfiguration);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Grow in={true} appear={true}>
          <Card square={true} elevation={12} sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%", position: "relative" }}>
              <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                {getTranslatedText("StartHeading")}
              </Typography>
              <div
                style={{ color: isDarkMode ? "#fff" : "#444" }}
                dangerouslySetInnerHTML={{
                  __html: getTranslatedText("StartContextText"),
                }}
              ></div>
              <StartCheckboxList />
              <Button
                color="success"
                variant="contained"
                sx={{ margin: 2, color: "#fff" }}
                disabled={checked.length < 5}
                onClick={() => {
                  dispatch(increaseCurrentStepId());
                  dispatch(
                    analyse_navigateWizard({ currentStep: WizardItems.Start })
                  );
                }}
                className="NextButtonWizardContent"
                endIcon={<FiArrowRight />}
              >
                {getTranslatedText("StartSFA")}
              </Button>
            </CardContent>
          </Card>
        </Grow>
      )}
    </LanguageConsumer>
  );
};

export default Start;
