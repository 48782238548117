import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { SettingsCategories } from "../Model/Categories/SettingsEnums";
import {
  reset_analyse_changeDefaultSettings,
  reset_analyse_changeGraphViewerSettings,
} from "../Redux/AnalyticsSlice";

export const usePublishSettingsAnalytics = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const changeDefaultSettingsState = useAppSelector(
    (store) => store.analytics.changeDefaultSettings
  );
  const changeGraphViewerSettingsState = useAppSelector(
    (store) => store.analytics.changeGraphViewerSettings
  );

  const TrackEvent = (eventName: string, properties: any) => {
    return useTrackEvent(appInsights, eventName, properties, true);
  };

  const trackDefaultSettings = TrackEvent(
    SettingsCategories.Default.toString(),
    {}
  );
  const trackGraphViewerSettings = TrackEvent(
    SettingsCategories.GraphViewer.toString(),
    {}
  );

  useEffect(() => {
    if (changeDefaultSettingsState.toggle) {
      try {
        trackDefaultSettings({
          Setting: changeDefaultSettingsState.setting.toString(),
          NewValue: changeDefaultSettingsState.newValue.toString(),
        });
      } catch {}
      dispatch(reset_analyse_changeDefaultSettings());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeDefaultSettingsState.toggle, dispatch]);

  useEffect(() => {
    if (changeGraphViewerSettingsState.toggle) {
      try {
        trackGraphViewerSettings({
          Setting: changeGraphViewerSettingsState.setting.toString(),
          NewValue: changeGraphViewerSettingsState.newValue.toString(),
        });
      } catch {}
      dispatch(reset_analyse_changeGraphViewerSettings());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeGraphViewerSettingsState.toggle, dispatch]);
};
