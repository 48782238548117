import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

export interface YesNoDialogProps {
  open: boolean;
  title: string;
  titleIcon?: React.ReactNode;
  content: string | null;
  onYesClick: () => void;
  onNoClick: () => void;
  titleYesButton: string;
  iconYesButton?: React.ReactNode;
  titleNoButton: string;
  dangerRight: boolean;
}

export const YesNoDialog = ({
  open,
  title,
  titleIcon,
  titleYesButton,
  iconYesButton,
  titleNoButton,
  onYesClick,
  onNoClick,
  content,
  dangerRight,
}: YesNoDialogProps) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onNoClick}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {title} {titleIcon}
      </DialogTitle>
      {content !== null && (
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </DialogContent>
      )}

      {dangerRight ? (
        <DialogActions>
          <Button
            color="success"
            variant="outlined"
            fullWidth
            size="large"
            onClick={onNoClick}
          >
            {titleNoButton}
          </Button>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            size="large"
            onClick={onYesClick}
            startIcon={iconYesButton}
          >
            {titleYesButton}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            size="large"
            onClick={onYesClick}
            startIcon={iconYesButton}
          >
            {titleYesButton}
          </Button>
          <Button
            color="success"
            variant="outlined"
            fullWidth
            size="large"
            onClick={onNoClick}
          >
            {titleNoButton}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
