import React from "react";

export const PrivacyLanguage = ({ lang }) => {
  if (lang === "de") {
    return (
      <React.Fragment>
        <div style={{ margin: 3 + "em" }}>
          <h2>Datenschutzrichtlinien</h2>

          <p>
            Bei der DEPRAG SCHULZ GMBH u. CO. KG (nachfolgend als DEPRAG
            bezeichnet) wird Ihre Privatsphäre respektiert. Alle erhobenen Daten
            dienen der Verbesserungen unseres Angebots. Datenschutz und
            Datensicherheit für Kunden und Nutzer haben für die Firma DEPRAG
            eine hohe Priorität. Der Schutz Ihrer personenbezogenen Daten
            während unserer gesamten Geschäftsprozesse ist uns daher ein
            besonderes Anliegen.
            <br />
            <br />
            Diese Erklärung zum Datenschutz erläutert gemäß Art. 13
            EU-Datenschutzgrundverordnung (DSGVO), welche Informationen auf
            unserer Website und die Nutzung des DEPRAG Digital Screw Joint
            Laboratory durch Ihren Besuch erfasst und wie diese Informationen
            genutzt werden. Die Erklärung gilt jedoch nicht für Websites anderer
            Unternehmen, die einen Link zu dieser Website enthalten oder zu
            denen unsere Website Links gelegt hat.
          </p>

          <h3>1. Erfassung und Verarbeitung personenbezogener Daten</h3>

          <p>
            Wenn Sie unsere Webseiten besuchen, speichern unsere Webserver
            standardmäßig temporär Zugriffsdaten, d.h. die IP-Adresse Ihres
            Zugangs, die Session-ID zur Identifikation Ihrer Verbindungssitzung,
            sowie das Datum und die Uhrzeit des Besuches. Rechtsgrundlage für
            die vorübergehende Speicherung der Daten ist Art. 6 Abs. 1 lit. f)
            DSGVO. Diese Daten werden nach Beendigung der Sitzung im Rahmen der
            gesetzlichen Vorschriften gelöscht. Darüber hinaus werden
            persönliche Daten nur dann gespeichert, wenn Sie uns diese von sich
            aus freiwillig, z.B. im Rahmen einer Registrierung, einer Bestellung
            und der damit verbundenen Auftragsbearbeitung auf Grundlage von Art.
            6 Abs. 1 lit. a) oder Art. 6 Abs. 1 lit. b) DSGVO angeben. Sie
            werden in den jeweiligen Eingabe- und Kontaktformularen über den
            Zweck der Datenerhebung informiert. Sie haben jederzeit die
            Möglichkeit, Ihre Registrierungsdaten in Ihrem Kundenkonto oder über
            unseren Kundendienst zu löschen.
          </p>

          <h3>2. Hinweis zur Datenübermittlung in Drittländer</h3>

          <p>
            Zur Gewährleistung der hohen Erreichbarkeit unseres Kundendienstes
            werden wir punktuell von Dienstleistern außerhalb des Geltungsraumes
            der DSGVO unterstützt. Wir haben mit den Dienstleistern Verträge
            gem. Art. 44 DSGVO in Verbindung mit den EU-Standardvertragsklauseln
            geschlossen und versichern uns regelmäßig, dass unsere sehr hohen
            Datenschutzanforderungen auch vollumfänglich erfüllt werden.
          </p>

          <h3>
            3. Verwendung und Weitergabe personenbezogener Daten und
            Zweckbindung
          </h3>

          <p>
            Die Verarbeitung und Weiterleitung Ihrer Daten geschieht auf
            folgenden rechtlichen Grundlagen: Art. 6 Abs. 1 lit. a) DSGVO für
            alle Verarbeitungen, die eine Einwilligung benötigen, Art. 6 Abs. 1
            lit. b) DSGVO für die Vertragserfüllung, Art. 6 Abs. 1 lit. c) DSGVO
            für das Zustimmungsmanagement zur Erfüllung rechtlicher
            Verpflichtungen sowie Art. 6 Abs. 1 lit. f) DSGVO für die weitere
            Verarbeitung mit berechtigtem Interesse.
            <br />
            <br />
            Wir verwenden die von Ihnen mitgeteilten Daten zur Erfüllung und
            Abwicklung Ihres Auftrags gemäß Art. 6 Abs. 1 lit. b) DSGVO. Ihre
            Daten werden entsprechend den jeweils geltenden
            datenschutzrechtlichen Vorschriften allein zu dem mit der jeweiligen
            Erhebung verbundenen Zweck und zur Wahrung berechtigter eigener
            Geschäftsinteressen gemäß Art. 6 Abs. 1 lit. f) DSGVO, insbesondere
            der technischen Administration der Webseiten verwendet. Nur dann,
            wenn Sie uns zuvor Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a)
            DSGVO erteilt haben, nutzen wir diese Daten auch für produktbezogene
            Umfragen und Marketingzwecke. Eine ggf. erteilte Einwilligung können
            Sie jederzeit mit Wirkung für die Zukunft widerrufen.
          </p>

          <h4>Übermittlung von Daten an Dritte</h4>

          <p>
            Eine Übermittlung persönlicher Daten an staatliche Stellen erfolgt
            nur im Rahmen zwingender Rechtsvorschriften. Gegenüber privaten
            Dritten werden Ihre Daten nur weitergegeben, wenn Sie ausdrücklich
            eingewilligt haben, hierfür eine gesetzliche Verpflichtung besteht
            oder dies zur Durchsetzung unserer Rechte, insbesondere zur
            Durchsetzung von Ansprüchen aus dem Vertragsverhältnis, notwendig
            ist. Darüber hinaus geben wir Ihre Daten an Dritte weiter, soweit
            dies im Rahmen der Nutzung der Webseite und der Vertragsabwicklung
            (auch außerhalb der Webseite), namentlich der Verarbeitung Ihrer
            Bestellungen, erforderlich ist.
          </p>

          <h3>4. Einsatz von Cookies</h3>

          <p>
            Um Ihnen bei dem Besuch unserer Webseite bestimmte Funktionen zu
            ermöglichen und diese attraktiv zu gestalten, verwenden wir auf
            verschiedenen Seiten sogenannte Cookies.
            <br />
            <br />
            Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem
            Endgerät abgelegt werden. Einige der von uns verwendeten Cookies
            werden nach dem Ende der Browser-Sitzung, also nach dem Schließen
            Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere
            Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns oder
            unseren Partnerunternehmen, Ihren Browser beim nächsten Besuch
            wiederzuerkennen (persistente Cookies). Weitere Informationen sowie
            individuelle Cookie-Einstellungen hier.
            <br />
            <br />
            Beim Aufruf der Seiten wird der Nutzer über die Verwendung von
            Cookies zu Analysezwecken informiert und seine Einwilligung zur
            Verarbeitung der in diesem Zusammenhang verwendeten
            personenbezogenen Daten eingeholt. Zur Erfüllung unserer rechtlichen
            Verpflichtung nutzen wir eine anwendungsspezifische Lösung, die auf
            Grundlage von Art. 6 Abs. 1 lit. c) DSGVO ihre Einwilligung
            speichert, um den Dokumentationspflichten gem. Art. 7 Abs. 1 DSGVO
            nachzukommen.
            <br />
            <br />
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und einzeln über deren Annahme entscheiden
            oder Cookies generell ausschließen. Bei der Nichtannahme der Session
            Cookies kann die Funktionalität eingeschränkt sein.
          </p>

          <h3>5. Erhebung anonymer Nutzerdaten</h3>

          <p>
            Auf dieser Website werden durch Webanalyse-Technologien der Firma
            Microsoft auf Grundlage von Art. 6 Abs. 1 lit. a) und Art. 6 Abs. 1
            lit. f) DSGVO Daten zu Marketing- und Optimierungszwecken erhoben
            und gespeichert, aus denen unter Verwendung von Pseudonymen
            Nutzungsprofile erstellt werden. Diese Nutzungsprofile dienen der
            Analyse des Besucherverhaltens und werden zur Verbesserung und
            bedarfsgerechten Gestaltung unseres Angebots ausgewertet. Die
            pseudonymisierten Nutzerprofile werden ohne eine gesondert zu
            erteilende, ausdrückliche Einwilligung des Betroffenen nicht mit
            personenbezogenen Daten über den Träger des Pseudonyms
            zusammengeführt. Weitere Informationen über die einzelnen Marketing-
            und Analysecookies erhalten sie hier.
            <br />
            <br />
            Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden
            Sie hier:
            <a
              href="https://learn.microsoft.com/de-de/azure/azure-monitor/app/app-insights-overview?tabs=net"
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: "#32992c" }}
            >
              {" "}
              Microsoft Application Insights
            </a>
          </p>

          <h3>6. Sicherheitsmaßnahmen</h3>

          <p>
            Die Firma DEPRAG setzt umfangreiche technische und betriebliche
            Sicherheitsvorkehrungen ein, um Ihre bei uns verwalteten
            personenbezogenen Daten gegen Missbrauch, zufällige oder
            vorsätzliche Manipulationen oder gegen den Zugriff unberechtigter
            Personen zu schützen. Unsere Sicherheitsverfahren werden
            entsprechend der technologischen Entwicklung fortlaufend verbessert.
          </p>

          <h3>7. Betroffenenrechte</h3>

          <p>
            Bei der Verarbeitung Ihrer personenbezogenen Daten gewährt die
            EU-Datenschutzgrundverordnung Ihnen bestimmte Rechte:
          </p>

          <div style={{ marginLeft: 3 + "em" }}>
            <h3>1. Auskunftsrecht (Art. 15 DSGVO):</h3>
            <p>
              Sie haben das Recht eine Bestätigung darüber zu verlangen, ob Sie
              betreffende personenbezogene Daten verarbeitet werden. Ist dies
              der Fall, so haben Sie ein Recht auf Auskunft über diese
              personenbezogenen Daten und auf die in Art. 15 DSGVO im Einzelnen
              aufgeführten Informationen.
            </p>

            <h3>2. Recht auf Berichtigung (Art. 16 DSGVO):</h3>

            <p>
              Sie haben das Recht, unverzüglich die Berichtigung Sie
              betreffender unrichtiger personenbezogener Daten und ggfs. die
              Vervollständigung unvollständiger personenbezogener Daten zu
              verlangen.
            </p>

            <h3>3. Recht auf Löschung (Art. 17 DSGVO):</h3>

            <p>
              Sie haben zudem das Recht zu verlangen, dass Sie betreffende
              personenbezogene Daten unverzüglich gelöscht werden, sofern einer
              der in Art. 17 DSGVO im Einzelnen aufgeführten Gründe zutrifft,
              z.B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt
              werden.
            </p>

            <h3>4. Recht auf Unterrichtung (Art. 19 DSGVO):</h3>

            <p>
              Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung
              der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht,
              ist dieser verpflichtet, allen Empfängern, denen die Sie
              betreffenden personenbezogenen Daten offengelegt wurden, diese
              Berichtigung oder Löschung der Daten oder Einschränkung der
              Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
              unmöglich oder ist mit einem unverhältnismäßigen Aufwand
              verbunden.
              <br />
              <br />
              Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über
              diese Empfänger unterrichtet zu werden.
            </p>

            <h3>
              5. Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO):
            </h3>

            <p>
              Sie haben das Recht, die Einschränkung der Verarbeitung zu
              verlangen, wenn eine der in Art. 18 DSGVO aufgeführten
              Voraussetzungen gegeben ist, z.B. wenn Sie Widerspruch gegen die
              Verarbeitung eingelegt haben, für die Dauer einer etwaigen
              Prüfung.
            </p>

            <h3>6. Recht auf Datenübertragbarkeit (Art. 20 DSGVO):</h3>

            <p>
              In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt
              werden, haben Sie das Recht, die Sie betreffenden
              personenbezogenen Daten in einem strukturierten, gängigen und
              maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser
              Daten an einen Dritten zu verlangen.
            </p>

            <h3>7. Widerruf der Einwilligung (Art. 7 Abs. 3 DSGVO):</h3>

            <p>
              Sie haben jederzeit das Recht ihre Einwilligung zu widerrufen.
              Dies führt dazu, dass wir die Datenverarbeitung, die wir zuvor auf
              Grundlage ihrer Einwilligung vorgenommen haben, nicht weiter
              fortführen. Ihr Widerruf berührt nicht die Rechtmäßigkeit der
              bereits erfolgten Verarbeitung der Daten.
            </p>

            <h3>
              8. Beschwerderecht bei einer Aufsichtsbehörde (Art. 77 DSGVO):
            </h3>

            <p>
              Sie haben gemäß Art. 77 DSGVO das Recht auf Beschwerde bei einer
              Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung
              der Sie betreffenden Daten gegen datenschutzrechtliche
              Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei
              einer Aufsichtsbehörde in dem Mitgliedstaat Ihres
              Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des
              mutmaßlichen Verstoßes geltend gemacht werden.
            </p>

            <h3>9. Widerspruchsrecht (Art. 21 DSGVO):</h3>
            <p>
              Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
              Situation ergeben, jederzeit gegen die Verarbeitung der Sie
              betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs.
              1 lit. e) oder Art. 6 Abs. 1 lit. f) DSGVO erfolgt, entsprechend
              Art. 21 Abs. 2 DSGVO Widerspruch einzulegen.
              <br />
              <br />
              Der Verantwortliche verarbeitet die Sie betreffenden
              personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
              schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
              Interessen, Rechte und Freiheiten überwiegen, oder die
              Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
              von Rechtsansprüchen.
              <br />
              <br />
              Werden die Sie betreffenden personenbezogenen Daten verarbeitet,
              um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
              Widerspruch gegen die Verarbeitung der Sie betreffenden
              personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
              dies gilt auch für das Profiling, soweit es mit solcher
              Direktwerbung in Verbindung steht.
            </p>
          </div>
          <br />
          <h3>Verantwortlicher für die Datenverarbeitung</h3>

          <p>
            DEPRAG SCHULZ GMBH u. CO. KG
            <br />
            Carl-Schulz-Platz 1 | 92224 Amberg
            <br />
            Deutschland Tel: +49 9621 371-0
            <br />
            Fax +49 9621 371-120
            <br />
          </p>

          <p>E-Mail: info@deprag.de</p>

          <p>
            Geschäftsführer:
            <br />
            Dr. Erik Hallmann / Dr.-Ing. Rolf Pfeiffer
          </p>

          <h3>Datenschutzbeauftragter des Verantwortlichen</h3>
          <p>
            Bastian Blank
            <br />
            Carl-Schulz-Platz 1 | 92224 Amberg
            <br />
            Deutschland Tel: +49 9621 371-208
            <br />
          </p>

          <p>E-Mail: datenschutz@deprag.de</p>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div style={{ margin: 3 + "em" }}>
          <h2>Privacy policy</h2>

          <p>
            At DEPRAG SCHULZ GMBH u. CO. KG (hereinafter referred to as DEPRAG)
            your privacy is respected. All data collected is used to improve our
            range of products and services. Data protection and data security
            for customers and users have a high priority for DEPRAG. The
            protection of your personal data throughout our business processes
            is therefore a matter of particular concern to us.
            <br />
            <br />
            In accordance with Article 13 of the EU Data Protection Regulation
            (EU-DSGVO), this data protection declaration explains what
            information is collected on our website and the use of DEPRAG
            Digital Screw Joint Laboratory through your visit and how this
            information is used. However, the declaration does not apply to
            websites of other companies which contain a link to this website or
            to which our website has provided links.
          </p>

          <h3>1. Collection and processing of personal data</h3>

          <p>
            When you visit our website, our web servers temporarily store access
            data by default, i.e. the IP address of your access, the Session_ID
            to identify your connection session, and the date and time of the
            visit.The legal basis for the temporary storage of the data is Art.
            6 para. 1 lit. f) EU-DSGVO. This data is deleted after the session
            has ended in accordance with legal regulations. In addition,
            personal data is only stored if you provide it to us voluntarily,
            e.g. as part of a registration, an order and the associated order
            processing, a survey, a competition or an online application on the
            basis of Art. 6 para. 1 lit. a) or Art. 6 para. 1 lit. b) EU-DSGVO.
            You will be informed about the purpose of the data collection in the
            respective entry and contact forms. You have the option of deleting
            your registration data in your customer account or via our customer
            service at any time.
          </p>

          <h3>2. Note on data transfer to third countries</h3>

          <p>
            To ensure the high accessibility of our customer service, we are
            selectively supported by service providers outside the area of
            application of the EU-DSGVO. We have concluded contracts with the
            service providers in accordance with Article 44 of the EU Data
            Protection Regulation in conjunction with the EU standard
            contractual clauses and regularly assure ourselves that our very
            high data protection requirements are also fully met.
          </p>

          <h3>3. Use and forwarding of personal data and purpose limitation</h3>

          <p>
            The processing and forwarding of your data is based on the following
            legal grounds: Art. 6 para. 1 lit. a) EU-DSGVO for all processing
            requiring consent, Art. 6 para. 1 lit. b) EU-DSGVO for contract
            fulfilment, Art. 6 para. 1 lit. c) EU-DSGVO for consent management
            to fulfil legal obligations and Art. 6 para. 1 lit. f) EU-DSGVO for
            further processing with legitimate interest.
            <br />
            <br />
            We use the data you provide to fulfil and process your order in
            accordance with Art. 6 para. 1 lit. b) EU-DSGVO. Your data will be
            used in accordance with the applicable data protection regulations
            solely for the purpose associated with the respective collection and
            to protect our own legitimate business interests pursuant to Art. 6
            (1) f) EU-DSGVO, in particular the technical administration of the
            websites. We will only use this data for product-related surveys and
            marketing purposes if you have given us your prior consent in
            accordance with Art. 6 para. 1 lit. a) EU-DSGVO. You can revoke any
            consent you may have given at any time with effect for the future.
          </p>

          <h4>Transmission of data to third parties</h4>

          <p>
            A transfer of personal data to government agencies will only take
            place within the framework of mandatory legal provisions. Your data
            will only be passed on to private third parties if you have
            expressly consented to this, if there is a legal obligation to do so
            or if this is necessary to enforce our rights, in particular to
            enforce claims arising from the contractual relationship. In
            addition, we pass on your data to third parties insofar as this is
            necessary in the context of the use of the website and the
            processing of contracts (also outside the website), namely the
            processing of your orders.
          </p>

          <h3>4. Use of cookies</h3>

          <p>
            In order to enabl you to use certain functions when visiting our
            website and to make it more attractive, we use so-called cookies on
            various pages.
            <br />
            <br />
            Cookies are small text files that are stored on your terminal
            device. Some of the cookies we use are deleted after the end of the
            browser session, i.e. after you close your browser (so-called
            session cookies). Other cookies remain on your terminal device and
            enable us or our partner companies to recognise your browser on your
            next visit (persistent cookies). Further information and individual
            cookie settings here.
            <br />
            <br />
            When calling up the pages, the user is informed about the use of
            cookies for analysis purposes and his consent to the processing of
            personal data used in this context is obtained. To fulfil our legal
            obligation, we use an application specific solution, which stores
            consent on the basis of Art. 6 (1) lit. c) EU-DSGVO in order to
            comply with the documentation obligations pursuant to Art. 7 (1)
            EU-DSGVO.
            <br />
            <br />
            You can set your browser so that you are informed about the setting
            of cookies and decide individually whether to accept them or
            generally exclude cookies. If you do not accept session cookies, the
            functionality may be limited.
          </p>

          <h3>5. Collection of anonymous user data</h3>

          <p>
            This website uses web analysis technologies from Microsoft on the
            basis of Art. 6 para. 1 lit. a) and Art. 6 para. 1 lit. f) EU-DSGVO
            for marketing and optimisation purposes, from which user profiles
            are created using pseudonyms. These usage profiles are used to
            analyse visitor behaviour and are evaluated to improve and design
            our offer in line with requirements. The pseudonymised user profiles
            are not combined with personal data about the bearer of the
            pseudonym without the express consent of the person concerned, which
            must be given separately. Further information on the individual
            marketing and analysis cookies can be found here.
            <br />
            <br />
            Further information on terms of use and data protection can be found
            here:
            <a
              href="https://learn.microsoft.com/en-us/azure/azure-monitor/app/app-insights-overview?tabs=net"
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: "#32992c" }}
            >
              {" "}
              Microsoft Application Insights
            </a>
          </p>

          <h3>6. Security measures</h3>

          <p>
            DEPRAG uses extensive technical and operational security measures to
            protect your personal data managed by us against misuse, accidental
            or intentional manipulation or against access by unauthorised
            persons. Our security procedures are continuously improved in line
            with technological developments.
          </p>

          <h3>7. Data subject rights</h3>

          <p>
            When processing your personal data, the EU General Data Protection
            Regulation grants you certain rights:
          </p>

          <div style={{ marginLeft: 3 + "em" }}>
            <h3>1. Right of access (Art. 15 EU GDPR):</h3>
            <p>
              You have the right to request confirmation as to whether personal
              data relating to you is being processed. If this is the case, you
              have the right to be informed about this personal data and to
              receive the information listed in detail in Art. 15 EU-DSGVO.
            </p>

            <h3>2. Right to rectification (Art. 16 EU GDPR):</h3>

            <p>
              You have the right to request without undue delay the
              rectification of any inaccurate personal data concerning you and,
              where applicable, the completion of any incomplete personal data.
            </p>

            <h3>3. Right to erasure (Art. 17 EU GDPR):</h3>

            <p>
              You also have the right to request that personal data concerning
              you be deleted without delay, provided that one of the reasons
              listed in detail in Art. 17 EU-DSGVO applies, e.g. if the data is
              no longer required for the purposes pursued.
            </p>

            <h3>4. Right to information (Art. 19 EU GDPR):</h3>

            <p>
              If you have asserted the right to rectification, erasure or
              restriction of processing against the controller, the controller
              is obliged to inform all recipients to whom the personal data
              concerning you have been disclosed of this rectification or
              erasure of the data or restriction of processing, unless this
              proves impossible or involves a disproportionate effort.
              <br />
              <br />
              You have the right to be informed about these recipients.
            </p>

            <h3>5. Right to restriction of processing (Art. 18 EU-DSGVO):</h3>

            <p>
              You have the right to request the restriction of processing if one
              of the conditions listed in Art. 18 EU GDPR applies, e.g. if you
              have objected to the processing, for the duration of any review.
            </p>

            <h3>6. Right to data portability (Art. 20 EU-DSGVO):</h3>

            <p>
              In certain cases, which are listed in detail in Art. 20 EU-DSGVO,
              you have the right to receive the personal data concerning you in
              a structured, common and machine-readable format or to request the
              transfer of this data to a third party.
            </p>

            <h3>7. Revocation of consent (Art. 7 para. 3 EU-DSGVO):</h3>

            <p>
              You have the right to revoke your consent at any time. This means
              that we will no longer continue the data processing that we
              previously carried out on the basis of your consent. Your
              revocation does not affect the lawfulness of the processing of the
              data that has already taken place. n.
            </p>

            <h3>
              8. Right to lodge a complaint with a supervisory authority (Art.
              77 EU-DSGVO):
            </h3>

            <p>
              According to Art. 77 EU-DSGVO, you have the right to lodge a
              complaint with a supervisory authority if you are of the opinion
              that the processing of the data concerning you violates data
              protection regulations. The right to lodge a complaint may in
              particular be asserted before a supervisory authority in the
              Member State of your place of residence, your place of work or the
              place of the alleged infringement.
            </p>

            <h3>9. Right to object (Art. 21 EU-DSGVO):</h3>
            <p>
              You have the right to object at any time, on grounds relating to
              your particular situation, to the processing of personal data
              concerning you which is carried out on the basis of Article
              6(1)(e) or Article 6(1)(f) EU GDPR, in accordance with Article
              21(2) EU GDPR.
              <br />
              <br />
              The controller shall no longer process the personal data
              concerning you unless it can demonstrate compelling legitimate
              grounds for the processing which override your interests, rights
              and freedoms, or the processing serves the purpose of asserting,
              exercising or defending legal claims.
              <br />
              <br />
              If the personal data concerning you is processed for the purpose
              of direct marketing, you have the right to object at any time to
              the processing of personal data concerning you for the purpose of
              such marketing; this also applies to profiling insofar as it is
              related to such direct marketing.
            </p>
          </div>
          <br />
          <h3>Responsible party for data processing</h3>

          <p>
            DEPRAG SCHULZ GMBH u. CO. KG
            <br />
            Carl-Schulz-Platz 1 | 92224 Amberg
            <br />
            Deutschland Tel: +49 9621 371-0
            <br />
            Fax +49 9621 371-120
            <br />
          </p>

          <p>E-Mail: info@deprag.de</p>

          <p>
            CEO:
            <br />
            Dr. Erik Hallmann / Dr.-Ing. Rolf Pfeiffer
          </p>

          <h3>Data protection officer of the responsible party</h3>
          <p>
            Bastian Blank
            <br />
            Carl-Schulz-Platz 1 | 92224 Amberg
            <br />
            Deutschland Tel: +49 9621 371-208
            <br />
          </p>

          <p>E-Mail: datenschutz@deprag.de</p>
        </div>
      </React.Fragment>
    );
  }
};
