import { createSlice } from "@reduxjs/toolkit";
import { TorqueUnit } from "../../../../Features/Settings/Enum/TorqueUnit";
import { Step } from "../../../Algorithm/useAlgorithm";
import { ConvertTorqueUnit } from "../../../UnitConverter/TorqueUnitConverter";
import { UnitConvertStrategy } from "../../../../Infrastructure/UnitConverter/Enum/UnitConvertStrategy";
import { toast } from "react-hot-toast";

export interface MonitorNextTighteningResult {
  IpAddressOrDns: string;
  FinalResult: string;
  GraphFile: string;
  GraphFileDownloadLink: string;
  TighteningStatus: number;
  Angle1: number;
  Angle2: number;
  Angle3: number;
  Torque1: number;
  Torque2: number;
  Torque3: number;
  AngleNok: number;
  TorqueNok: number;
  DurationInMilliseconds: number;
  HeadRestMomentNm: number | null;
  DestructiveMomentNm: number | null;
  Info: string;
  Step: Step;
}

export enum ExecuteProcessStatus {
  Idle,
  WaitForResult,
  Finished,
}

export interface ExecuteProcessState {
  tighteningResults: Array<MonitorNextTighteningResult>;
  status: ExecuteProcessStatus;
  currentStep: Step;
  toastIdsFromInfoMessageToasts: { step: Step; id: string }[];
}

export const initialState: ExecuteProcessState = {
  tighteningResults: [],
  status: ExecuteProcessStatus.Idle,
  currentStep: { ComponentNr: 1, ScrewdrivingPointNr: 1 } as Step,
  toastIdsFromInfoMessageToasts: [],
};

export const executeProcessSlice = createSlice({
  name: "executeProcess",
  initialState: initialState,
  reducers: {
    addTighteningResult: (state, action) => {
      state.tighteningResults.push(action.payload);
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    deleteTighteningResultWithStep: (state, action) => {
      const index = state.tighteningResults.findIndex(
        (x) => x.Step === action.payload
      );
      state.tighteningResults.splice(index, 1);
    },
    resetProcessValues: (state) => {
      state.tighteningResults = initialState.tighteningResults;
      state.status = initialState.status;
      state.currentStep = initialState.currentStep;
    },
    setTorqueUnitOfTightenings: (state, action) => {
      var sourceUnit = action.payload.sourceTorqueUnit as TorqueUnit;
      var targetUnit = action.payload.targetTorqueUnit as TorqueUnit;

      state.tighteningResults.forEach((element) => {
        element.Torque1 = ConvertTorqueUnit({
          sourceTorqueUnit: sourceUnit,
          sourceTorque: element.Torque1,
          targetTorqueUnit: targetUnit,
          convertStrategy: UnitConvertStrategy.RoundNormal,
        });
        element.Torque2 = ConvertTorqueUnit({
          sourceTorqueUnit: sourceUnit,
          sourceTorque: element.Torque2,
          targetTorqueUnit: targetUnit,
          convertStrategy: UnitConvertStrategy.RoundNormal,
        });
        element.Torque3 = ConvertTorqueUnit({
          sourceTorqueUnit: sourceUnit,
          sourceTorque: element.Torque3,
          targetTorqueUnit: targetUnit,
          convertStrategy: UnitConvertStrategy.RoundNormal,
        });
        element.TorqueNok = ConvertTorqueUnit({
          sourceTorqueUnit: sourceUnit,
          sourceTorque: element.TorqueNok,
          targetTorqueUnit: targetUnit,
          convertStrategy: UnitConvertStrategy.RoundNormal,
        });
      });
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    appendToastIdsFromInfoMessageToasts: (state, action) => {
      state.toastIdsFromInfoMessageToasts.push(action.payload);
    },
    dismissAllToastFromInfoMessageToasts: (state) => {
      state.toastIdsFromInfoMessageToasts.forEach((element) => {
        toast.dismiss(element.id);
      });
      state.toastIdsFromInfoMessageToasts = [];
    },
    dismissSingleToast: (state, action) => {
      var indexToDelete = state.toastIdsFromInfoMessageToasts.findIndex(
        (x) =>
          x.step.ComponentNr === action.payload.ComponentNr &&
          x.step.ScrewdrivingPointNr === action.payload.ScrewdrivingPointNr
      );
      if (indexToDelete !== -1) {
        toast.dismiss(state.toastIdsFromInfoMessageToasts[indexToDelete].id);
        var newValues = [...state.toastIdsFromInfoMessageToasts];
        newValues.splice(indexToDelete, 1);
        state.toastIdsFromInfoMessageToasts = newValues;
      }
    },
  },
});

export const {
  appendToastIdsFromInfoMessageToasts,
  dismissAllToastFromInfoMessageToasts,
  dismissSingleToast,
  addTighteningResult,
  setStatus,
  resetProcessValues,
  setTorqueUnitOfTightenings,
  deleteTighteningResultWithStep,
  setCurrentStep,
} = executeProcessSlice.actions;

export default executeProcessSlice.reducer;
