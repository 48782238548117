import { useEffect, useLayoutEffect, useState } from "react";
import { ZodType, z } from "zod";

// Hook zu Validierung von Werten ohne eine Form. Validierungsfehler werden erst zur�ckgegeben, nachdem ein Wert erstmalig ge�ndert wurde.

export const useValidation = <t>(schema: ZodType, values: t) => {
  const [internalErrors, setInternalErrors] = useState<any>([]);
  const [errors, setErrors] = useState<object>({});
  const [oldValues, setOldValues] = useState({ ...values });
  const [initialValues] = useState({ ...values });
  const [triggered, setTriggerd] = useState<string[]>([]);

  // Jede �nderung der Values wird �berpr�ft und ggf. die �berpr�fung durch Zod gestartet.
  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(oldValues)) {
      checkForErrors();
      setOldValues({ ...values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useLayoutEffect(() => {
    checkForErrors();
    setOldValues({ ...values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Die von Zod gefundenen Fehler werden hier in ein Objekt umgewandelt und in {errors} abgespeichert, damit die Fehlernachricht direkt mit dem Key ausgelesen werden kann.
  useEffect(() => {
    var newErrors = {};
    internalErrors.forEach((error) => {
      error["path"].forEach((path) => {
        // Wenn ein Value noch nicht getriggered wurde und den Default-Wert hat werden Zod-Fehler nicht zur�ckgegeben.
        if (
          JSON.stringify(initialValues[path]) !==
            JSON.stringify(values[path]) ||
          triggered.includes(path)
        ) {
          newErrors[path] = error.message;
        }
      });
    });
    setErrors(newErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalErrors]);

  // Falls ein Value ge�ndert wird, wird dieses als getriggered gespeichert und wird nun immer alle Fehler zur�ckgegeben bekommen.
  useEffect(() => {
    Object.keys(values as object).forEach((key) => {
      if (
        JSON.stringify(values[key]) !== JSON.stringify(initialValues[key]) &&
        !triggered.includes(key)
      ) {
        var arr = triggered;
        arr.push(key);
        setTriggerd(arr);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  // Validierung des Schemas durch Zod.
  const checkForErrors = () => {
    try {
      schema.parse({ ...values });
      if (errors) {
        setInternalErrors([]);
        setErrors({});
      }
      return [];
    } catch (error) {
      var e = error as z.ZodError;
      setInternalErrors(e.errors);
      return e.errors;
    }
  };

  // Setzt alle gespeicherten Werte zur�ck.
  const reset = () => {
    setTriggerd([]);
    setInternalErrors([]);
    setErrors({});
  };

  // Alle gefundenen Validierungsfehler werden ohne Ausnahme zur�ckgegeben.
  const checkSubmit = () => {
    var newErrors = {};
    var newInternal = checkForErrors();
    setOldValues({ ...values });
    newInternal.forEach((error) => {
      error["path"].forEach((path) => {
        newErrors[path] = error.message;

        if (!triggered.includes(path as string)) {
          var arr = triggered;
          arr.push(path as string);
          setTriggerd(arr);
        }
      });
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const canSubmit = internalErrors.length === 0;

  return {
    errors: errors,
    canSubmit: canSubmit,
    checkSubmit: checkSubmit,
    reset: reset,
  };
};
