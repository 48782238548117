import { Step, Stepper, Box, StepLabel, Typography } from "@mui/material";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppSelector } from "../../../../Infrastructure/Redux/hooks";

const WizardStepper = () => {
  const allSteps = useAppSelector((store) =>
    store.wizard.stepList.map((x) => x)
  );
  const activeStep = useAppSelector((store) => store.wizard.currentStepId);
  const activeStepData = useAppSelector((store) =>
    store.wizard.stepList.find((x) => x.id === activeStep)
  );

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Stepper activeStep={activeStep}>
            {allSteps.map((_, index) => {
              return (
                <Step key={index}>
                  <StepLabel></StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStepData ? (
            <Typography variant="h6">
              {getTranslatedText(activeStepData.textIdentifierWizardStep)}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
      )}
    </LanguageConsumer>
  );
};

export default WizardStepper;
