import { usePublishMqttErrorAnalytics } from "./usePublishMqttErrorAnalytics";
import { usePublishFeedbackAnalytics } from "./usePublishFeedbackAnalytics";
import { usePublishFooterAnalytics } from "./usePublishFooterAnalytics";
import { usePublishWizardAnalytics } from "./usePublishWizardAnalytics";
import { usePublishButtonClickAnalytics } from "./usePublishButtonClickAnalytics";
import { usePublishGraphViewerActionAnalytics } from "./usePublishGraphViewerActionAnalytics";
import { usePublishSettingsAnalytics } from "./usePublishSettingsAnalytics";
import { usePublishExecuteProcessAnalytics } from "./usePublishExecuteProcessAnalytics";
import { usePublishOnlineHelpAnalytics } from "./usePublishOnlineHelpAnalytics";

export const usePublishAnalytics = () => {
  /*
    const isLicenced = useAppSelector(
    (store) => store.licenceService.licenceAuthentication.isLicenced
  );

  useEffect(() => {
    if (isLicenced) {
      appInsights.setAuthenticatedUserContext(licenceKey, undefined, true);
    } else {
      appInsights.clearAuthenticatedUserContext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLicenced]);
  */

  usePublishMqttErrorAnalytics();
  usePublishFeedbackAnalytics();
  usePublishFooterAnalytics();
  usePublishWizardAnalytics();
  usePublishButtonClickAnalytics();
  usePublishGraphViewerActionAnalytics();
  usePublishSettingsAnalytics();
  usePublishExecuteProcessAnalytics();
  usePublishOnlineHelpAnalytics();
};
