import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { GraphViewerActionEvent } from "../Model/Categories/GraphViewerActionEnums";
import {
  reset_analyse_graphViewerActionNavigate,
  reset_analyse_graphViewerActionOpen,
} from "../Redux/AnalyticsSlice";

export const usePublishGraphViewerActionAnalytics = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const graphViewerActionOpenState = useAppSelector(
    (store) => store.analytics.graphViewerActionOpen
  );
  const graphViewerActionNavigateState = useAppSelector(
    (store) => store.analytics.graphViewerActionNavigate
  );

  const TrackEvent = (eventName: string, properties: any) => {
    return useTrackEvent(appInsights, eventName, properties, true);
  };

  const trackGraphViewerActionOpen = TrackEvent(
    GraphViewerActionEvent.GraphViewerActionOpen.toString(),
    {}
  );
  const trackGraphViewerActionNavigate = TrackEvent(
    GraphViewerActionEvent.GraphViewerActionNavigate.toString(),
    {}
  );

  useEffect(() => {
    if (graphViewerActionOpenState.toggle) {
      try {
        trackGraphViewerActionOpen({
          Target: graphViewerActionOpenState.target.toString(),
        });
      } catch {}
      dispatch(reset_analyse_graphViewerActionOpen());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphViewerActionOpenState.toggle, dispatch]);

  useEffect(() => {
    if (graphViewerActionNavigateState.toggle) {
      try {
        trackGraphViewerActionNavigate({
          Direction: graphViewerActionNavigateState.direction.toString(),
        });
      } catch {}
      dispatch(reset_analyse_graphViewerActionNavigate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphViewerActionNavigateState.toggle, dispatch]);
};
