import {
  setInitialProgramChoice,
  setInitialProgramNumber,
  setInitialStartEnableMode,
  setInititalStartEnable,
  setLoading,
} from "../../../Configurations/AstConfiguration/Redux/AstConfigurationSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { v4 as uuidv4 } from "uuid";
import {
  DefaultLockDeviceRequestPayloadDto,
  LockDeviceRequestPayloadDto,
} from "../../Models/Commands/LockDevice/LockDeviceRequestPayloadDto";
import { MqttRequestDto } from "../../Models/MqttRequestDto";
import { GetScrewdrivingRelatedSettingsResultPayloadDto } from "../../Models/Commands/GetScrewdrivingRelatedSettings/GetScrewdrivingRelatedSettingsResultPayloadDto";

interface UseHandleGetScrewdrivingRelatedSettingsCommandArgs {
  mqttPublish: (payload: {}, topic?: string) => void;
}

export const useHandleGetScrewdrivingRelatedSettingsCommandResult = ({
  mqttPublish,
}: UseHandleGetScrewdrivingRelatedSettingsCommandArgs) => {
  const dispatch = useAppDispatch();
  const astConfiguration = useAppSelector((store) => store.astConfiguration);

  const handleGetScrewdrivingRelatedSettingsCommandResultSuccess =
    (result: {}) => {
      dispatch(setLoading(false));
      const parsedResult =
        result as GetScrewdrivingRelatedSettingsResultPayloadDto;
      parsedResult.ProgramNumber &&
        dispatch(setInitialProgramNumber(parsedResult.ProgramNumber));
      parsedResult.ProgramChoice &&
        dispatch(setInitialProgramChoice(parsedResult.ProgramChoice));
      parsedResult.StartEnable &&
        dispatch(setInititalStartEnable(parsedResult.StartEnable));
      parsedResult.StartEnableMode &&
        dispatch(setInitialStartEnableMode(parsedResult.StartEnableMode));

      const request = {
        TransactionGuid: uuidv4(),
        Command: "LockDevice",
        Payload: {
          ...DefaultLockDeviceRequestPayloadDto,
          IpAddressOrDns: astConfiguration.ipAddressOrDns,
          Username: astConfiguration.username,
          Password: astConfiguration.password,
          AstType: astConfiguration.parameterInformation.controllerType,
        } as LockDeviceRequestPayloadDto,
      } as MqttRequestDto;

      mqttPublish(request);
    };

  return { handleGetScrewdrivingRelatedSettingsCommandResultSuccess };
};
