import { useContext, useEffect, useState } from "react";
import {
  Grow,
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { v4 as uuidv4 } from "uuid";
import { GraphViewerOpenTarget } from "../../../../../Infrastructure/Analytics/Model/Categories/GraphViewerActionEnums";
import { WizardItems } from "../../../../../Infrastructure/Analytics/Model/Categories/WizardEnums";
import {
  analyse_startExecuteProcess,
  analyse_graphViewerActionOpen,
  analyse_navigateWizard,
} from "../../../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { SuccessAnimation } from "../../../../../Infrastructure/Animations/SuccessAnimation";
import {
  ExecuteProcessStatus,
  resetProcessValues,
  setStatus,
  MonitorNextTighteningResult,
  deleteTighteningResultWithStep,
  dismissSingleToast,
  dismissAllToastFromInfoMessageToasts,
} from "../../../../../Infrastructure/Configurations/ExecuteProcess/Redux/ExecuteProcessSlice";
import {
  getTranslation,
  LanguageConsumer,
} from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { MqttContext } from "../../../../../Infrastructure/Mqtt/MqttService";
import { MqttRequestDto } from "../../../../../Infrastructure/Mqtt/Models/MqttRequestDto";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Infrastructure/Redux/hooks";
import { YesNoDialog } from "../../../../../Infrastructure/YesNoDialog/YesNoDialog";
import { TorqueUnit } from "../../../../Settings/Enum/TorqueUnit";
import {
  decreaseCurrentStepId,
  increaseCurrentStepId,
} from "../../Redux/WizardSlice";
import {
  DefaultMonitorNextTighteningRequestPayloadDto,
  MonitorNextTighteningRequestPayloadDto,
} from "../../../../../Infrastructure/Mqtt/Models/Commands/MonitorNextTightening/MonitorNextTighteningRequestPayloadDto";
import { ConvertTorqueUnitAsString } from "../../../../../Infrastructure/UnitConverter/TorqueUnitConverter";
import {
  DataGrid,
  GridColDef,
  gridFilteredSortedRowEntriesSelector,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiRef,
  GridColumnMenuProps,
  GridColumnMenu,
  gridExpandedRowCountSelector,
  GridRowClassNameParams,
} from "@mui/x-data-grid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../Layout/Datagrid/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../Layout/Datagrid/DataGridLocale_en";
import {
  Step,
  useAlgorithm,
} from "../../../../../Infrastructure/Algorithm/useAlgorithm";
import { ProcessingOrder } from "../../../../../Infrastructure/Configurations/ProcessConfiguration/Redux/ProcessConfigurationSlice";
import { addGuidToIgnore } from "../../../../../Infrastructure/Mqtt/Redux/MqttStateSlice";
import {
  DefaultGetScrewdrivingRelatedSettingsRequestPayloadDto,
  GetScrewdrivingRelatedSettingsRequestPayloadDto,
} from "../../../../../Infrastructure/Mqtt/Models/Commands/GetScrewdrivingRelatedSettings/GetScrewdrivingRelatedSettingsRequestPayloadDto";
import { UnitConvertStrategy } from "../../../../../Infrastructure/UnitConverter/Enum/UnitConvertStrategy";
import { Error } from "@mui/icons-material";
import { GraphViewer } from "@deprag/react-core";
import "./Style/ExecuteProcess.css";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

const ExecuteProcess = () => {
  const dispatch = useAppDispatch();
  const astConfiguration = useAppSelector((store) => store.astConfiguration);
  const { myDepragApiKey } = useAppSelector((store) => store.licenceService.licenceAuthentication);
  const processConfiguration = useAppSelector(
    (store) => store.processConfiguration
  );
  const { tighteningResults, status, currentStep } = useAppSelector(
    (store) => store.executeProcess
  );
  const { torqueUnit, graphViewerUrl } = useAppSelector(
    (store) => store.settings
  );
  const { processingOrder } = useAppSelector(
    (store) => store.processConfiguration
  );
  const langIsDe = useAppSelector((store) => store.settings.language === "de");
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const { currentTransactionGuid } = useAppSelector((store) => store.mqttState);

  const { publishMqtt } = useContext(MqttContext);
  const [getBackSecurityDialogOpen, setGetBackSecurityDialogOpen] =
    useState<boolean>(false);
  const [
    warnUserThatOneOrMoreTighteningWithoutHeadRestOrDestructiveMomentDialogOpen,
    setwarnUserThatOneOrMoreTighteningWithoutHeadRestOrDestructiveMomentDialogOpen,
  ] = useState<boolean>(false);

  const [
    shouldReallyDeleteTighteningDialogOpen,
    setShouldReallyDeleteTighteningDialogOpen,
  ] = useState(false);
  const [tighteningToDeleteStep, setTighteningToDeleteStep] = useState<Step>();

  const { amount, count, finished, lastStepId, LastStep } = useAlgorithm();

  const [graphFileArray, setGraphFileArray] = useState<string[]>([]);
  const [graphFile, setGraphFile] = useState<string>("");

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (!finished) {
      startProcess();
      dispatch(
        analyse_startExecuteProcess({
          controllerType: astConfiguration.parameterInformation.controllerType,
          amountOfScrewdrivingPoints: amount,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (tighteningResults.length > 0) {
      const maxRowIndex = gridExpandedRowCountSelector(apiRef) - 1;
      updateGraphFileArray();
      setTimeout(() => {
        apiRef.current.scrollToIndexes({ rowIndex: maxRowIndex, colIndex: 0 });
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tighteningResults]);

  const startProcess = () => {
    const request = {
      TransactionGuid: uuidv4(),
      Command: "GetScrewdrivingRelatedSettings",
      Payload: {
        ...DefaultGetScrewdrivingRelatedSettingsRequestPayloadDto,
        IpAddressOrDns: astConfiguration.ipAddressOrDns,
        Username: astConfiguration.username,
        Password: astConfiguration.password,
      } as GetScrewdrivingRelatedSettingsRequestPayloadDto,
    } as MqttRequestDto;

    publishMqtt(request);
  };

  const publishWaitForNextTighteningIfProcessWasAlreadyFinished = () => {
    const newTighteningValuesNeeded = tighteningResults.length === amount;

    if (!newTighteningValuesNeeded) {
      return;
    }
    dispatch(setStatus(ExecuteProcessStatus.WaitForResult));
    const monitorNextTighteningRequest = {
      TransactionGuid: uuidv4(),
      Command: "MonitorNextTightening",
      Payload: {
        ...DefaultMonitorNextTighteningRequestPayloadDto,
        IpAddressOrDns: astConfiguration.ipAddressOrDns,
        Username: astConfiguration.username,
        Password: astConfiguration.password,
        ExpectedProgramNumber: processConfiguration.programNumber,
      } as MonitorNextTighteningRequestPayloadDto,
    } as MqttRequestDto;

    publishMqtt(monitorNextTighteningRequest);
  };

  const handleDeleteIconClicked = (step: Step) => {
    setTighteningToDeleteStep(step);
    setShouldReallyDeleteTighteningDialogOpen(true);
  };

  const updateGraphFileArray = () => {
    const sortedRows = gridFilteredSortedRowEntriesSelector(apiRef);
    var arr = [] as string[];
    sortedRows.forEach((element) => {
      arr.push(element.model.GraphFileDownloadLink);
    });
    setGraphFileArray(arr);
  };

  const handleFinishExecuteProcess = () => {
    dispatch(dismissAllToastFromInfoMessageToasts());
    dispatch(increaseCurrentStepId());
    dispatch(
      analyse_navigateWizard({ currentStep: WizardItems.ExecuteProcess })
    );
  };

  const columns: GridColDef[] = [
    {
      field: "ComponentNr",
      headerName: getTranslation("ComponentNr"),
      flex: 0.6,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.Step.ComponentNr,
    },
    {
      field: "ScrewdrivingPointNr",
      headerName: getTranslation("ScrewdrivingPointNr"),
      flex: 0.6,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.Step.ScrewdrivingPointNr,
    },
    {
      field: "HeadRestMomentNm",
      headerName:
        getTranslation("HeadRestMoment") +
        ` (${getTranslation(TorqueUnit[torqueUnit].toString())})`,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value === null ? "-" : params.value,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.HeadRestMomentNm !== null
          ? ConvertTorqueUnitAsString({
            sourceTorqueUnit: TorqueUnit.Nm,
            sourceTorque: params.row.HeadRestMomentNm,
            targetTorqueUnit: torqueUnit,
            convertStrategy: UnitConvertStrategy.RoundNormal,
          })
          : null,
    },
    {
      field: "DestructiveMomentNm",
      headerName:
        getTranslation("DestructiveMoment") +
        ` (${getTranslation(TorqueUnit[torqueUnit].toString())})`,
      flex: langIsDe ? 1 : 0.8,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value === null ? "-" : params.value,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.DestructiveMomentNm !== null
          ? ConvertTorqueUnitAsString({
            sourceTorqueUnit: TorqueUnit.Nm,
            sourceTorque: params.row.DestructiveMomentNm,
            targetTorqueUnit: torqueUnit,
            convertStrategy: UnitConvertStrategy.RoundNormal,
          })
          : null,
    },
    {
      field: " ",
      headerName: getTranslation("Actions"),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: "right",
      width: 130,
      renderCell: (
        params: GridRenderCellParams<any, MonitorNextTighteningResult, any>
      ) => (
        <Box
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {params.row.Info && (
            <Tooltip title={getTranslation(params.row.Info)} arrow>
              <IconButton>
                <Error sx={{ fontSize: 24 }} color="error" />
              </IconButton>
            </Tooltip>
          )}
          {params.row.GraphFile ? (
            <IconButton
              title={getTranslation("OpenInGraphviewerCloud")}
              color="success"
              onClick={() => {
                dispatch(
                  analyse_graphViewerActionOpen({
                    target: GraphViewerOpenTarget.NewTab,
                  })
                );
                updateGraphFileArray();
                setGraphFile(params.row.GraphFileDownloadLink);
              }}
            >
              <img src="Images/Icons/graphviewer.svg" alt="graphviewer" />
            </IconButton>
          ) : (
            <></>
          )}
          <IconButton
            title={getTranslation("DeleteThisTightening")}
            color="error"
            disabled={(params.id as number) != lastStepId}
            onClick={() => {
              handleDeleteIconClicked(params.row.Step);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rowClassNames = (params: GridRowClassNameParams<any>): string => {
    if (!params.row.Info) {
      return "";
    }

    if (isDarkMode) {
      return "dataGridBackgroundRedDarkMode";
    }

    return "dataGridBackgroundRedLightMode";
  };

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <>
          <GraphViewer
            array={graphFileArray}
            value={graphFile}
            setValue={(val) => setGraphFile(val)}
            url={graphViewerUrl}
            quickView={false}
            darkMode={isDarkMode}
            language={langIsDe ? "de" : "en"}
            apiKey={myDepragApiKey as string}
          />

          <Grow in={true} appear={true}>
            <Card square={true} elevation={12} sx={{ height: "100%" }}>
              <CardContent
                sx={{
                  height: "100%",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
                  {getTranslatedText("ExecuteProcessHeading")}
                </Typography>
                <div
                  style={{ color: isDarkMode ? "#fff" : "#444" }}
                  dangerouslySetInnerHTML={{
                    __html: getTranslatedText("ExecuteProcessText"),
                  }}
                ></div>
                <LinearProgress
                  value={(count / amount) * 100}
                  variant="determinate"
                  color="success"
                  sx={{ width: "100%", mt: 2, borderRadius: 2, height: 6 }}
                />

                <Grid container spacing={2} sx={{ mt: 2, mb: 4, flexGrow: 1 }}>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    order={{ xs: 2, md: 1 }}
                    alignContent="center"
                    textAlign={"center"}
                    sx={{ height: "100%" }}
                  >
                    <DataGrid
                      data-testid="tighteningTable"
                      apiRef={apiRef}
                      localeText={
                        langIsDe
                          ? GRID_DEFAULT_LOCALE_TEXT_DE
                          : GRID_DEFAULT_LOCALE_TEXT_EN
                      }
                      rows={tighteningResults}
                      getRowId={(row) =>
                        `${row.Step.ComponentNr}${row.Step.ScrewdrivingPointNr}`
                      }
                      columns={columns}
                      getRowClassName={(params) => rowClassNames(params)}
                      autoHeight={false}
                      sx={{
                        height: "100%",
                        maxWidth: "100%",
                        maxHeight: 368,
                        "& .MuiDataGrid-virtualScroller": {
                          overflow:
                            tighteningResults.length === 0 ? "hidden" : "auto",
                        },
                      }}
                      pageSizeOptions={[100]}
                      checkboxSelection={false}
                      disableColumnFilter={false}
                      disableColumnMenu={false}
                      disableVirtualization={false}
                      rowSelection={false}
                      hideFooter={true}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 50,
                          },
                        },
                      }}
                      slots={{
                        columnMenu: CustomColumnMenu,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    order={{ xs: 1, md: 2 }}
                    alignContent="center"
                    textAlign={"center"}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: 500 }}
                        dangerouslySetInnerHTML={{
                          __html:
                            status === ExecuteProcessStatus.Idle
                              ? getTranslatedText("StartTighteningFirstPiece")
                                .replace(
                                  "{ComponentNr}",
                                  currentStep.ComponentNr.toString()
                                )
                                .replace(
                                  "{ScrewdrivingPointNr}",
                                  currentStep.ScrewdrivingPointNr.toString()
                                )
                              : status === ExecuteProcessStatus.WaitForResult
                                ? (processingOrder ===
                                  ProcessingOrder.ComponentAfterComponent &&
                                  currentStep.ScrewdrivingPointNr === 1) ||
                                  processingOrder ===
                                  ProcessingOrder.ScrewdrivingPointAfterScrewdrivingPoint
                                  ? getTranslatedText(
                                    "WaitForTighteningNextPointOnNewComponent"
                                  )
                                    .replace(
                                      "{ComponentNr}",
                                      currentStep.ComponentNr.toString()
                                    )
                                    .replace(
                                      "{ScrewdrivingPointNr}",
                                      currentStep.ScrewdrivingPointNr.toString()
                                    )
                                  : getTranslatedText(
                                    "WaitForTighteningNextPoint"
                                  )
                                    .replace(
                                      "{ComponentNr}",
                                      currentStep.ComponentNr.toString()
                                    )
                                    .replace(
                                      "{ScrewdrivingPointNr}",
                                      currentStep.ScrewdrivingPointNr.toString()
                                    )
                                : getTranslatedText(
                                  "ClickNextToDisplayResults"
                                ),
                        }}
                      />
                      {status === ExecuteProcessStatus.Finished ? (
                        <Box
                          sx={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <SuccessAnimation
                            size={130}
                            speed={0.6}
                            loop={false}
                          />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            mt: "auto",
                          }}
                        >
                          <Box
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              borderRadius: "4px",
                              py: 2,
                              px: 2,
                              display: "flex",
                              gap: 2,
                              width: "100%",
                              minHeight: "100px",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <img
                              src="Images/Animation/screw.svg"
                              alt="screw"
                              height={60}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "end",
                                gap: 2,
                                width: "100%",
                                overflow: "hidden",
                                textAlign: "end",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  maxWidth: "100%",
                                  width: "100%",
                                  wordWrap: "break-word",
                                }}
                              >
                                {getTranslatedText("ScrewdrivingPoint")}
                              </Typography>
                              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                                {currentStep.ScrewdrivingPointNr}/
                                {
                                  processConfiguration.amountOfScrewdrivingPoints
                                }
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              borderRadius: "4px",
                              py: 2,
                              px: 2,
                              display: "flex",
                              gap: 2,
                              width: "100%",
                              minHeight: "100px",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <img
                              src="Images/Animation/workpiece.svg"
                              alt="component"
                              height={30}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "end",
                                gap: 2,
                                width: "100%",
                                overflow: "hidden",
                                textAlign: "end",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  maxWidth: "100%",
                                  width: "100%",
                                  wordWrap: "break-word",
                                }}
                              >
                                {getTranslatedText("Component")}
                              </Typography>
                              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                                {currentStep.ComponentNr}/
                                {processConfiguration.amountOfComponents}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="dark"
                    variant="contained"
                    sx={{ margin: 2 }}
                    onClick={() => setGetBackSecurityDialogOpen(true)}
                    className="BackButtonWizardContent"
                    startIcon={<FiArrowLeft />}
                  >
                    {getTranslatedText("PreviousStepButtonText")}
                  </Button>

                  <Button
                    color="success"
                    variant="contained"
                    disabled={!finished}
                    sx={{ margin: 2, color: "#fff" }}
                    onClick={() => {
                      var amountOfHeadRestsEqualNull = tighteningResults.filter(
                        (x) => x.HeadRestMomentNm === null
                      );
                      var amountOfDestructiveMomentsEqualNull =
                        tighteningResults.filter(
                          (x) => x.DestructiveMomentNm === null
                        );
                      if (
                        amountOfHeadRestsEqualNull.length === 0 &&
                        amountOfDestructiveMomentsEqualNull.length === 0
                      ) {
                        handleFinishExecuteProcess();
                        return;
                      }
                      setwarnUserThatOneOrMoreTighteningWithoutHeadRestOrDestructiveMomentDialogOpen(
                        true
                      );
                    }}
                    className="NextButtonWizardContent"
                    endIcon={<FiArrowRight />}
                  >
                    {getTranslatedText("NextStepButtonText")}
                  </Button>
                </Box>

                <YesNoDialog
                  title={getTranslatedText(
                    "ShouldReallyDeleteThisTighteningTitle"
                  )}
                  content={getTranslatedText(
                    "ShouldReallyDeleteThisTighteningContent"
                  )}
                  titleYesButton={getTranslatedText("AgreeDeleteTightening")}
                  titleNoButton={getTranslatedText("DisagreeDeleteTightening")}
                  open={shouldReallyDeleteTighteningDialogOpen}
                  onNoClick={() =>
                    setShouldReallyDeleteTighteningDialogOpen(false)
                  }
                  onYesClick={() => {
                    setShouldReallyDeleteTighteningDialogOpen(false);
                    dispatch(
                      deleteTighteningResultWithStep(tighteningToDeleteStep)
                    );
                    dispatch(dismissSingleToast(tighteningToDeleteStep));
                    LastStep();
                    publishWaitForNextTighteningIfProcessWasAlreadyFinished();
                  }}
                  dangerRight={false}
                />

                <YesNoDialog
                  title={getTranslatedText("GetBackSecurityDialogTitle")}
                  content={getTranslatedText("GetBackSecurityDialogText")}
                  titleYesButton={getTranslatedText(
                    "AgreeGetBackSecurityDialog"
                  )}
                  titleNoButton={getTranslatedText(
                    "DisagreeGetBackSecurityDialog"
                  )}
                  open={getBackSecurityDialogOpen}
                  onNoClick={() => setGetBackSecurityDialogOpen(false)}
                  onYesClick={() => {
                    setGetBackSecurityDialogOpen(false);
                    dispatch(decreaseCurrentStepId());
                    dispatch(resetProcessValues());
                    dispatch(addGuidToIgnore(currentTransactionGuid));
                    dispatch(dismissAllToastFromInfoMessageToasts());
                  }}
                  dangerRight={false}
                />

                <YesNoDialog
                  title={getTranslatedText(
                    "WarnUserThatOneOrMoreTighteningWithoutHeadRestOrDestructiveMomentDialogTitle"
                  )}
                  content={getTranslatedText(
                    "WarnUserThatOneOrMoreTighteningWithoutHeadRestOrDestructiveMomentDialogText"
                  )}
                  titleYesButton={getTranslatedText(
                    "AgreeWarnUserThatOneOrMoreTighteningWithoutHeadRestOrDestructiveMomentDialog"
                  )}
                  titleNoButton={getTranslatedText(
                    "DisagreeAgreeWarnUserThatOneOrMoreTighteningWithoutHeadRestOrDestructiveMomentDialog"
                  )}
                  open={
                    warnUserThatOneOrMoreTighteningWithoutHeadRestOrDestructiveMomentDialogOpen
                  }
                  onNoClick={() =>
                    setwarnUserThatOneOrMoreTighteningWithoutHeadRestOrDestructiveMomentDialogOpen(
                      false
                    )
                  }
                  onYesClick={() => {
                    setwarnUserThatOneOrMoreTighteningWithoutHeadRestOrDestructiveMomentDialogOpen(
                      false
                    );
                    handleFinishExecuteProcess();
                  }}
                  dangerRight={true}
                />
              </CardContent>
            </Card>
          </Grow>
        </>
      )}
    </LanguageConsumer>
  );
};

export default ExecuteProcess;

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
}
