import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Version } from "../../Versioninfo/Version";

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: globalThis.Settings
      ? globalThis.Settings.appInsightsInstrumentationKey
      : "09c3094c-bb08-4254-a8cc-6badbcfa17e6",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    isStorageUseDisabled: true,
    disableTelemetry: window.location.hostname === "localhost", // do not track in developer mode
    // disableTelemetry:false  // set to false if you want to track
  },
});
appInsights.loadAppInsights();
appInsights.context.application.ver = Version;
