export interface UploadProgramRequestPayloadDto {
  IpAddressOrDns: string;
  Username: string;
  Password: string;
  TimeOutInMilliseconds: number;
  ContentToSet: string;
}

export const DefaultUploadProgramRequestPayloadDto: UploadProgramRequestPayloadDto =
  {
    IpAddressOrDns: "",
    Username: "",
    Password: "",
    TimeOutInMilliseconds: 30000,
    ContentToSet: "",
  };
