import { Grid, Card, CardContent, Box, Container } from "@mui/material";
import React from "react";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { Helmet } from "react-helmet";
import { ThirdPartyLicenceNotesLanguage } from "./Languages/ThirdPartyLicenceNotesLanguage";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";
import "./Style/ThirdPartyLicenceNotes.css";

export const ThirdPartyLicenceNotes = () => {
  const language = useAppSelector((store) => store.settings.language);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
          <React.Fragment>
            <Helmet>
              <title>
                {getTranslatedText("ThirdPartyLicenseNotesLinkText")}
              </title>
            </Helmet>

            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={12} md={12}>
                <Card elevation={12}>
                  <CardContent>
                    <ThirdPartyLicenceNotesLanguage lang={language} />

                    <Box component={"div"} sx={{ overflowX: "auto", pt: 3 }}>
                      <table className="thirdPartyTable">
                        <thead>
                          <tr>
                            <th className="string">name</th>
                            <th className="string">material / not material</th>
                            <th className="string">license type</th>
                            <th className="string">author</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="string">@fontsource/comfortaa</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Lotus declininglotus@gmail.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">@fontsource/roboto</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Lotus declininglotus@gmail.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">@hookform/resolvers</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              bluebill1049 bluebill1049@hotmail.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">
                              @microsoft/applicationinsights-react-js
                            </td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Microsoft Application Insights Team
                            </td>
                          </tr>
                          <tr>
                            <td className="string">
                              @microsoft/applicationinsights-web
                            </td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Microsoft Application Insights Team
                            </td>
                          </tr>
                          <tr>
                            <td className="string">@mui/icons-material</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">MUI Team</td>
                          </tr>
                          <tr>
                            <td className="string">@mui/lab</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">MUI Team</td>
                          </tr>
                          <tr>
                            <td className="string">@mui/x-date-pickers</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">MUI Team</td>
                          </tr>
                          <tr>
                            <td className="string">@react-three/drei</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">@react-three/fiber</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Paul Henschel (https://github.com/drcmda)
                            </td>
                          </tr>
                          <tr>
                            <td className="string">@reactour/tour</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Lionel Tzatzkin</td>
                          </tr>
                          <tr>
                            <td className="string">@reduxjs/toolkit</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Mark Erikson mark@isquaredsoftware.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">
                              @testing-library/jest-dom
                            </td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Ernesto Garcia gnapse@gmail.com
                              (http://gnapse.github.io)
                            </td>
                          </tr>
                          <tr>
                            <td className="string">@testing-library/react</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Kent C. Dodds me@kentcdodds.com
                              (https://kentcdodds.com)
                            </td>
                          </tr>
                          <tr>
                            <td className="string">
                              @testing-library/react-hooks
                            </td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Michael Peyper mpeyper7@gmail.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">@types/jest</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">@types/papaparse</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">@types/react</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">@types/react-dom</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">
                              @types/react-image-gallery
                            </td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">@types/three</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">axios</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Matt Zabriskie</td>
                          </tr>
                          <tr>
                            <td className="string">base-64</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Mathias Bynens https://mathiasbynens.be/
                            </td>
                          </tr>
                          <tr>
                            <td className="string">body-scroll-lock</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Will Po</td>
                          </tr>
                          <tr>
                            <td className="string">browser-detect</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              PIGNOSE kennethan@nhpcw.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">cross-domain-storage</td>

                            <td className="string">material</td>
                            <td className="string">ISC</td>

                            <td className="string">
                              Matt Larner matt.larner.dev@gmail.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">customize-cra</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Adam Rackis</td>
                          </tr>
                          <tr>
                            <td className="string">date-fns</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">dayjs</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">iamkun</td>
                          </tr>
                          <tr>
                            <td className="string">file-select-dialog</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              TomokiMiyauci
                              development.operation.6.6.6@gmail.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">fscreen</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Rafael Pedicini rafael@rafgraph.dev
                            </td>
                          </tr>
                          <tr>
                            <td className="string">html2canvas</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Niklas von Hertzen niklasvh@gmail.com
                              https://hertzen.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">https-browserify</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              James Halliday mail@substack.net
                              http://substack.net
                            </td>
                          </tr>
                          <tr>
                            <td className="string">immer</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Michel Weststrate</td>
                          </tr>
                          <tr>
                            <td className="string">material-ui-popup-state</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Andy Edwards</td>
                          </tr>
                          <tr>
                            <td className="string">messagepack</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">mprot</td>
                          </tr>
                          <tr>
                            <td className="string">moment</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Iskren Ivov Chernev iskren.chernev@gmail.com
                              (https://github.com/ichernev)
                            </td>
                          </tr>
                          <tr>
                            <td className="string">papaparse</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Matthew Holt https://twitter.com/mholt6
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">react-beautiful-dnd</td>

                            <td className="string">material</td>
                            <td className="string">Apache-2.0</td>

                            <td className="string">
                              Alex Reardon areardon@atlassian.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-color</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              case case@casesandberg.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-cookie-consent</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Rick van Lieshout info@rickvanlieshout.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-copy-to-clipboard</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Nik Butenko nik@butenko.me
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-dom</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">react-dropzone</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Param Aggarwal</td>
                          </tr>
                          <tr>
                            <td className="string">react-helmet</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">NFL engineers@nfl.com</td>
                          </tr>
                          <tr>
                            <td className="string">react-hook-form</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">bluebill1049@hotmail.com</td>
                          </tr>
                          <tr>
                            <td className="string">react-icons</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Goran Gajic</td>
                          </tr>
                          <tr>
                            <td className="string">react-image-gallery</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Xiao Lin</td>
                          </tr>
                          <tr>
                            <td className="string">react-joyride</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Gil Barbara gilbarbara@gmail.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-json-tree</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Shu Uesugi shu@chibicode.com
                              (http://github.com/chibicode)
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-json-view</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Mac Gainor</td>
                          </tr>
                          <tr>
                            <td className="string">
                              react-keyboard-event-handler
                            </td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">David Lin</td>
                          </tr>
                          <tr>
                            <td className="string">react-lottie</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">react-redux</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Dan Abramov dan.abramov@me.com
                              (https://github.com/gaearon)
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-router-dom</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Remix Software hello@remix.run
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-router-hash-link</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Rafael Pedicini rafael@rafgraph.dev
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-scripts</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">react-spinners</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              David Hu (https://www.davidhu.io)
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-spreadsheet</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Iddan Aaronsohn mail@aniddan.com
                              (https://aniddan.com)
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-spreadsheet-grid</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Denis Raslov denis.raslov@yandex.ru
                            </td>
                          </tr>
                          <tr>
                            <td className="string">react-user-guide</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">sandeshshrestha</td>
                          </tr>
                          <tr>
                            <td className="string">react-uuid</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Rick Brown ricky.brown.00@gmail.com
                              (https://github.com/RickBr0wn/minimal-react-boilerplate)
                            </td>
                          </tr>
                          <tr>
                            <td className="string">reactour</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Lionel T elrumordelaluz@hotmail.com
                              (https://elrumordelaluz.com/)
                            </td>
                          </tr>
                          <tr>
                            <td className="string">redux-persist</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string"></td>
                          </tr>
                          <tr>
                            <td className="string">scheduler</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">scichart</td>

                            <td className="string">material</td>
                            <td className="string">ISC</td>

                            <td className="string">SciChart Ltd</td>
                          </tr>
                          <tr>
                            <td className="string">stream-http</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">John Hiesey</td>
                          </tr>
                          <tr>
                            <td className="string">styled-components</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">Glen Maddern</td>
                          </tr>
                          <tr>
                            <td className="string">three</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">mrdoob</td>
                          </tr>
                          <tr>
                            <td className="string">typeface-fira-sans</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Kyle Mathews mathews.kyle@gmail.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">typescript</td>

                            <td className="string">material</td>
                            <td className="string">Apache-2.0</td>

                            <td className="string">Microsoft Corp.</td>
                          </tr>
                          <tr>
                            <td className="string">url</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">n/a</td>
                          </tr>
                          <tr>
                            <td className="string">use-query-params</td>

                            <td className="string">material</td>
                            <td className="string">ISC</td>

                            <td className="string">
                              Peter Beshai peter.beshai@gmail.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">use-react-screenshot</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">vre2h</td>
                          </tr>
                          <tr>
                            <td className="string">web-vitals</td>

                            <td className="string">material</td>
                            <td className="string">Apache-2.0</td>

                            <td className="string">
                              Philip Walton philip@philipwalton.com
                              http://philipwalton.com
                            </td>
                          </tr>
                          <tr>
                            <td className="string">zod</td>

                            <td className="string">material</td>
                            <td className="string">MIT</td>

                            <td className="string">
                              Colin McDonnell colin@colinhacks.com
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </React.Fragment>
        </Container>
      )}
    </LanguageConsumer>
  );
};
