import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { MainCategories } from "../Model/MainCategories";
import {
  reset_analyse_downloadClientConnector,
  reset_analyse_copyClientIdAndConnect,
  reset_analyse_requestDataFromAst,
} from "../Redux/AnalyticsSlice";

export const usePublishButtonClickAnalytics = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const downloadClientConnectorState = useAppSelector(
    (store) => store.analytics.downloadClientConnector
  );
  const copyClientIdAndConnectState = useAppSelector(
    (store) => store.analytics.copyClientIdAndConnect
  );
  const requestDataFromAstState = useAppSelector(
    (store) => store.analytics.requestDataFromAst
  );

  const TrackEvent = (eventName: string, properties: any) => {
    return useTrackEvent(appInsights, eventName, properties, true);
  };

  const trackDownloadClientConnector = TrackEvent(
    MainCategories.DownloadClientConnector.toString(),
    {}
  );
  const trackCopyClientIdAndConnect = TrackEvent(
    MainCategories.CopyClientIdAndConnect.toString(),
    {}
  );
  const trackRequestDataFromAst = TrackEvent(
    MainCategories.RequestDataFromAst.toString(),
    {}
  );

  useEffect(() => {
    if (downloadClientConnectorState.toggle) {
      try {
        trackDownloadClientConnector({});
      } catch {}
      dispatch(reset_analyse_downloadClientConnector());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadClientConnectorState.toggle, dispatch]);

  useEffect(() => {
    if (copyClientIdAndConnectState.toggle) {
      try {
        trackCopyClientIdAndConnect({});
      } catch {}
      dispatch(reset_analyse_copyClientIdAndConnect());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyClientIdAndConnectState.toggle, dispatch]);

  useEffect(() => {
    if (requestDataFromAstState.toggle) {
      try {
        trackRequestDataFromAst({});
      } catch {}
      dispatch(reset_analyse_requestDataFromAst());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestDataFromAstState.toggle, dispatch]);
};
