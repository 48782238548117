import { useContext, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Grow,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import LoadingButton from "@mui/lab/LoadingButton";
import { WizardItems } from "../../../../../Infrastructure/Analytics/Model/Categories/WizardEnums";
import {
  analyse_requestDataFromAst,
  analyse_navigateWizard,
} from "../../../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import {
  setIpAddress,
  setIsConfigured,
  clearParameterInformation,
  setUsername,
  setPassword,
  setLoading,
} from "../../../../../Infrastructure/Configurations/AstConfiguration/Redux/AstConfigurationSlice";
import { isIPAddress } from "../../../../../Infrastructure/Helper/IpCheckProvider";
import {
  LanguageConsumer,
  getTranslation,
} from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { MqttContext } from "../../../../../Infrastructure/Mqtt/MqttService";
import { HardwareType } from "../../../../../Infrastructure/Mqtt/Enums/HardwareType";
import { MqttRequestDto } from "../../../../../Infrastructure/Mqtt/Models/MqttRequestDto";
import { MqttConnectionState } from "../../../../../Infrastructure/Mqtt/Redux/MqttStateSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Infrastructure/Redux/hooks";
import {
  decreaseCurrentStepId,
  increaseCurrentStepId,
} from "../../Redux/WizardSlice";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  DefaultDetectDeviceTypeRequestPayloadDto,
  DetectDeviceTypeRequestPayloadDto,
} from "../../../../../Infrastructure/Mqtt/Models/Commands/DetectDeviceType/DetectDeviceTypeRequestPayloadDto";
import { useValidation } from "../../../../../Infrastructure/Hooks/useValidation";
import * as z from "zod";
import { FiArrowLeft, FiArrowRight, FiCheck } from "react-icons/fi";
import { PiPlugsConnected } from "react-icons/pi";

interface ConfigureAstModel {
  IpAddress: string;
  Username: string;
  Password: string;
}

const ConfigureAst = () => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const ipAddressAst = useAppSelector(
    (store) => store.astConfiguration.ipAddressOrDns
  );
  const astConfiguration = useAppSelector((store) => store.astConfiguration);
  const mqttConnectionState = useAppSelector(
    (store) => store.mqttState.connectionState
  );
  const loading = useAppSelector((store) => store.astConfiguration.loading);
  const { publishMqtt } = useContext(MqttContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const configureAstSchema = z
    .object({
      IpAddress: z.string(),
      Username: z.string().min(1, getTranslation("UsernameNotValid")),
      Password: z.string().min(1, getTranslation("PasswordNotValid")),
    })
    .refine((data) => isIPAddress(data.IpAddress), {
      message: getTranslation("IpNotValid"),
      path: ["IpAddress"],
    });

  const { errors, canSubmit, checkSubmit } = useValidation(configureAstSchema, {
    IpAddress: ipAddressAst,
    Username: astConfiguration.username,
    Password: astConfiguration.password,
  } as ConfigureAstModel);

  const detectHardwareType = () => {
    if (!checkSubmit()) return;
    const request = {
      TransactionGuid: uuidv4(),
      Command: "DetectDeviceType",
      Payload: {
        ...DefaultDetectDeviceTypeRequestPayloadDto,
        Username: astConfiguration.username,
        Password: astConfiguration.password,
        IpAddressOrDns: ipAddressAst,
      } as DetectDeviceTypeRequestPayloadDto,
    } as MqttRequestDto;

    publishMqtt(request);
  };

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Grow in={true} appear={true}>
          <Card square={true} elevation={12} sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%", position: "relative" }}>
              <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                {getTranslatedText("ConfigureAstHeading")}
              </Typography>
              <div
                style={{ color: isDarkMode ? "#fff" : "#444" }}
                dangerouslySetInnerHTML={{
                  __html: getTranslatedText("ConfigureAstText"),
                }}
              ></div>

              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} alignContent="center" textAlign={"center"}>
                  <TextField
                    error={!!errors["IpAddress"]}
                    helperText={errors["IpAddress"]}
                    autoFocus
                    fullWidth
                    value={ipAddressAst}
                    label={getTranslatedText("IpAdressAst")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      dispatch(setIpAddress(e.target.value));
                      dispatch(setIsConfigured(false));
                      dispatch(clearParameterInformation());
                    }}
                  />
                </Grid>
                <Grid item xs={6} alignContent="center" textAlign={"center"}>
                  <TextField
                    error={!!errors["Username"]}
                    helperText={errors["Username"]}
                    fullWidth
                    id="outlined-basic"
                    value={astConfiguration.username}
                    label={getTranslatedText("UsernameAst")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      dispatch(setUsername(e.target.value));
                      dispatch(setIsConfigured(false));
                      dispatch(clearParameterInformation());
                    }}
                  />
                </Grid>
                <Grid item xs={6} alignContent="center" textAlign={"center"}>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    error={!!errors["Password"]}
                    helperText={errors["Password"]}
                    fullWidth
                    value={astConfiguration.password}
                    label={getTranslatedText("PasswordAst")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      dispatch(setPassword(e.target.value));
                      dispatch(setIsConfigured(false));
                      dispatch(clearParameterInformation());
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    fullWidth
                    color="success"
                    variant={
                      astConfiguration.isConfigured ? "outlined" : "contained"
                    }
                    loading={loading}
                    loadingPosition="start"
                    disabled={!canSubmit || astConfiguration.isConfigured}
                    sx={{
                      color: astConfiguration.isConfigured
                        ? "#32992C !important"
                        : "#fff",
                      border: astConfiguration.isConfigured
                        ? "2px solid rgba(50, 153, 44, 0.5) !important"
                        : "unset",
                    }}
                    onClick={() => {
                      dispatch(setIsConfigured(false));
                      dispatch(clearParameterInformation());
                      dispatch(setLoading(true));
                      dispatch(analyse_requestDataFromAst());
                      detectHardwareType();
                    }}
                    startIcon={
                      astConfiguration.isConfigured ? (
                        <FiCheck />
                      ) : (
                        <PiPlugsConnected />
                      )
                    }
                  >
                    {getTranslatedText(
                      loading
                        ? "EstablishingConnection"
                        : astConfiguration.isConfigured
                          ? "ConnectionActive"
                          : "GetAstDataButton"
                    )}
                  </LoadingButton>
                </Grid>
              </Grid>
              {astConfiguration.isConfigured && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "start", sm: "center" },
                    mt: 2,
                    mb: 3,
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      border: "2px solid rgba(50, 153, 44, 0.5)",
                      p: 1,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <img
                      src="/Images/Animation/ast-default.svg"
                      alt="ast"
                      height={80}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mr: 1,
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        {HardwareType[
                          astConfiguration.parameterInformation.controllerType
                        ].toUpperCase()}
                      </Typography>
                      <Typography>
                        {getTranslatedText("SerialNumberShorted")}
                        {": "}
                        {
                          astConfiguration.parameterInformation
                            .controllerSerialNumber
                        }
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      border: "2px solid rgba(50, 153, 44, 0.5)",
                      p: 1,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <img
                      src="/Images/Animation/screwer-raw.svg"
                      alt="screwer"
                      height={80}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mr: 1,
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        {astConfiguration.parameterInformation.screwDriverType}
                      </Typography>
                      <Typography>
                        {getTranslatedText("SerialNumberShorted")}
                        {": "}
                        {
                          astConfiguration.parameterInformation
                            .screwDriverSerialNumber
                        }
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="dark"
                  variant="contained"
                  sx={{ margin: 2 }}
                  onClick={() => dispatch(decreaseCurrentStepId())}
                  className="BackButtonWizardContent"
                  startIcon={<FiArrowLeft />}
                >
                  {getTranslatedText("PreviousStepButtonText")}
                </Button>

                <Button
                  color="success"
                  variant="contained"
                  sx={{ margin: 2, color: "#fff" }}
                  onClick={() => {
                    dispatch(increaseCurrentStepId());
                    dispatch(
                      analyse_navigateWizard({
                        currentStep: WizardItems.ConfigureAst,
                      })
                    );
                  }}
                  disabled={
                    !astConfiguration.isConfigured ||
                    (astConfiguration.parameterInformation.controllerType !==
                      HardwareType.Ast11 &&
                      astConfiguration.parameterInformation.controllerType !==
                        HardwareType.Ast12 &&
                      astConfiguration.parameterInformation.controllerType !==
                        HardwareType.Ast40) ||
                    mqttConnectionState !==
                      MqttConnectionState.ConnectionWithCloudConnectorActive
                  }
                  className="NextButtonWizardContent"
                  endIcon={<FiArrowRight />}
                >
                  {getTranslatedText("NextStepButtonText")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grow>
      )}
    </LanguageConsumer>
  );
};

export default ConfigureAst;
