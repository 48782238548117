import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { WizardEvents } from "../Model/Categories/WizardEnums";
import { reset_analyse_navigateWizard } from "../Redux/AnalyticsSlice";

export const usePublishWizardAnalytics = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const navigateWizardState = useAppSelector(
    (store) => store.analytics.navigateWizard
  );

  const TrackEvent = (eventName: string, properties: any) => {
    return useTrackEvent(appInsights, eventName, properties, true);
  };

  const trackNavigateWizard = TrackEvent(WizardEvents.Next.toString(), {});

  useEffect(() => {
    if (navigateWizardState.toggle) {
      try {
        trackNavigateWizard({
          CurrentStep: navigateWizardState.currentStep.toString(),
        });
      } catch {}
      dispatch(reset_analyse_navigateWizard());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateWizardState.toggle, dispatch]);
};
