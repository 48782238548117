import { Step, Stepper, Box, StepLabel } from "@mui/material";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppSelector } from "../../../../Infrastructure/Redux/hooks";

const WizardStepper = () => {
  const allSteps = useAppSelector((store) =>
    store.wizard.stepList.map((x) => x)
  );
  const activeStep = useAppSelector((store) => store.wizard.currentStepId);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{ height: "100%" }}
          >
            {allSteps.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel>
                    {getTranslatedText(step.textIdentifierWizardStep)}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      )}
    </LanguageConsumer>
  );
};

export default WizardStepper;
