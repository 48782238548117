/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grow,
  Card,
  CardContent,
  Grid,
  Container,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { Helmet } from "react-helmet";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";
import WizardStepper from "./Wizard/Stepper/WizardStepper";
import WizardStepperMobile from "./Wizard/Stepper/WizardStepperMobile";
import { ComponentIdentifierToComponentMapper } from "./Wizard/Mapper/ComponentIdentifierToComponentMapper";
import { useNavigate } from "react-router-dom";
import HandleMqttConnectionLostDialog from "./Wizard/Components/HandleMqttConnectionLostDialog";
import { AnimationHandler } from "./Components/AnimationHandler";
import { useCheckApiKey } from "../../Infrastructure/LicenceService/Hooks/useCheckApiKey";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const Home = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const wizardSteps = useAppSelector((store) => store.wizard);
  const isLicenced = useAppSelector(
    (store) => store.licenceService.licenceAuthentication.isLicenced
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  useCheckApiKey();

  useEffect(() => {
    if (!isLicenced) {
      navigate("/");
    }
  }, [isLicenced]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <React.Fragment>
      <LanguageConsumer>
        {({ getTranslatedText }) => (
          <>
            <HandleMqttConnectionLostDialog />

            <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
              <React.Fragment>
                <Helmet>
                  <title>{getTranslatedText("Schraubfallanalyse")}</title>
                </Helmet>

                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    height: "fit-content",
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    maxHeight={"1000px"}
                    alignItems={"stretch"}
                    alignContent={"stretch"}
                    direction={"row"}
                    columnSpacing={isMobile ? 0 : 2}
                    rowSpacing={isMobile ? 2 : 0}
                    sx={{ display: "flex", width: "100%" }}
                  >
                    <Grid item xs={12} md={3} sx={{ height: "auto" }}>
                      <Grow in={true} appear={true}>
                        <Card
                          square={true}
                          elevation={12}
                          sx={{ height: "100%" }}
                        >
                          <CardContent sx={{ height: "100%" }}>
                            {windowDimensions.width < 900 ? (
                              <WizardStepperMobile />
                            ) : (
                              <WizardStepper />
                            )}
                          </CardContent>
                        </Card>
                      </Grow>
                    </Grid>
                    <Grid item xs={12} md={9} sx={{ height: "auto" }}>
                      {
                        ComponentIdentifierToComponentMapper[
                          wizardSteps.stepList.filter(
                            (x) => x.id === wizardSteps.currentStepId
                          )[0].componentIdentifier
                        ]
                      }
                    </Grid>
                  </Grid>
                  <AnimationHandler />
                </Box>
              </React.Fragment>
            </Container>
          </>
        )}
      </LanguageConsumer>
    </React.Fragment>
  );
};
