import { TorqueUnit } from "../../../../Features/Settings/Enum/TorqueUnit";
import {
  setNotification,
  NotificationType,
} from "../../../../Layout/Notifications/Redux/NotificationsSlice";
import { useAlgorithm } from "../../../Algorithm/useAlgorithm";
import { analyse_finishExecuteProcess } from "../../../Analytics/Redux/AnalyticsSlice";
import {
  addTighteningResult,
  MonitorNextTighteningResult,
  setStatus,
  ExecuteProcessStatus,
  appendToastIdsFromInfoMessageToasts,
} from "../../../Configurations/ExecuteProcess/Redux/ExecuteProcessSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { ConvertTorqueUnit } from "../../../UnitConverter/TorqueUnitConverter";
import {
  DefaultMonitorNextTighteningRequestPayloadDto,
  MonitorNextTighteningRequestPayloadDto,
} from "../../Models/Commands/MonitorNextTightening/MonitorNextTighteningRequestPayloadDto";
import { MonitorNextTighteningResultPayloadDto } from "../../Models/Commands/MonitorNextTightening/MonitorNextTighteningResultPayloadDto";
import { MqttRequestDto } from "../../Models/MqttRequestDto";
import { v4 as uuidv4 } from "uuid";
import { UnitConvertStrategy } from "../../../../Infrastructure/UnitConverter/Enum/UnitConvertStrategy";

interface UseHandleUploadProgramCommandArgs {
  mqttPublish: (payload: {}, topic?: string) => void;
}

export const useHandleMonitorNextTighteningCommandResult = ({
  mqttPublish,
}: UseHandleUploadProgramCommandArgs) => {
  const dispatch = useAppDispatch();
  const astConfiguration = useAppSelector((store) => store.astConfiguration);
  const processConfiguration = useAppSelector(
    (store) => store.processConfiguration
  );
  const currentStepId = useAppSelector((store) => store.wizard.currentStepId);
  const currentTorqueUnit = useAppSelector(
    (store) => store.settings.torqueUnit
  );
  const tighteningResults = useAppSelector(
    (store) => store.executeProcess.tighteningResults
  );
  const currentExecuteStatus = useAppSelector(
    (store) => store.executeProcess.status
  );
  const currentExecutionStep = useAppSelector(
    (store) => store.executeProcess.currentStep
  );
  const { NextStep, amount } = useAlgorithm();

  const handleMonitorNextTighteningCommandResultSuccess = (result: {}) => {
    if (currentStepId !== 4) {
      return;
    }
    const parsedResult = result as MonitorNextTighteningResultPayloadDto;

    if (currentTorqueUnit !== TorqueUnit[parsedResult.TorqueUnit]) {
      parsedResult.Torque1 = ConvertTorqueUnit({
        sourceTorqueUnit: (TorqueUnit as any)[parsedResult.TorqueUnit],
        sourceTorque: parsedResult.Torque1,
        targetTorqueUnit: currentTorqueUnit,
        convertStrategy: UnitConvertStrategy.RoundNormal,
      });
      parsedResult.Torque2 = ConvertTorqueUnit({
        sourceTorqueUnit: (TorqueUnit as any)[parsedResult.TorqueUnit],
        sourceTorque: parsedResult.Torque2,
        targetTorqueUnit: currentTorqueUnit,
        convertStrategy: UnitConvertStrategy.RoundNormal,
      });
      parsedResult.Torque3 = ConvertTorqueUnit({
        sourceTorqueUnit: (TorqueUnit as any)[parsedResult.TorqueUnit],
        sourceTorque: parsedResult.Torque3,
        targetTorqueUnit: currentTorqueUnit,
        convertStrategy: UnitConvertStrategy.RoundNormal,
      });
      parsedResult.TorqueNok = ConvertTorqueUnit({
        sourceTorqueUnit: (TorqueUnit as any)[parsedResult.TorqueUnit],
        sourceTorque: parsedResult.TorqueNok,
        targetTorqueUnit: currentTorqueUnit,
        convertStrategy: UnitConvertStrategy.RoundNormal,
      });
    }

    const wasLastTighteningResult = tighteningResults.length + 1 === amount;
    dispatch(
      addTighteningResult({
        IpAddressOrDns: parsedResult.IpAddressOrDns,
        FinalResult: parsedResult.FinalResult,
        GraphFile: parsedResult.GraphFile,
        GraphFileDownloadLink: parsedResult.GraphFileDownloadLink,
        TighteningStatus: parsedResult.TighteningStatus,
        Angle1: parsedResult.Angle1,
        Angle2: parsedResult.Angle2,
        Angle3: parsedResult.Angle3,
        Torque1: parsedResult.Torque1,
        Torque2: parsedResult.Torque2,
        Torque3: parsedResult.Torque3,
        AngleNok: parsedResult.AngleNok,
        TorqueNok: parsedResult.TorqueNok,
        DurationInMilliseconds: parsedResult.DurationInMilliseconds,
        HeadRestMomentNm: parsedResult.HeadRestMoment,
        DestructiveMomentNm: parsedResult.DestructiveMoment,
        Info: parsedResult.Info,
        Step: currentExecutionStep,
      } as MonitorNextTighteningResult)
    );

    if (!parsedResult.Info) {
      if (wasLastTighteningResult) {
        dispatch(
          setNotification({
            type: NotificationType.success,
            text: "FinishedProcessSuccessfully",
          })
        );
      } else {
        dispatch(
          setNotification({
            type: NotificationType.success,
            text: "ReceivedTighteningResult",
          })
        );
      }
    } else {
      var currentToastId = uuidv4();
      dispatch(
        setNotification({
          type: NotificationType.error,
          text: "ReceivedTighteningResultWithInfoMessage",
          extraTextProps: [
            currentExecutionStep.ScrewdrivingPointNr.toString(),
            currentExecutionStep.ComponentNr.toString(),
          ],
          extendedNotification: { text: parsedResult.Info },
          toastId: currentToastId,
        })
      );
      dispatch(
        appendToastIdsFromInfoMessageToasts({
          step: currentExecutionStep,
          id: currentToastId,
        })
      );
    }

    if (wasLastTighteningResult) {
      dispatch(
        analyse_finishExecuteProcess({
          controllerType: astConfiguration.parameterInformation.controllerType,
          amountOfScrewdrivingPoints:
            processConfiguration.amountOfScrewdrivingPoints,
        })
      );
      dispatch(setStatus(ExecuteProcessStatus.Finished));
    } else {
      if (currentExecuteStatus !== ExecuteProcessStatus.WaitForResult) {
        dispatch(setStatus(ExecuteProcessStatus.WaitForResult));
      }
      NextStep();
      const monitorNextTighteningRequest = {
        TransactionGuid: uuidv4(),
        Command: "MonitorNextTightening",
        Payload: {
          ...DefaultMonitorNextTighteningRequestPayloadDto,
          IpAddressOrDns: astConfiguration.ipAddressOrDns,
          Username: astConfiguration.username,
          Password: astConfiguration.password,
          ExpectedProgramNumber: processConfiguration.programNumber,
        } as MonitorNextTighteningRequestPayloadDto,
      } as MqttRequestDto;

      mqttPublish(monitorNextTighteningRequest);
    }
  };
  const handleMonitorNextTighteningCommandResultFailed = () => {
    const monitorNextTighteningRequest = {
      TransactionGuid: uuidv4(),
      Command: "MonitorNextTightening",
      Payload: {
        ...DefaultMonitorNextTighteningRequestPayloadDto,
        IpAddressOrDns: astConfiguration.ipAddressOrDns,
        Username: astConfiguration.username,
        Password: astConfiguration.password,
        ExpectedProgramNumber: processConfiguration.programNumber,
      } as MonitorNextTighteningRequestPayloadDto,
    } as MqttRequestDto;

    mqttPublish(monitorNextTighteningRequest);
  };

  return {
    handleMonitorNextTighteningCommandResultSuccess,
    handleMonitorNextTighteningCommandResultFailed,
  };
};
