export interface IDefaultStep {
  componentIdentifier: string;
  textIdentifierInWizardStep: string;
}

export const DefaultStepList: IDefaultStep[] = [
  {
    componentIdentifier: "Start",
    textIdentifierInWizardStep: "StartupStepWizardText",
  },
  {
    componentIdentifier: "DownloadAndStartClient",
    textIdentifierInWizardStep: "DownloadAndStartClientWizardText",
  },
  {
    componentIdentifier: "ConfigureAst",
    textIdentifierInWizardStep: "ConfigureAstWizardText",
  },
  {
    componentIdentifier: "ParamProcess",
    textIdentifierInWizardStep: "ParamProcessWizardText",
  },
  {
    componentIdentifier: "ExecuteProcess",
    textIdentifierInWizardStep: "ExecuteProcessWizardText",
  },
  {
    componentIdentifier: "EnterCustomerDetails",
    textIdentifierInWizardStep: "EnterCustomerDetailsWizardText",
  },
  {
    componentIdentifier: "EnterElementDetails",
    textIdentifierInWizardStep: "EnterElementDetailsWizardText",
  },
  {
    componentIdentifier: "DisplayResults",
    textIdentifierInWizardStep: "DisplayResultsWizardText",
  },
];
