import {
  setRefreshToken,
  setToken,
} from "../LicenceService/Redux/LicenceServiceSlice";
import { RootState } from "../Redux/store";
import { baseUrl } from "./BaseApi";

interface RefreshTokenRequest {
  Token: string;
}

export const refreshingAuthenticationData = async (
  refreshToken: RefreshTokenRequest,
  dispatch: (action: { payload: undefined; type: string }) => {
    payload: undefined;
    type: string;
  }
): Promise<string | null> => {
  const url = baseUrl + "auth/refreshtoken";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(refreshToken),
  });

  var jsonResponse = await response.json();

  if (jsonResponse.success) {
    let payload = jsonResponse.payload;
    dispatch(setToken(payload.token));
    dispatch(setRefreshToken(payload.refreshToken));

    return payload.token;
  }

  return null;
};

export const checkIfTokenIsStillValidAndRefreshIfNecessary = async (
  dispatch: (action: { payload: undefined; type: string }) => {
    payload: undefined;
    type: string;
  },
  rootState: RootState
) => {
  let currentDate = new Date();
  let recievedTokenDate = rootState.licenceService.licenceAuthentication
    .tokenReceived
    ? new Date(rootState.licenceService.licenceAuthentication.tokenReceived)
    : new Date(2000, 1, 1, 0, 0, 0, 0);
  let secondsBetweenValid = 15 * 60;
  let difDates = currentDate.getTime() - recievedTokenDate.getTime();
  let secondsBetweenCalculated = difDates / 1000;
  let newToken = null as string | null;

  if (secondsBetweenCalculated > secondsBetweenValid) {
    newToken = await handleRefreshToken(dispatch, rootState);
  }
  return newToken;
};

const handleRefreshToken = async (
  dispatch: (action: { payload: undefined; type: string }) => {
    payload: undefined;
    type: string;
  },
  rootState?: RootState
) => {
  if (dispatch && rootState) {
    var refreshToken =
      rootState.licenceService.licenceAuthentication.refreshToken;
    if (refreshToken) {
      let refreshTokenObject = { Token: refreshToken } as RefreshTokenRequest;
      let newToken = await refreshingAuthenticationData(
        refreshTokenObject,
        dispatch
      );
      return newToken;
    }

    return null;
  }

  return null;
};
