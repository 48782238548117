import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Grow,
} from "@mui/material";
import { WizardItems } from "../../../../../Infrastructure/Analytics/Model/Categories/WizardEnums";
import { analyse_navigateWizard } from "../../../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import {
  setContactPersonSureName,
  setContactPersonLastName,
  setCompanyName,
  setStreet,
  setHouseNr,
  setZipCode,
  setCity,
} from "../../../../../Infrastructure/Configurations/CustomerDetailsConfiguration/Redux/CustomerDetailsConfigurationSlice";
import {
  LanguageConsumer,
  getTranslation,
} from "../../../../../Infrastructure/Internationalisation/TranslationService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Infrastructure/Redux/hooks";
import {
  decreaseCurrentStepId,
  increaseCurrentStepId,
} from "../../Redux/WizardSlice";
import { useValidation } from "../../../../../Infrastructure/Hooks/useValidation";
import * as z from "zod";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

interface EnterCustomerDetailsModel {
  SureName: string;
  LastName: string;
  CompanyName: string;
}

const EnterCustomerDetails = () => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const companyName = useAppSelector(
    (store) => store.customerDetailsConfiguration.companyName
  );
  const contactPersonSureName = useAppSelector(
    (store) => store.customerDetailsConfiguration.contactPersonSureName
  );
  const contactPersonLastName = useAppSelector(
    (store) => store.customerDetailsConfiguration.contactPersonLastName
  );
  const street = useAppSelector(
    (store) => store.customerDetailsConfiguration.street
  );
  const houseNr = useAppSelector(
    (store) => store.customerDetailsConfiguration.houseNr
  );
  const city = useAppSelector(
    (store) => store.customerDetailsConfiguration.city
  );
  const zipCode = useAppSelector(
    (store) => store.customerDetailsConfiguration.zipCode
  );

  const enterCustomerDetailsSchema = z.object({
    SureName: z.string().min(1, getTranslation("StringCannotBeEmpty")),
    LastName: z.string().min(1, getTranslation("StringCannotBeEmpty")),
    CompanyName: z.string().min(1, getTranslation("StringCannotBeEmpty")),
  });

  const { errors, canSubmit, checkSubmit } = useValidation(
    enterCustomerDetailsSchema,
    {
      SureName: contactPersonSureName,
      LastName: contactPersonLastName,
      CompanyName: companyName,
    } as EnterCustomerDetailsModel
  );

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Grow in={true} appear={true}>
          <Card square={true} elevation={12} sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%", position: "relative" }}>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
                {getTranslatedText("EnterCustomerDetailsHeading")}
              </Typography>
              <div
                style={{ color: isDarkMode ? "#fff" : "#444" }}
                dangerouslySetInnerHTML={{
                  __html: getTranslatedText("EnterCustomerDetailsText"),
                }}
              ></div>

              <Grid container spacing={2} sx={{ mt: 2, pb: 4 }}>
                <Grid item xs={12} alignContent="center" textAlign={"start"}>
                  <Typography variant="inherit" sx={{ fontWeight: "medium" }}>
                    {getTranslatedText("ContactPerson")}
                  </Typography>
                </Grid>
                <Grid item xs={6} alignContent="center" textAlign={"center"}>
                  <TextField
                    error={!!errors["SureName"]}
                    helperText={errors["SureName"]}
                    autoFocus
                    required
                    fullWidth
                    id="outlined-basic"
                    value={contactPersonSureName}
                    label={getTranslatedText("ContactPersonSureName")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      dispatch(setContactPersonSureName(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={6} alignContent="center" textAlign={"center"}>
                  <TextField
                    error={!!errors["LastName"]}
                    helperText={errors["LastName"]}
                    required
                    fullWidth
                    id="outlined-basic"
                    value={contactPersonLastName}
                    label={getTranslatedText("ContactPersonLastName")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      dispatch(setContactPersonLastName(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={12} alignContent="center" textAlign={"start"}>
                  <Typography variant="inherit" sx={{ fontWeight: "medium" }}>
                    {getTranslatedText("Address")}
                  </Typography>
                </Grid>
                <Grid item xs={12} alignContent="center" textAlign={"center"}>
                  <TextField
                    error={!!errors["CompanyName"]}
                    helperText={errors["CompanyName"]}
                    required
                    fullWidth
                    value={companyName}
                    label={getTranslatedText("CompanyName")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      dispatch(setCompanyName(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={10} alignContent="center" textAlign={"center"}>
                  <TextField
                    error={!!errors["Street"]}
                    helperText={errors["Street"]}
                    fullWidth
                    id="outlined-basic"
                    value={street}
                    label={getTranslatedText("Street")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      dispatch(setStreet(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={2} alignContent="center" textAlign={"center"}>
                  <TextField
                    error={!!errors["HouseNr"]}
                    helperText={errors["HouseNr"]}
                    fullWidth
                    id="outlined-basic"
                    value={houseNr}
                    label={getTranslatedText("HouseNr")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      dispatch(setHouseNr(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={6} alignContent="center" textAlign={"center"}>
                  <TextField
                    error={!!errors["ZipCode"]}
                    helperText={errors["ZipCode"]}
                    fullWidth
                    id="outlined-basic"
                    value={zipCode}
                    label={getTranslatedText("ZipCode")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      dispatch(setZipCode(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={6} alignContent="center" textAlign={"center"}>
                  <TextField
                    error={!!errors["City"]}
                    helperText={errors["City"]}
                    fullWidth
                    id="outlined-basic"
                    value={city}
                    label={getTranslatedText("City")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      dispatch(setCity(e.target.value));
                    }}
                  />
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="dark"
                  variant="contained"
                  sx={{ margin: 2 }}
                  onClick={() => dispatch(decreaseCurrentStepId())}
                  className="BackButtonWizardContent"
                  startIcon={<FiArrowLeft />}
                >
                  {getTranslatedText("PreviousStepButtonText")}
                </Button>

                <Button
                  color="success"
                  variant="contained"
                  sx={{ margin: 2, color: "#fff" }}
                  onClick={() => {
                    if (!checkSubmit()) return;
                    dispatch(increaseCurrentStepId());
                    dispatch(
                      analyse_navigateWizard({
                        currentStep: WizardItems.EnterCustomerDetails,
                      })
                    );
                  }}
                  disabled={!canSubmit}
                  className="NextButtonWizardContent"
                  endIcon={<FiArrowRight />}
                >
                  {getTranslatedText("NextStepButtonText")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grow>
      )}
    </LanguageConsumer>
  );
};

export default EnterCustomerDetails;
