import { MainCategories } from "../MainCategories";

export enum FooterEvents {
  Open = MainCategories.Footer + "Open",
}

export enum FooterItems {
  LinkedIn = "LinkedIn",
  Instagram = "Instagram",
  Facebook = "Facebook",
  Twitter = "Twitter",
  Youtube = "Youtube",
  DepragHomepage = "DepragHomepage",
}
