import { TorqueUnit } from "../../Features/Settings/Enum/TorqueUnit";
import { UnitConvertStrategy } from "./Enum/UnitConvertStrategy";

interface ConvertToBaseTorqueUnitArgs {
  sourceTorqueUnit: TorqueUnit;
  sourceTorque: number;
}

interface ConvertTorqueUnitArgs extends ConvertToBaseTorqueUnitArgs {
  targetTorqueUnit: TorqueUnit;
  convertStrategy: UnitConvertStrategy;
}

export const ConvertTorqueUnitAsString = ({
  sourceTorqueUnit,
  sourceTorque,
  targetTorqueUnit,
  convertStrategy,
}: ConvertTorqueUnitArgs): string => {
  if (!sourceTorque) {
    return "0";
  }

  var torqueInNm = ConvertToBaseTorqueUnit({ sourceTorqueUnit, sourceTorque });

  switch (targetTorqueUnit) {
    case TorqueUnit.Ncm:
      return RoundValue(torqueInNm * 100, 2, convertStrategy).toFixed(2);
    case TorqueUnit.Kgm:
      return RoundValue(
        torqueInNm * 0.101971621298,
        3,
        convertStrategy
      ).toFixed(3);
    case TorqueUnit.Kgcm:
      return RoundValue(torqueInNm * 10.1971621298, 2, convertStrategy).toFixed(
        2
      );
    case TorqueUnit.Inlb:
      return RoundValue(torqueInNm * 8.85074576738, 2, convertStrategy).toFixed(
        2
      );
    case TorqueUnit.Ftlb:
      return RoundValue(
        torqueInNm * 0.737562149277,
        3,
        convertStrategy
      ).toFixed(3);
    default:
      return RoundValue(torqueInNm, 2, convertStrategy).toFixed(2);
  }
};

export const ConvertTorqueUnit = ({
  sourceTorqueUnit,
  sourceTorque,
  targetTorqueUnit,
  convertStrategy,
}: ConvertTorqueUnitArgs): number => {
  if (!sourceTorque) {
    return 0;
  }

  var torqueInNm = ConvertToBaseTorqueUnit({ sourceTorqueUnit, sourceTorque });

  switch (targetTorqueUnit) {
    case TorqueUnit.Ncm:
      return RoundValue(torqueInNm * 100, 2, convertStrategy);
    case TorqueUnit.Kgm:
      return RoundValue(torqueInNm * 0.101971621298, 3, convertStrategy);
    case TorqueUnit.Kgcm:
      return RoundValue(torqueInNm * 10.1971621298, 2, convertStrategy);
    case TorqueUnit.Inlb:
      return RoundValue(torqueInNm * 8.85074576738, 2, convertStrategy);
    case TorqueUnit.Ftlb:
      return RoundValue(torqueInNm * 0.737562149277, 3, convertStrategy);
    default:
      return RoundValue(torqueInNm, 2, convertStrategy);
  }
};

const ConvertToBaseTorqueUnit = ({
  sourceTorqueUnit,
  sourceTorque,
}: ConvertToBaseTorqueUnitArgs): number => {
  switch (sourceTorqueUnit) {
    case TorqueUnit.Ncm:
      return sourceTorque / 100;
    case TorqueUnit.Kgm:
      return sourceTorque / 0.101971621298;
    case TorqueUnit.Kgcm:
      return sourceTorque / 10.1971621298;
    case TorqueUnit.Inlb:
      return sourceTorque / 8.85074576738;
    case TorqueUnit.Ftlb:
      return sourceTorque / 0.737562149277;
    case TorqueUnit.Nm:
      return sourceTorque;
    default:
      return sourceTorque / 141.611932666; // in-oz, not available in TorqueUnit-Enum and AST11
  }
};

const RoundValue = (
  source: number,
  digits: number,
  convertStrategy: UnitConvertStrategy
): number => {
  switch (convertStrategy) {
    case UnitConvertStrategy.RoundNormal:
      return parseFloat(source.toFixed(digits));
    case UnitConvertStrategy.RoundDown:
      return Math.floor(source * Math.pow(10, digits)) / Math.pow(10, digits);
    case UnitConvertStrategy.RoundUp:
      return Math.ceil(source * Math.pow(10, digits)) / Math.pow(10, digits);
    default:
      return parseFloat(source.toFixed(digits));
  }
};
