import { useEffect } from "react";
import { useAppDispatch } from "../../Infrastructure/Redux/hooks";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { setMyDepragApiKey } from "../../Infrastructure/LicenceService/Redux/LicenceServiceSlice";
import {
  setAppTheme,
  setLanguage,
  setReportProcessorCompany,
  setReportProcessorEmailAdress,
  setReportProcessorFirstname,
  setReportProcessorLastname,
} from "../Settings/Redux/SettingsSlice";
import {
  setCity,
  setCompanyName,
  setContactPersonLastName,
  setContactPersonSureName,
  setHouseNr,
  setStreet,
  setZipCode,
} from "../../Infrastructure/Configurations/CustomerDetailsConfiguration/Redux/CustomerDetailsConfigurationSlice";
import {
  setFeedbackCompany,
  setFeedbackEmail,
  setFeedbackLastName,
  setFeedbackName,
} from "../Feedback/Redux/FeedbackSlice";

export const useCheckQueryParams = () => {
  const dispatch = useAppDispatch();

  const [query] = useQueryParams({
    email: StringParam,
    licenceKey: StringParam,
  });

  const [apiKeyQuery] = useQueryParams({
    myDeprag: NumberParam,
    apiKey: StringParam,
    language: StringParam,
    theme: StringParam,
    emailUser: StringParam,
    city: StringParam,
    company: StringParam,
    firstName: StringParam,
    lastName: StringParam,
    houseNumber: StringParam,
    street: StringParam,
    postalCode: StringParam,
  });

  const {
    apiKey,
    language,
    theme,
    city,
    company,
    firstName,
    lastName,
    houseNumber,
    street,
    postalCode,
  } = apiKeyQuery;
  const { email } = query;

  useEffect(() => {
    if (apiKey !== undefined && apiKey && apiKey.trim().length > 0) {
      dispatch(setMyDepragApiKey(apiKey));
    }

    if (language !== undefined && language && language.trim().length > 0) {
      dispatch(setLanguage(language));
    }

    if (theme !== undefined && theme && theme.trim().length > 0) {
      dispatch(setAppTheme(theme));
    }

    if (firstName !== undefined && firstName && firstName.trim().length > 0) {
      dispatch(setContactPersonSureName(firstName));
      dispatch(setReportProcessorFirstname(firstName));
      dispatch(setFeedbackName(firstName));
    }

    if (lastName !== undefined && lastName && lastName.trim().length > 0) {
      dispatch(setContactPersonLastName(lastName));
      dispatch(setReportProcessorLastname(lastName));
      dispatch(setFeedbackLastName(lastName));
    }

    if (company !== undefined && company && company.trim().length > 0) {
      dispatch(setCompanyName(company));
      dispatch(setReportProcessorCompany(company));
      dispatch(setFeedbackCompany(company));
    }

    if (street !== undefined && street && street.trim().length > 0) {
      dispatch(setStreet(street));
    }

    if (
      houseNumber !== undefined &&
      houseNumber &&
      houseNumber.trim().length > 0
    ) {
      dispatch(setHouseNr(houseNumber));
    }

    if (
      postalCode !== undefined &&
      postalCode &&
      postalCode.trim().length > 0
    ) {
      dispatch(setZipCode(postalCode));
    }

    if (city !== undefined && city && city.trim().length > 0) {
      dispatch(setCity(city));
    }

    if (email !== undefined && email && email.trim().length > 0) {
      dispatch(setReportProcessorEmailAdress(email));
      dispatch(setFeedbackEmail(email));
    }
  }, [
    apiKey,
    language,
    theme,
    email,
    city,
    company,
    firstName,
    lastName,
    houseNumber,
    street,
    postalCode,
    dispatch,
  ]);
};
