import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LanguageLine } from "../../../Infrastructure/Internationalisation/Model/LanguageLine";
import { readLanguageFile } from "../../../Infrastructure/Internationalisation/LanguageFileReader";

export interface LanguageFileState {
  languageFileContent: LanguageLine[] | null;
  languageFileWasLoaded: boolean;
}

const initialState: LanguageFileState = {
  languageFileContent: null,
  languageFileWasLoaded: false,
};

export const loadCsv = createAsyncThunk("languageFile/loadcsv", async () => {
  const response = await readLanguageFile();
  return response;
});

export const languageFileSlice = createSlice({
  name: "languageFile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadCsv.fulfilled, (state, action) => {
      state.languageFileContent = [...action.payload];
      state.languageFileWasLoaded = true;
    });
  },
});

export default languageFileSlice.reducer;
