import React from "react";
import { Button } from "@mui/material";
import "./Style/LoadingIndicator.css";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";

const LoadingIndicator = () => {
  const abortControllerCreateReport = useAppSelector(
    (store) => store.createReport.abortController
  );

  const handleAbortClick = () => {
    abortControllerCreateReport?.abort();
  };

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <React.Fragment>
          {
            <>
              <div className="loading-container"></div>
              <div className="whiteBox">
                <img
                  alt="logo"
                  style={{ width: "300px", padding: "10px", marginTop: "50px" }}
                  src="JSON/Logo_Animation.gif"
                />

                <Button
                  onClick={handleAbortClick}
                  variant="text"
                  color="secondary"
                  sx={{ mx: 1, flexGrow: 1 }}
                >
                  {getTranslatedText("Cancel")}
                </Button>
                <div></div>
              </div>
            </>
          }
        </React.Fragment>
      )}
    </LanguageConsumer>
  );
};

export default LoadingIndicator;
