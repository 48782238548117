import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { sendFeedbackFormToBackend } from "../../../Features/Feedback/Redux/FeedbackSlice";
import { getTranslation } from "../../../Infrastructure/Internationalisation/TranslationService";
import { createReportFromBackend } from "../../../Infrastructure/CreateReport/Redux/CreateReportSlice";
import { toast } from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { store } from "../../../Infrastructure/Redux/store";
import { v4 as uuidv4 } from "uuid";

export enum NotificationType {
  error = "error",
  success = "success",
}

export interface ExtendedText {
  text: string;
  toastId: string;
}

export interface NotificationState {
  extendedText: ExtendedText[];
  isExtended: boolean;
  currentToastId: string;
}

const initialNotificationState: NotificationState = {
  extendedText: [],
  isExtended: false,
  currentToastId: "",
};

export const NotificationsSlice = createSlice({
  name: "notifications",
  initialState: initialNotificationState,
  reducers: {
    setNotification: (state, action) => {
      if (action.payload.extendedNotification) {
        var currentId = action.payload.toastId ?? uuidv4();
        state.extendedText.push({
          text: action.payload.extendedNotification.text
            ? action.payload.extendedNotification.text
            : getTranslation("NoErrorMessageDetected"),
          toastId: currentId,
        } as ExtendedText);
        toast.error(
          (t) => (
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Typography marginLeft={"10px"} marginRight={"10px"}>
                {getTranslation(
                  action.payload.text,
                  action.payload.extraTextProps
                )}
              </Typography>
              <Button
                color="success"
                variant="contained"
                title={getTranslation("ExtendNotification")}
                sx={{ color: "#fff" }}
                onClick={() => {
                  store.dispatch(setCurrentToastId(t.id));
                  store.dispatch(extendMessage());
                }}
              >
                {getTranslation("ExtendNotification")}
              </Button>
              <IconButton
                onClick={() => {
                  toast.dismiss(t.id);
                  store.dispatch(deleteExtendedMessage(t.id));
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ),
          {
            duration: Infinity,
            id: currentId,
          }
        );
        return;
      }
      if (action.payload.type === NotificationType.success) {
        toast.success(getTranslation(action.payload.text));
        return;
      }
      if (action.payload.type === NotificationType.error) {
        toast.error(getTranslation(action.payload.text));
      }
    },
    extendMessage: (state) => {
      state.isExtended = true;
    },

    hideNotification: (state) => {
      state.isExtended = false;
      state.currentToastId = "";
    },
    deleteExtendedMessage: (state, action) => {
      var newExtendeMessages = [...state.extendedText];
      newExtendeMessages.splice(
        newExtendeMessages.findIndex((x) => x.toastId === action.payload),
        1
      );
      state.extendedText = newExtendeMessages;
    },
    setCurrentToastId: (state, action) => {
      state.currentToastId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(sendFeedbackFormToBackend.fulfilled), (_, action) => {
        if (action.payload.success) {
          toast.success(getTranslation("SuccessfullySendFeedback"));
        }
      })
      .addMatcher(isAnyOf(createReportFromBackend.rejected), (_, action) => {
        var payload = action.payload as any;
        var text = payload.messageText
          ? getTranslation(payload.messageText)
          : payload.message
            ? payload.message
            : "ErrorWhileCreateReport";
        toast.error(text);
      });
  },
});

export const {
  setNotification,
  hideNotification,
  extendMessage,
  deleteExtendedMessage,
  setCurrentToastId,
} = NotificationsSlice.actions;

export default NotificationsSlice.reducer;
