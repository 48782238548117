export enum MainCategories {
  MqttError = "MqttError",
  Feedback = "Feedback_",
  Footer = "Footer_",
  Wizard = "Wizard_",
  DownloadClientConnector = "DownloadClientConnector",
  CopyClientIdAndConnect = "CopyClientIdAndTryToConnect",
  RequestDataFromAst = "RequestDataFromAst",
  ExecuteProcess = "ExecuteProcess_",
  GraphViewerAction = "GraphViewerAction_",
  Settings = "Settings_",
  License = "License_",
  OnlineHelp = "OnlineHelp_",
}
