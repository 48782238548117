import { createSlice } from "@reduxjs/toolkit";
import { DefaultStepList, IDefaultStep } from "../Model/DefaultStepList";

interface IStep {
  id: number;
  componentIdentifier: string;
  textIdentifierWizardStep: string;
}

export interface WizardState {
  currentStepId: number;
  stepList: IStep[];
}

export const initialStateWizard: WizardState = {
  currentStepId: 0,
  stepList: createStepList(DefaultStepList),
};

export const wizardSlice = createSlice({
  name: "wizard",
  initialState: initialStateWizard,
  reducers: {
    increaseCurrentStepId: (state) => {
      state.currentStepId = state.currentStepId + 1;
    },
    decreaseCurrentStepId: (state) => {
      state.currentStepId = state.currentStepId - 1;
    },
    setCurrentStepId: (state, action) => {
      state.currentStepId = action.payload;
    },
    resetWizard: (state) => {
      state.currentStepId = 0;
      state.stepList = createStepList(DefaultStepList);
    },
  },
});

export function createStepList(sourceStepList: IDefaultStep[]): IStep[] {
  var stepList: IStep[] = [];

  sourceStepList.forEach((element, index) => {
    stepList.push({
      id: index,
      componentIdentifier: element.componentIdentifier,
      textIdentifierWizardStep: element.textIdentifierInWizardStep,
    });
  });

  return stepList;
}

export const {
  increaseCurrentStepId,
  decreaseCurrentStepId,
  setCurrentStepId,
  resetWizard,
} = wizardSlice.actions;

export default wizardSlice.reducer;
