import CircularProgress from "@mui/material/CircularProgress";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Grow,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { WizardItems } from "../../../../../Infrastructure/Analytics/Model/Categories/WizardEnums";
import {
  analyse_downloadClientConnector,
  analyse_copyClientIdAndConnect,
  analyse_navigateWizard,
} from "../../../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import {
  LanguageConsumer,
  getTranslation,
} from "../../../../../Infrastructure/Internationalisation/TranslationService";
import {
  MqttConnectionState,
  setClientId,
} from "../../../../../Infrastructure/Mqtt/Redux/MqttStateSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Infrastructure/Redux/hooks";
import {
  decreaseCurrentStepId,
  increaseCurrentStepId,
  setCurrentStepId,
} from "../../Redux/WizardSlice";
import { SoftwareVersionOutdated } from "../../../../../Infrastructure/Mqtt/MqttService";
import { baseUrl } from "../../../../../Infrastructure/Api/BaseApi";
import { FiArrowLeft, FiArrowRight, FiCheck } from "react-icons/fi";
import { PiPlugsConnected, PiWindowsLogoFill } from "react-icons/pi";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

const DownloadAndStartClient = () => {
  const dispatch = useAppDispatch();
  const mqttConnectionState = useAppSelector(
    (store) => store.mqttState.connectionState
  );
  const accessToken = useAppSelector(
    (store) => store.licenceService.licenceAuthentication.token
  );

  const tighteningCount = useAppSelector(
    (store) => store.executeProcess.tighteningResults.length
  );
  const stepList = useAppSelector((store) => store.wizard.stepList);

  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    if (
      mqttConnectionState ===
      MqttConnectionState.ConnectionWithCloudConnectorActive
    ) {
      setBtnLoading(false);
    }
  }, [mqttConnectionState]);

  useEffect(() => {
    if (!btnLoading) return;
    let timer = setTimeout(() => {
      setBtnLoading(false);
      toast.error(getTranslation("CloudConnectorNotFound"));
    }, 10000);
    return () => clearTimeout(timer);
  }, [btnLoading]);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Grow in={true} appear={true}>
          <Card square={true} elevation={12} sx={{ height: "100%" }}>
            <CardContent
              sx={{
                height: "100%",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                {getTranslatedText("DownloadAndStartClientHeading")}
              </Typography>

              <Box sx={{ display: "flex", alignItems: "start", gap: 2 }}>
                <img
                  src="/Images/Icons/download.svg"
                  alt="connector"
                  height={36}
                  width={36}
                />
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography sx={{ fontWeight: 600 }}>
                    1. {getTranslatedText("DownloadCloudConnectorTitle")}
                  </Typography>
                  <Typography sx={{ ml: 2 }}>
                    {getTranslatedText("DownloadCloudConnectorDescription")}
                  </Typography>
                  <Button
                    sx={{ ml: 2, width: "fit-content" }}
                    variant="outlined"
                    color="success"
                    startIcon={<PiWindowsLogoFill />}
                    onClick={() => {
                      dispatch(analyse_downloadClientConnector());
                    }}
                    href={`${baseUrl}download?fileName=DEPRAG Cloud DSJL Connector.exe&access_token=${accessToken}`}
                    download="DEPRAG Cloud DSJL Connector.exe"
                  >
                    {getTranslatedText("DownloadApp")}
                  </Button>

                  <Typography
                    variant="subtitle2"
                    sx={{ opacity: 0.5, ml: 2, fontSize: 12 }}
                  >
                    <div
                      className="greenATag"
                      dangerouslySetInnerHTML={{
                        __html: getTranslatedText(
                          "DownloadCloudConnectorNote",
                          ["/service/contactdata"]
                        ),
                      }}
                    ></div>
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "start", gap: 2 }}>
                <img
                  src="/Images/Icons/connector.svg"
                  alt="connector"
                  height={36}
                  width={36}
                />
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography sx={{ fontWeight: 600 }}>
                    2. {getTranslatedText("StartCloudConnectorTitle")}
                  </Typography>
                  <Typography sx={{ ml: 2 }}>
                    {getTranslatedText("StartCloudConnectorDescription")}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "start", gap: 2 }}>
                <img
                  src="/Images/Icons/connect.svg"
                  alt="connector"
                  height={36}
                  width={36}
                />
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography sx={{ fontWeight: 600 }}>
                    3. {getTranslatedText("EstablishConnectionTitle")}
                  </Typography>
                  <Typography sx={{ ml: 2 }}>
                    {getTranslatedText("EstablishConnectionDescription")}
                  </Typography>
                  <Box>
                    <LoadingButton
                      loading={btnLoading}
                      startIcon={
                        btnLoading ? (
                          <CircularProgress size={24} />
                        ) : mqttConnectionState ===
                          MqttConnectionState.ConnectionWithCloudConnectorActive ? (
                          <FiCheck />
                        ) : (
                          <PiPlugsConnected />
                        )
                      }
                      loadingPosition="start"
                      variant={
                        mqttConnectionState ===
                        MqttConnectionState.ConnectionWithCloudConnectorActive
                          ? "outlined"
                          : "contained"
                      }
                      color="success"
                      disabled={
                        mqttConnectionState ===
                        MqttConnectionState.ConnectionWithCloudConnectorActive
                      }
                      sx={{
                        ml: 2,
                        color:
                          mqttConnectionState ===
                          MqttConnectionState.ConnectionWithCloudConnectorActive
                            ? "#32992C !important"
                            : "#fff",
                        border:
                          mqttConnectionState ===
                          MqttConnectionState.ConnectionWithCloudConnectorActive
                            ? "2px solid rgba(50, 153, 44, 0.5) !important"
                            : "unset",
                      }}
                      onClick={async () => {
                        setBtnLoading(true);
                        const newClientId = uuidv4();
                        dispatch(setClientId(newClientId));
                        dispatch(analyse_copyClientIdAndConnect());
                      }}
                    >
                      {getTranslatedText(
                        btnLoading
                          ? "EstablishingConnection"
                          : mqttConnectionState ===
                              MqttConnectionState.ConnectionWithCloudConnectorActive
                            ? "ConnectionActive"
                            : "CopyClientIdAndStartConnection"
                      )}
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>

              {SoftwareVersionOutdated && (
                <Typography color="red">
                  {getTranslatedText(
                    "DownloadAndStartFileSoftwareVersionOutdatedText"
                  )}
                </Typography>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="dark"
                  variant="contained"
                  sx={{ margin: 2 }}
                  onClick={() => dispatch(decreaseCurrentStepId())}
                  className="BackButtonWizardContent"
                  startIcon={<FiArrowLeft />}
                >
                  {getTranslatedText("PreviousStepButtonText")}
                </Button>

                <Button
                  color="success"
                  variant="contained"
                  sx={{ margin: 2, color: "#fff" }}
                  onClick={() => {
                    if (tighteningCount > 0) {
                      dispatch(
                        setCurrentStepId(
                          stepList.filter(
                            (x) => x.componentIdentifier === "ExecuteProcess"
                          )[0].id
                        )
                      );
                    } else {
                      dispatch(increaseCurrentStepId());
                    }
                    dispatch(
                      analyse_navigateWizard({
                        currentStep: WizardItems.DownloadAndStart,
                      })
                    );
                  }}
                  disabled={
                    mqttConnectionState !==
                      MqttConnectionState.ConnectionWithCloudConnectorActive ||
                    SoftwareVersionOutdated
                  }
                  className="NextButtonWizardContent"
                  endIcon={<FiArrowRight />}
                >
                  {getTranslatedText("NextStepButtonText")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grow>
      )}
    </LanguageConsumer>
  );
};

export default DownloadAndStartClient;
