import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Stack,
    Icon, Typography,
} from "@mui/material";
import { PiInfoFill } from "react-icons/pi";
import HtmlParser from "react-html-parser";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";


interface TechnicalPreviewDialogProps {
    open: boolean;
    headerTextKey?: string;
    descriptionTextKey?: string;
}

const UseOnlyInMyDepragDialog = (
    {
        open,
        headerTextKey = "UseOnlyInMyDeprag",
        descriptionTextKey = "UseOnlyInMyDepragDescription",
    }: TechnicalPreviewDialogProps) => {

    const isDarkTheme = useAppSelector(store => store.settings.appTheme === "dark");

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" color="warning.main">
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Icon color="warning">
                                <PiInfoFill />
                            </Icon>
                            <Typography variant="h6">
                                {getTranslatedText(headerTextKey)}
                            </Typography>
                        </Stack>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className={isDarkTheme ? "lightLink" : "darkLink"}>
                            {HtmlParser(getTranslatedText(descriptionTextKey))}
                        </DialogContentText>
                    </DialogContent>

                </Dialog>
            }
        </LanguageConsumer>
    );
};

export default UseOnlyInMyDepragDialog;
