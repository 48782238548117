import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { FooterEvents } from "../Model/Categories/FooterEnums";
import { reset_analyse_openFooter } from "../Redux/AnalyticsSlice";

export const usePublishFooterAnalytics = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const openFooterState = useAppSelector((store) => store.analytics.openFooter);

  const TrackEvent = (eventName: string, properties: any) => {
    return useTrackEvent(appInsights, eventName, properties, true);
  };

  const trackOpenFooterLink = TrackEvent(FooterEvents.Open.toString(), {});

  useEffect(() => {
    if (openFooterState.toggle) {
      try {
        trackOpenFooterLink({ Target: openFooterState.target.toString() });
      } catch {}
      dispatch(reset_analyse_openFooter());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFooterState.toggle, dispatch]);
};
