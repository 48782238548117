export interface GetScrewdrivingRelatedSettingsRequestPayloadDto {
  IpAddressOrDns: string;
  Username: string;
  Password: string;
  TimeOutInMilliseconds: number;
}

export const DefaultGetScrewdrivingRelatedSettingsRequestPayloadDto: GetScrewdrivingRelatedSettingsRequestPayloadDto =
  {
    IpAddressOrDns: "",
    Username: "",
    Password: "",
    TimeOutInMilliseconds: 30000,
  };
