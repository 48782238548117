import { createSlice } from "@reduxjs/toolkit";
import { HardwareType } from "../../Mqtt/Enums/HardwareType";
import {
  GraphViewerNavigateDirection,
  GraphViewerOpenTarget,
} from "../Model/Categories/GraphViewerActionEnums";
import { FooterItems } from "../Model/Categories/FooterEnums";
import {
  AnalyseMqttCommandItems,
  AnalyseMqttSourceItems,
} from "../Model/Categories/MqttEnums";
import {
  ButtonClickData,
  ChangeDefaultSettingsData,
  ChangeGraphViewerSettingsData,
  ExecuteProcessData,
  GraphViewerActionNavigate,
  GraphViewerActionOpen,
  LicenseActivationData,
  LicenseOfferRequestData,
  MqttErrorData,
  NavigateWizardData,
  OpenFooterData,
  SendFeedbackData,
  ShowOnlineHelpData,
} from "../Model/Datatypes";
import { WizardItems } from "../Model/Categories/WizardEnums";
import {
  DefaultSettingsItems,
  GraphViewerSettingsItems,
} from "../Model/Categories/SettingsEnums";
import { OnlineHelpItems } from "../Model/Categories/OnlineHelpEnums";

export interface AnalyticsState {
  mqttError: MqttErrorData;
  sendFeedback: SendFeedbackData;
  openFooter: OpenFooterData;
  navigateWizard: NavigateWizardData;
  downloadClientConnector: ButtonClickData;
  copyClientIdAndConnect: ButtonClickData;
  requestDataFromAst: ButtonClickData;
  startExecuteProcess: ExecuteProcessData;
  finishExecuteProcess: ExecuteProcessData;
  graphViewerActionOpen: GraphViewerActionOpen;
  graphViewerActionNavigate: GraphViewerActionNavigate;
  changeDefaultSettings: ChangeDefaultSettingsData;
  changeGraphViewerSettings: ChangeGraphViewerSettingsData;
  licenseActivation: LicenseActivationData;
  licenseOfferRequest: LicenseOfferRequestData;
  showOnlineHelp: ShowOnlineHelpData;
}

export const initialStateAnalytics: AnalyticsState = {
  mqttError: {
    toggle: false,
    source: AnalyseMqttSourceItems.Broker,
    errorMessage: "",
    command: AnalyseMqttCommandItems.DetectDeviceType,
  },
  sendFeedback: { toggle: false },
  openFooter: { toggle: false, target: FooterItems.DepragHomepage },
  navigateWizard: { toggle: false, currentStep: WizardItems.Start },
  downloadClientConnector: { toggle: false },
  copyClientIdAndConnect: { toggle: false },
  requestDataFromAst: { toggle: false },
  startExecuteProcess: {
    toggle: false,
    controllerType: HardwareType.Ast11,
    amountOfScrewdrivingPoints: 0,
  },
  finishExecuteProcess: {
    toggle: false,
    controllerType: HardwareType.Ast11,
    amountOfScrewdrivingPoints: 0,
  },
  graphViewerActionOpen: {
    toggle: false,
    target: GraphViewerOpenTarget.NewTab,
  },
  graphViewerActionNavigate: {
    toggle: false,
    direction: GraphViewerNavigateDirection.Back,
  },
  changeDefaultSettings: {
    toggle: false,
    setting: DefaultSettingsItems.Language,
    newValue: "",
  },
  changeGraphViewerSettings: {
    toggle: false,
    setting: GraphViewerSettingsItems.GraphViewerUrl,
    newValue: "",
  },
  licenseActivation: { toggle: false, isMaster: false },
  licenseOfferRequest: { toggle: false, amountOfLicenses: 0 },
  showOnlineHelp: {
    toggle: false,
    source: OnlineHelpItems.GeneralSettingsOnlineHelp,
  },
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState: initialStateAnalytics,
  reducers: {
    analyse_mqttError: (state, action) => {
      state.mqttError.toggle = true;
      state.mqttError.source = action.payload.source;
      state.mqttError.errorMessage = action.payload.errorMessage;
      state.mqttError.command = action.payload.command;
      state.mqttError.parameter = action.payload.parameter;
      state.mqttError.parameterName = action.payload.parameterName;
    },
    reset_analyse_mqttError: (state) => {
      state.mqttError = initialStateAnalytics.mqttError;
    },
    analyse_startExecuteProcess: (state, action) => {
      state.startExecuteProcess.toggle = true;
      state.startExecuteProcess.controllerType = action.payload.controllerType;
      state.startExecuteProcess.amountOfScrewdrivingPoints =
        action.payload.amountOfScrewdrivingPoints;
    },
    reset_analyse_startExecuteProcess: (state) => {
      state.startExecuteProcess = initialStateAnalytics.startExecuteProcess;
    },
    analyse_finishExecuteProcess: (state, action) => {
      state.finishExecuteProcess.toggle = true;
      state.finishExecuteProcess.controllerType = action.payload.controllerType;
      state.finishExecuteProcess.amountOfScrewdrivingPoints =
        action.payload.amountOfScrewdrivingPoints;
    },
    reset_analyse_finishExecuteProcess: (state) => {
      state.finishExecuteProcess = initialStateAnalytics.finishExecuteProcess;
    },
    analyse_graphViewerActionOpen: (state, action) => {
      state.graphViewerActionOpen.toggle = true;
      state.graphViewerActionOpen.target = action.payload.target;
    },
    reset_analyse_graphViewerActionOpen: (state) => {
      state.graphViewerActionOpen = initialStateAnalytics.graphViewerActionOpen;
    },
    analyse_graphViewerActionNavigate: (state, action) => {
      state.graphViewerActionNavigate.toggle = true;
      state.graphViewerActionNavigate.direction = action.payload.direction;
    },
    reset_analyse_graphViewerActionNavigate: (state) => {
      state.graphViewerActionNavigate =
        initialStateAnalytics.graphViewerActionNavigate;
    },
    analyse_sendFeedback: (state) => {
      state.sendFeedback.toggle = true;
    },
    reset_analyse_sendFeedback: (state) => {
      state.sendFeedback.toggle = false;
    },
    analyse_openFooter: (state, action) => {
      state.openFooter.toggle = true;
      state.openFooter.target = action.payload.target;
    },
    reset_analyse_openFooter: (state) => {
      state.openFooter.toggle = false;
    },
    analyse_navigateWizard: (state, action) => {
      state.navigateWizard.toggle = true;
      state.navigateWizard.currentStep = action.payload.currentStep;
    },
    reset_analyse_navigateWizard: (state) => {
      state.navigateWizard.toggle = false;
    },
    analyse_downloadClientConnector: (state) => {
      state.downloadClientConnector.toggle = true;
    },
    reset_analyse_downloadClientConnector: (state) => {
      state.downloadClientConnector.toggle = false;
    },
    analyse_copyClientIdAndConnect: (state) => {
      state.copyClientIdAndConnect.toggle = true;
    },
    reset_analyse_copyClientIdAndConnect: (state) => {
      state.copyClientIdAndConnect.toggle = false;
    },
    analyse_requestDataFromAst: (state) => {
      state.requestDataFromAst.toggle = true;
    },
    reset_analyse_requestDataFromAst: (state) => {
      state.requestDataFromAst.toggle = false;
    },
    analyse_changeDefaultSettings: (state, action) => {
      state.changeDefaultSettings.toggle = true;
      state.changeDefaultSettings.setting = action.payload.setting;
      state.changeDefaultSettings.newValue = action.payload.newValue;
    },
    reset_analyse_changeDefaultSettings: (state) => {
      state.changeDefaultSettings.toggle = false;
    },
    analyse_changeGraphViewerSettings: (state, action) => {
      state.changeGraphViewerSettings.toggle = true;
      state.changeGraphViewerSettings.setting = action.payload.setting;
      state.changeGraphViewerSettings.newValue = action.payload.newValue;
    },
    reset_analyse_changeGraphViewerSettings: (state) => {
      state.changeGraphViewerSettings.toggle = false;
    },
    reset_analyse_licenseActivation: (state) => {
      state.licenseActivation = initialStateAnalytics.licenseActivation;
    },
    analyse_licenseOfferRequest: (state, action) => {
      state.licenseOfferRequest.toggle = true;
      state.licenseOfferRequest.amountOfLicenses =
        action.payload.amountOfLicenses;
    },
    reset_analyse_licenseOfferRequest: (state) => {
      state.licenseOfferRequest = initialStateAnalytics.licenseOfferRequest;
    },
    analyse_showOnlineHelp: (state, action) => {
      state.showOnlineHelp.toggle = true;
      state.showOnlineHelp.source = action.payload.source;
    },
    reset_analyse_showOnlineHelp: (state) => {
      state.showOnlineHelp.toggle = false;
    },
  },
});

export const {
  analyse_mqttError,
  reset_analyse_mqttError,
  analyse_sendFeedback,
  reset_analyse_sendFeedback,
  analyse_openFooter,
  reset_analyse_openFooter,
  analyse_navigateWizard,
  reset_analyse_navigateWizard,
  analyse_downloadClientConnector,
  reset_analyse_downloadClientConnector,
  analyse_copyClientIdAndConnect,
  reset_analyse_copyClientIdAndConnect,
  analyse_requestDataFromAst,
  reset_analyse_requestDataFromAst,
  analyse_finishExecuteProcess,
  analyse_graphViewerActionNavigate,
  analyse_graphViewerActionOpen,
  analyse_startExecuteProcess,
  reset_analyse_finishExecuteProcess,
  reset_analyse_graphViewerActionNavigate,
  reset_analyse_graphViewerActionOpen,
  reset_analyse_startExecuteProcess,
  analyse_changeDefaultSettings,
  reset_analyse_changeDefaultSettings,
  analyse_changeGraphViewerSettings,
  reset_analyse_changeGraphViewerSettings,
  reset_analyse_licenseActivation,
  analyse_showOnlineHelp,
  reset_analyse_showOnlineHelp,
  analyse_licenseOfferRequest,
  reset_analyse_licenseOfferRequest,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
