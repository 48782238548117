import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { FeedbackEvents } from "../Model/Categories/FeedbackEnums";
import { reset_analyse_sendFeedback } from "../Redux/AnalyticsSlice";

export const usePublishFeedbackAnalytics = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const sendFeedbackState = useAppSelector(
    (store) => store.analytics.sendFeedback
  );

  const TrackEvent = (eventName: string, properties: any) => {
    return useTrackEvent(appInsights, eventName, properties, true);
  };

  const trackSendFeedback = TrackEvent(FeedbackEvents.Send.toString(), {});

  useEffect(() => {
    if (sendFeedbackState.toggle) {
      try {
        trackSendFeedback({});
      } catch {}
      dispatch(reset_analyse_sendFeedback());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendFeedbackState.toggle, dispatch]);
};
