import { createSlice } from "@reduxjs/toolkit";

export const DefaultProcessValues = {
  amountOfComponentsLowerLimit: 1,
  amountOfComponentsUpperLimit: 99,
  amountOfScrewdrivingPointsPerComponentLowerLimit: 1,
  amountOfScrewdrivingPointsPerComponentUpperLimit: 99,
  programNumberLowerLimit: 1,
  programNumberUpperLimit: 10,
  minimumAmountOfIterationsPerUniqueTightening: 5,
};

export enum ProcessingOrder {
  ComponentAfterComponent,
  ScrewdrivingPointAfterScrewdrivingPoint,
}

export interface DemandTorque {
  screwdrivingPointNr: number;
  torque: string;
  useDemandTorque: boolean;
  isValid: boolean;
}

export interface IProcessConfiguration {
  maxTorque: number;
  rotationalSpeed: number;
  amountOfComponents: number;
  amountOfScrewdrivingPoints: number;
  processingOrder: ProcessingOrder;
  groupByTighteningPosition: boolean;
  programNumber: number;
  withDemandTorques: boolean;
  demandTorques: DemandTorque[];
  demandTorquesDialogOpen: boolean;
  demandTorqueValidationToggle: boolean;
}

export const initialStateProcessConfiguration: IProcessConfiguration = {
  maxTorque: 0,
  rotationalSpeed: 200,
  amountOfComponents: 5,
  amountOfScrewdrivingPoints: 1,
  processingOrder: ProcessingOrder.ComponentAfterComponent,
  groupByTighteningPosition: true,
  programNumber: 1,
  withDemandTorques: false,
  demandTorques: [
    {
      screwdrivingPointNr: 1,
      torque: "",
      useDemandTorque: false,
      isValid: true,
    } as DemandTorque,
  ],
  demandTorquesDialogOpen: false,
  demandTorqueValidationToggle: false,
};

const handleUpdateDemandTorques = (state) => {
  if (state.groupByTighteningPosition === false) {
    state.demandTorques = state.demandTorques.slice(0, 1);
  }
  if (state.groupByTighteningPosition !== false) {
    if (state.amountOfScrewdrivingPoints > state.demandTorques.length) {
      var amountToAdd =
        state.amountOfScrewdrivingPoints - state.demandTorques.length;
      for (let newElement = 0; newElement < amountToAdd; newElement++) {
        state.demandTorques.push({
          screwdrivingPointNr: state.demandTorques.length + 1,
          torque: "",
          useDemandTorque: false,
          isValid: true,
        } as DemandTorque);
      }
    }
    if (state.amountOfScrewdrivingPoints < state.demandTorques.length) {
      var amountToDelete =
        state.demandTorques.length - state.amountOfScrewdrivingPoints;
      for (let newElement = 0; newElement < amountToDelete; newElement++) {
        state.demandTorques.pop();
      }
    }
  }
};

export const processConfigurationSlice = createSlice({
  name: "processConfiguration",
  initialState: initialStateProcessConfiguration,
  reducers: {
    setMaxTorque: (state, action) => {
      state.maxTorque = action.payload;
    },
    setRotationalSpeed: (state, action) => {
      state.rotationalSpeed = action.payload;
    },
    setAmountOfComponents: (state, action) => {
      state.amountOfComponents = action.payload;
    },
    setAmountOfScrewdrivingPoints: (state, action) => {
      state.amountOfScrewdrivingPoints = action.payload;
      handleUpdateDemandTorques(state);
    },
    setProcessingOrder: (state, action) => {
      state.processingOrder = action.payload;
    },
    setGroupByTighteningPosition: (state, action) => {
      state.groupByTighteningPosition = action.payload;
      handleUpdateDemandTorques(state);
    },
    setProgramNumber: (state, action) => {
      state.programNumber = action.payload;
    },
    setDemandTorque: (state, action) => {
      var demandTorqueIndexToEdit = state.demandTorques.findIndex(
        (x) => x.screwdrivingPointNr === action.payload.screwdrivingPointNr
      );
      state.demandTorques[demandTorqueIndexToEdit].torque =
        action.payload.torque;
    },
    setWithDemandTorques: (state, action) => {
      state.withDemandTorques = action.payload;
    },
    setUseDemandTorque: (state, action) => {
      var demandTorqueIndexToEdit = state.demandTorques.findIndex(
        (x) => x.screwdrivingPointNr === action.payload.screwdrivingPointNr
      );
      state.demandTorques[demandTorqueIndexToEdit].useDemandTorque =
        action.payload.useDemandTorque;
    },
    setDemandTorquesDialogOpen: (state, action) => {
      state.demandTorquesDialogOpen = action.payload;
    },
    setDemandTorqueIsValid: (state, action) => {
      var demandTorqueIndexToEdit = state.demandTorques.findIndex(
        (x) => x.screwdrivingPointNr === action.payload.screwdrivingPointNr
      );
      state.demandTorques[demandTorqueIndexToEdit].isValid =
        action.payload.isValid;
    },
    setDemandTorqueValidationToggle: (state) => {
      state.demandTorqueValidationToggle = !state.demandTorqueValidationToggle;
    },
    resetProcessConfiguration: (state) => {
      state.maxTorque = initialStateProcessConfiguration.maxTorque;
      state.rotationalSpeed = initialStateProcessConfiguration.rotationalSpeed;
      state.amountOfScrewdrivingPoints =
        initialStateProcessConfiguration.amountOfScrewdrivingPoints;
      state.programNumber = initialStateProcessConfiguration.programNumber;
    },
  },
});

export const {
  setAmountOfScrewdrivingPoints,
  setGroupByTighteningPosition,
  setMaxTorque,
  setProgramNumber,
  setRotationalSpeed,
  resetProcessConfiguration,
  setAmountOfComponents,
  setProcessingOrder,
  setWithDemandTorques,
  setDemandTorque,
  setDemandTorquesDialogOpen,
  setDemandTorqueIsValid,
  setUseDemandTorque,
  setDemandTorqueValidationToggle,
} = processConfigurationSlice.actions;

export default processConfigurationSlice.reducer;
