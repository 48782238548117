import {
  setNotification,
  NotificationType,
} from "../../../../Layout/Notifications/Redux/NotificationsSlice";
import {
  setIsConfigured,
  setLoading,
} from "../../../Configurations/AstConfiguration/Redux/AstConfigurationSlice";
import { useAppDispatch } from "../../../Redux/hooks";
import { ValidataAstSettingsResultPayloadDto } from "../../Models/Commands/ValidateAstSettings/ValidateAstSettingsResultPayloadDto";

export const useHandleValidateAstSettingsCommandResult = () => {
  const dispatch = useAppDispatch();

  const handleValidateAstSettingsCommandResultSuccess = (result: {}) => {
    const parsedResult = result as ValidataAstSettingsResultPayloadDto;

    if (parsedResult.InvalidForSfa) {
      dispatch(setIsConfigured(false));
      dispatch(setLoading(false));
      dispatch(
        setNotification({
          type: NotificationType.error,
          text: parsedResult.InvalidBecause,
        })
      );
      return;
    }

    dispatch(setIsConfigured(true));
    dispatch(setLoading(false));
  };

  const handleValidateAstSettingsCommandResultFailed = () => {
    dispatch(setLoading(false));
  };

  return {
    handleValidateAstSettingsCommandResultSuccess,
    handleValidateAstSettingsCommandResultFailed,
  };
};
