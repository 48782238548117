import {
  Box,
  Zoom,
  Fab,
  Drawer,
  Grid,
  IconButton,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Card,
  CardMedia,
  Button,
} from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import {
  LanguageConsumer,
  getTranslation,
} from "../../Infrastructure/Internationalisation/TranslationService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../Infrastructure/Redux/hooks";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "./Style/Feedback.css";
import {
  removeFeedbackAttachements,
  sendFeedbackFormToBackend,
  setFeedbackAttachements,
  setFeedbackCompany,
  setFeedbackEmail,
  setFeedbackLastName,
  setFeedbackMessage,
  setFeedbackMessageType,
  setFeedbackName,
  setShowErrors,
  toggleShowFeedback,
} from "./Redux/FeedbackSlice";
import { MessageType } from "./Models/MessageType";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { FeedbackPopover } from "../../Infrastructure/Popover/Feedback/FeedbackPopover";
import CloseIcon from "@mui/icons-material/Close";
import { analyse_sendFeedback } from "../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { toast } from "react-hot-toast";
import { UploadFile } from "@mui/icons-material";
import { useState } from "react";

const toBase64 = (file) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const Feedback = () => {
  const dispatch = useAppDispatch();
  const licenceNumber = "";
  const showFeedback = useAppSelector((store) => store.feedback.showFeedback);
  const isSending = useAppSelector(
    (store) => store.feedback.status === "loading"
  );
  const name = useAppSelector((store) => store.feedback.name);
  const lastName = useAppSelector((store) => store.feedback.lastName);
  const company = useAppSelector((store) => store.feedback.company);
  const message = useAppSelector((store) => store.feedback.message);
  const email = useAppSelector((store) => store.feedback.email);
  const messageType = useAppSelector((store) => store.feedback.messageType);
  const attachements = useAppSelector((store) => store.feedback.attachements);
  const showErrors = useAppSelector((store) => store.feedback.showErrors);
  const language = useAppSelector((store) => store.settings.language);

  const handleFiles = async (images: FileList) => {
    if ((attachements?.length || 0) + images.length > 3) {
      toast.error(getTranslation("MaxFileLimitReached"));
      return;
    }

    for (let index = 0; index < images.length; index++) {
      const element = images[index];
      var fileEnding = element.name.split(".").pop();
      if (
        fileEnding === "jpg" ||
        fileEnding === "png" ||
        fileEnding === "JPG" ||
        fileEnding === "PNG" ||
        fileEnding === "jpeg" ||
        fileEnding === "JPEG"
      ) {
        var imageBase64 = await toBase64(element);
        dispatch(setFeedbackAttachements(imageBase64));
      } else {
        toast.error(`.${fileEnding} ${getTranslation("IsNoValidFileFormat")}`);
      }
    }
  };

  const validateEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const enableShowErrors = () => {
    dispatch(setShowErrors());
  };

  const [dragOver, setDragOver] = useState(false);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <>
          <Drawer
            anchor={"right"}
            open={showFeedback}
            onClose={() => dispatch(toggleShowFeedback())}
          >
            <Grid container direction={"row"} spacing={2} sx={{ p: 4, pb: 0 }}>
              <Grid item xs={12}>
                <Box
                  component={"div"}
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: "300" }}
                  >
                    {getTranslatedText("FeedbackTitle")}
                  </Typography>
                  <IconButton
                    title={getTranslatedText("CloseFeedback")}
                    color="inherit"
                    onClick={() => dispatch(toggleShowFeedback())}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography component="div" sx={{ fontWeight: "300" }}>
                  {getTranslatedText("FeedbackCustomerText")}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction={"row"}
              spacing={1}
              sx={{ p: 4, pb: 0, pt: 5 }}
            >
              <Grid item xs={6}>
                <TextField
                  onFocus={enableShowErrors}
                  disabled={isSending}
                  error={name.trim().length === 0 && showErrors}
                  helperText={
                    name.trim().length === 0 && showErrors
                      ? getTranslatedText("FeedbackNameError")
                      : ""
                  }
                  fullWidth
                  color="success"
                  value={name}
                  onChange={(e) => dispatch(setFeedbackName(e.target.value))}
                  label={getTranslatedText("FeedbackName")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onFocus={enableShowErrors}
                  disabled={isSending}
                  error={lastName.trim().length === 0 && showErrors}
                  helperText={
                    lastName.trim().length === 0 && showErrors
                      ? getTranslatedText("FeedbackLastNameError")
                      : ""
                  }
                  fullWidth
                  color="success"
                  value={lastName}
                  onChange={(e) =>
                    dispatch(setFeedbackLastName(e.target.value))
                  }
                  label={getTranslatedText("FeedbackLastName")}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction={"row"}
              spacing={1}
              sx={{ p: 4, pb: 0, pt: 2 }}
            >
              <Grid item xs={6}>
                <TextField
                  onFocus={enableShowErrors}
                  disabled={isSending}
                  error={company.trim().length === 0 && showErrors}
                  helperText={
                    company.trim().length === 0 && showErrors
                      ? getTranslatedText("FeedbackCompanyError")
                      : ""
                  }
                  fullWidth
                  color="success"
                  value={company}
                  onChange={(e) => dispatch(setFeedbackCompany(e.target.value))}
                  label={getTranslatedText("FeedbackCompany")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onFocus={enableShowErrors}
                  disabled={isSending}
                  error={
                    (email.trim().length === 0 || !validateEmail(email)) &&
                    showErrors
                  }
                  helperText={
                    (email.trim().length === 0 || !validateEmail(email)) &&
                    showErrors
                      ? getTranslatedText("FeedbackEmailError")
                      : ""
                  }
                  fullWidth
                  color="success"
                  value={email}
                  onChange={(e) => dispatch(setFeedbackEmail(e.target.value))}
                  label={getTranslatedText("FeedbackEmail")}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction={"row"}
              spacing={1}
              sx={{ p: 4, pb: 0, pt: 2 }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    color="success"
                    id="demo-simple-select-helper-label"
                  >
                    {getTranslatedText("MessageType")}{" "}
                  </InputLabel>
                  <Select
                    disabled={isSending}
                    fullWidth
                    className="onlinehelp-settings-languageSelect"
                    color="success"
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={messageType}
                    label={getTranslatedText("MessageType")}
                    onChange={(e) => {
                      dispatch(
                        setFeedbackMessageType(e.target.value as MessageType)
                      );
                    }}
                    displayEmpty
                  >
                    <MenuItem value={MessageType.Suggestion}>
                      {getTranslatedText("MessageTypeSuggestion")}{" "}
                    </MenuItem>
                    <MenuItem value={MessageType.Question}>
                      {getTranslatedText("MessageTypeQuestion")}{" "}
                    </MenuItem>
                    <MenuItem value={MessageType.BugReport}>
                      {getTranslatedText("MessageTypeBugReport")}{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              direction={"row"}
              spacing={1}
              sx={{ p: 4, pb: 0, pt: 2 }}
            >
              <Grid item component={"div"} xs={12}>
                <TextField
                  onFocus={enableShowErrors}
                  disabled={isSending}
                  error={message.trim().length === 0 && showErrors}
                  helperText={
                    message.trim().length === 0 && showErrors
                      ? getTranslatedText("FeedbackMessageError")
                      : ""
                  }
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={8}
                  color="success"
                  value={message}
                  onChange={(e) => dispatch(setFeedbackMessage(e.target.value))}
                  label={getTranslatedText("FeedbackMessage")}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} px={4} pt={2}>
              <Typography>{getTranslatedText("AddAttachment")}</Typography>
            </Grid>

            <Grid
              container
              direction={"row"}
              spacing={1}
              sx={{ mt: 0, p: 4, pb: 0, pt: 1 }}
            >
              <Grid item component={"div"} xs={12}>
                <Button
                  disabled={isSending || attachements?.length === 3}
                  component="label"
                  color="success"
                  variant="outlined"
                  sx={{
                    ...(dragOver
                      ? {
                          borderStyle: "solid",
                          borderWidth: "2px",
                          backgroundColor: "rgba(50, 153, 44, 0.04)",
                        }
                      : { borderStyle: "dashed" }),
                    height: 120,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                  fullWidth
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnter={() => setDragOver(true)}
                  onDragLeave={() => setDragOver(false)}
                  onDrop={(e) => {
                    e.preventDefault();
                    setDragOver(false);
                    const droppedFiles = e.dataTransfer.files;
                    if (droppedFiles && droppedFiles.length > 0) {
                      handleFiles(droppedFiles);
                    }
                  }}
                >
                  <input
                    type="file"
                    hidden
                    accept={".png, .jpg, .jpeg"}
                    multiple
                    onChange={(e) => {
                      if (e.target.files) handleFiles(e.target.files);
                    }}
                  />

                  <UploadFile
                    sx={{ opacity: 0.8, pointerEvents: "none" }}
                    fontSize="large"
                  />
                  <Typography
                    fontSize={16}
                    sx={{ textTransform: "initial", pointerEvents: "none" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getTranslatedText("ChooseFileOrDragHere"),
                      }}
                    />
                  </Typography>
                </Button>
              </Grid>
              <Grid item style={{ paddingTop: 0 }}>
                <Typography variant="caption">
                  {getTranslatedText("FeedbackValidImageFormats")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction={"row"}
              spacing={1}
              sx={{
                mt: attachements && attachements.length > 0 ? 1 : 0,
                px: 4,
              }}
            >
              {attachements &&
                attachements.length > 0 &&
                attachements.map((att, attIdx) => {
                  return (
                    <Grid item xs={4} key={attIdx}>
                      <Card>
                        <div className="cardWrapper">
                          <CardMedia
                            alt="My cool img"
                            component="img"
                            sx={{ width: "100%", maxHeight: "100px" }}
                            image={att}
                          />
                          <div className="layer">
                            <IconButton
                              title="test"
                              color="primary"
                              onClick={() =>
                                dispatch(removeFeedbackAttachements(att))
                              }
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </div>
                        </div>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid
              container
              direction={"row"}
              spacing={1}
              sx={{ mt: 1, p: 4, pt: 0 }}
            >
              <Grid item xs={12} alignContent="center" textAlign={"center"}>
                <LoadingButton
                  loading={isSending}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  disabled={
                    isSending ||
                    name.trim().length === 0 ||
                    lastName.trim().length === 0 ||
                    email.trim().length === 0 ||
                    !validateEmail(email) ||
                    company.trim().length === 0 ||
                    message.trim().length === 0
                  }
                  color="success"
                  variant="contained"
                  sx={{ color: "#fff" }}
                  fullWidth
                  size="large"
                  onClick={() => {
                    dispatch(
                      sendFeedbackFormToBackend({
                        Name: name,
                        LastName: lastName,
                        Company: company,
                        Message: message,
                        Email: email,
                        LicenceNumber:
                          licenceNumber !== "" ? licenceNumber : "not-licenced",
                        Attachements: attachements ?? [],
                        MessageType: messageType,
                        Language: language,
                      })
                    );
                    dispatch(analyse_sendFeedback());
                  }}
                >
                  {getTranslatedText("SendFeedback")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Drawer>

          <FeedbackPopover />
          <Zoom in={true} unmountOnExit>
            <Fab
              className="popover-feedback"
              size="small"
              sx={{ position: "fixed", bottom: 20, right: 20 }}
              onClick={() => dispatch(toggleShowFeedback())}
              aria-label={"Feedback"}
              color={"default"}
            >
              <FeedbackIcon fontSize="small" />
            </Fab>
          </Zoom>
        </>
      )}
    </LanguageConsumer>
  );
};
