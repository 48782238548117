import { HardwareType } from "../../../Enums/HardwareType";

export interface LockDeviceRequestPayloadDto {
  IpAddressOrDns: string;
  Username: string;
  Password: string;
  AstType: HardwareType;
  TimeOutInMilliseconds: number;
}

export const DefaultLockDeviceRequestPayloadDto: LockDeviceRequestPayloadDto = {
  IpAddressOrDns: "",
  Username: "",
  Password: "",
  AstType: HardwareType.None,
  TimeOutInMilliseconds: 30000,
};
