import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  TextField,
  Grow,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { WizardItems } from "../../../../../Infrastructure/Analytics/Model/Categories/WizardEnums";
import { analyse_navigateWizard } from "../../../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import {
  setRotationalSpeed,
  DefaultProcessValues,
  setAmountOfScrewdrivingPoints,
  setProgramNumber,
  setProcessingOrder,
  ProcessingOrder,
  setGroupByTighteningPosition,
  setAmountOfComponents,
  setDemandTorquesDialogOpen,
  setDemandTorqueIsValid,
  setWithDemandTorques,
} from "../../../../../Infrastructure/Configurations/ProcessConfiguration/Redux/ProcessConfigurationSlice";
import {
  LanguageConsumer,
  getTranslation,
} from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { MqttConnectionState } from "../../../../../Infrastructure/Mqtt/Redux/MqttStateSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Infrastructure/Redux/hooks";
import {
  decreaseCurrentStepId,
  increaseCurrentStepId,
} from "../../Redux/WizardSlice";
import { YesNoDialog } from "../../../../../Infrastructure/YesNoDialog/YesNoDialog";
import { SetDemandTorquesDialog } from "./Components/SetDemandTorquesDialog";
import { useValidation } from "../../../../../Infrastructure/Hooks/useValidation";
import * as z from "zod";
import { FiAlertTriangle, FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { Information } from "../../../../../Layout/Information/Information";

interface ParamProcessModel {
  MaxTorque: number;
  RotationalSpeed: number;
  ProgramNr: number;
  AmountOfComponents: number;
  AmountOfScrewdrivingPoints: number;
}

const ParamProcess = () => {
  const dispatch = useAppDispatch();
  const processConfiguration = useAppSelector(
    (store) => store.processConfiguration
  );
  const astConfiguration = useAppSelector((store) => store.astConfiguration);
  const mqttConnectionState = useAppSelector(
    (store) => store.mqttState.connectionState
  );

  const [
    amountOfTotalScrewdrivingPointsValid,
    setAmountOfTotalScrewdrivingPointsValid,
  ] = useState<boolean>(true);
  const [
    amountOfTotalScrewdrivingPointsInValidText,
    setAmountOfTotalScrewdrivingPointsInValidText,
  ] = useState<string>("");

  const [getNextSecurityDialogOpen, setNextSecurityDialogOpen] =
    useState<boolean>(false);

  const rotationalSpeedErrorMessage = getTranslation(
    "ProcessRotationalSpeedInvalid",
    [
      astConfiguration.parameterInformation.speedLowerLimit.toString(),
      astConfiguration.parameterInformation.speedUpperLimit.toString(),
    ]
  );
  const programNrErrorMessage = getTranslation("ProcessProgramNumberInvalid", [
    DefaultProcessValues.programNumberLowerLimit.toString(),
    DefaultProcessValues.programNumberUpperLimit.toString(),
  ]);
  const amountOfComponentsErrorMessage = getTranslation(
    "ProcessAmountComponentsInvalid",
    [
      DefaultProcessValues.amountOfComponentsLowerLimit.toString(),
      DefaultProcessValues.amountOfComponentsUpperLimit.toString(),
    ]
  );
  const amountOfScrewdrivingPointsErrorMessage = getTranslation(
    "ProcessAmountOfScrewdrivingPointsInvalid",
    [
      DefaultProcessValues.amountOfScrewdrivingPointsPerComponentLowerLimit.toString(),
      DefaultProcessValues.amountOfScrewdrivingPointsPerComponentUpperLimit.toString(),
    ]
  );
  const paramProcessSchema = z.object({
    MaxTorque: z.number(),
    RotationalSpeed: z
      .number()
      .int(rotationalSpeedErrorMessage)
      .min(
        astConfiguration.parameterInformation.speedLowerLimit,
        rotationalSpeedErrorMessage
      )
      .max(
        astConfiguration.parameterInformation.speedUpperLimit,
        rotationalSpeedErrorMessage
      ),
    ProgramNr: z
      .number()
      .int(programNrErrorMessage)
      .min(DefaultProcessValues.programNumberLowerLimit, programNrErrorMessage)
      .max(DefaultProcessValues.programNumberUpperLimit, programNrErrorMessage),
    AmountOfComponents: z
      .number()
      .int(amountOfComponentsErrorMessage)
      .min(
        DefaultProcessValues.amountOfComponentsLowerLimit,
        amountOfComponentsErrorMessage
      )
      .max(
        DefaultProcessValues.amountOfComponentsUpperLimit,
        amountOfComponentsErrorMessage
      ),
    AmountOfScrewdrivingPoints: z
      .number()
      .int(amountOfScrewdrivingPointsErrorMessage)
      .min(
        DefaultProcessValues.amountOfScrewdrivingPointsPerComponentLowerLimit,
        amountOfScrewdrivingPointsErrorMessage
      )
      .max(
        DefaultProcessValues.amountOfScrewdrivingPointsPerComponentUpperLimit,
        amountOfScrewdrivingPointsErrorMessage
      ),
  });

  const { errors, canSubmit, checkSubmit } = useValidation(paramProcessSchema, {
    MaxTorque: processConfiguration.maxTorque,
    RotationalSpeed: processConfiguration.rotationalSpeed,
    ProgramNr: processConfiguration.programNumber,
    AmountOfComponents: processConfiguration.amountOfComponents,
    AmountOfScrewdrivingPoints: processConfiguration.amountOfScrewdrivingPoints,
  } as ParamProcessModel);

  useEffect(() => {
    checkSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (
      let elementIndex = 0;
      elementIndex < processConfiguration.demandTorques.length;
      elementIndex++
    ) {
      var element = processConfiguration.demandTorques[elementIndex];
      if (element.useDemandTorque === false) {
        dispatch(
          setDemandTorqueIsValid({
            screwdrivingPointNr: elementIndex + 1,
            isValid: true,
          })
        );
        continue;
      }

      var parsedInput = element.torque.replace(",", ".");

      if (Number.isNaN(Number(parsedInput))) {
        dispatch(
          setDemandTorqueIsValid({
            screwdrivingPointNr: elementIndex + 1,
            isValid: false,
          })
        );
        continue;
      }
      var inputNumber = Number(parsedInput);

      if (
        inputNumber > processConfiguration.maxTorque ||
        inputNumber < astConfiguration.parameterInformation.torqueLowerLimit
      ) {
        dispatch(
          setDemandTorqueIsValid({
            screwdrivingPointNr: elementIndex + 1,
            isValid: false,
          })
        );
        continue;
      }
      dispatch(
        setDemandTorqueIsValid({
          screwdrivingPointNr: elementIndex + 1,
          isValid: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processConfiguration.demandTorqueValidationToggle, dispatch]);

  useEffect(() => {
    if (processConfiguration.groupByTighteningPosition) {
      if (
        processConfiguration.amountOfComponents <
        DefaultProcessValues.minimumAmountOfIterationsPerUniqueTightening
      ) {
        setAmountOfTotalScrewdrivingPointsValid(false);
        if (processConfiguration.amountOfScrewdrivingPoints < 2) {
          setAmountOfTotalScrewdrivingPointsInValidText(
            getTranslation("ToLessAmountOfComponents")
          );
          return;
        }
        setAmountOfTotalScrewdrivingPointsInValidText(
          getTranslation(
            "ToLessAmountOfComponentsWhileGroupByTighteningPosIsActive",
            [
              DefaultProcessValues.minimumAmountOfIterationsPerUniqueTightening.toString(),
            ]
          )
        );
        return;
      }
    } else if (
      processConfiguration.amountOfComponents *
        processConfiguration.amountOfScrewdrivingPoints <
      DefaultProcessValues.minimumAmountOfIterationsPerUniqueTightening
    ) {
      setAmountOfTotalScrewdrivingPointsValid(false);
      if (processConfiguration.amountOfScrewdrivingPoints < 2) {
        setAmountOfTotalScrewdrivingPointsInValidText(
          getTranslation("ToLessAmountOfComponents")
        );
        return;
      }
      setAmountOfTotalScrewdrivingPointsInValidText(
        getTranslation(
          "ToLessTotalTighteningsWihileGroupByTighteningPosIsInactive",
          [
            DefaultProcessValues.minimumAmountOfIterationsPerUniqueTightening.toString(),
          ]
        )
      );
      return;
    }
    setAmountOfTotalScrewdrivingPointsValid(true);
    setAmountOfTotalScrewdrivingPointsInValidText("");
  }, [processConfiguration]);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Grow in={true} appear={true}>
          <Card square={true} elevation={12} sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%", position: "relative" }}>
              <Grid container direction={"row"}>
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    {getTranslatedText("ParamProcessHeading")}
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", alignItems: "start", gap: 1, my: 3 }}>
                <TextField
                  error={!!errors["RotationalSpeed"]}
                  helperText={errors["RotationalSpeed"]}
                  fullWidth
                  className="onlinehelp-paramProcess-rotationalSpeed"
                  defaultValue={processConfiguration.rotationalSpeed}
                  label={getTranslatedText("ProcessRotationalSpeed")}
                  variant="outlined"
                  color="success"
                  onChange={(e) => {
                    if (
                      Number.isNaN(Number(e.target.value)) ||
                      e.target.value.includes(".")
                    ) {
                      dispatch(setRotationalSpeed(0));
                      return;
                    }
                    dispatch(setRotationalSpeed(Number(e.target.value)));
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {getTranslatedText("Rpm")}
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ mt: 2 }}>
                  <Information
                    text={getTranslatedText(
                      "ParamProcessOnlineHelpRotationalSpeedMessage"
                    )}
                    size={24}
                    placement="right"
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "start", gap: 1, mb: 3 }}>
                <TextField
                  error={!!errors["ProgramNr"]}
                  helperText={errors["ProgramNr"]}
                  fullWidth
                  className="onlinehelp-paramProcess-programNumber"
                  defaultValue={processConfiguration.programNumber}
                  label={getTranslatedText("ProcessProgramNumber")}
                  variant="outlined"
                  color="success"
                  onChange={(e) => {
                    if (
                      Number.isNaN(Number(e.target.value)) ||
                      e.target.value.includes(".")
                    ) {
                      dispatch(setProgramNumber(0));
                      return;
                    }
                    dispatch(setProgramNumber(Number(e.target.value)));
                  }}
                />
                <Box sx={{ mt: 2 }}>
                  <Information
                    text={getTranslatedText(
                      "ParamProcessOnlineHelpProgramNrMessage"
                    )}
                    size={24}
                    placement="right"
                  />
                </Box>
              </Box>

              <Grid container columnSpacing={2} rowSpacing={1} marginBottom={4}>
                <Grid
                  item
                  xs={6}
                  mb={2}
                  sx={{ display: "flex", alignItems: "start", gap: 1 }}
                >
                  <TextField
                    error={!!errors["AmountOfComponents"]}
                    helperText={errors["AmountOfComponents"]}
                    fullWidth
                    className="onlinehelp-paramProcess-amountOfComponents"
                    defaultValue={processConfiguration.amountOfComponents}
                    label={getTranslatedText("ProcessAmountComponents")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      if (
                        Number.isNaN(Number(e.target.value)) ||
                        e.target.value.includes(".")
                      ) {
                        dispatch(setAmountOfComponents(0));
                        return;
                      }
                      dispatch(setAmountOfComponents(Number(e.target.value)));
                    }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <Information
                      text={getTranslatedText(
                        "ParamProcessOnlineHelpAmountOfComponentsMessage"
                      )}
                      size={24}
                      placement="right"
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={6}
                  mb={2}
                  sx={{ display: "flex", alignItems: "start", gap: 1 }}
                >
                  <TextField
                    error={!!errors["AmountOfScrewdrivingPoints"]}
                    helperText={errors["AmountOfScrewdrivingPoints"]}
                    fullWidth
                    className="onlinehelp-paramProcess-amountOfScrewdrivingPoints"
                    defaultValue={
                      processConfiguration.amountOfScrewdrivingPoints
                    }
                    label={getTranslatedText("ProcessAmountScrewdrivingPoints")}
                    variant="outlined"
                    color="success"
                    onChange={(e) => {
                      if (
                        Number.isNaN(Number(e.target.value)) ||
                        e.target.value.includes(".")
                      ) {
                        dispatch(setAmountOfScrewdrivingPoints(0));
                        return;
                      }
                      dispatch(
                        setAmountOfScrewdrivingPoints(Number(e.target.value))
                      );
                    }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <Information
                      text={getTranslatedText(
                        "ParamProcessOnlineHelpAmountOfScrewdrivingPointsMessage"
                      )}
                      size={24}
                      placement="right"
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "start", gap: 1 }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      color="success"
                      id="select-label-processingOrder"
                    >
                      {getTranslatedText("ProcessingOrder")}{" "}
                    </InputLabel>
                    <Select
                      color="success"
                      labelId="select-label-processingOrder"
                      id="select-processingOrder"
                      className="onlinehelp-paramProcess-processingOrder"
                      value={processConfiguration.processingOrder}
                      label={getTranslatedText("ProcessingOrder")}
                      fullWidth
                      onChange={(e) => {
                        dispatch(setProcessingOrder(e.target.value));
                      }}
                    >
                      <MenuItem value={ProcessingOrder.ComponentAfterComponent}>
                        {getTranslatedText("ComponentByComponent")}{" "}
                      </MenuItem>
                      <MenuItem
                        value={
                          ProcessingOrder.ScrewdrivingPointAfterScrewdrivingPoint
                        }
                      >
                        {getTranslatedText(
                          "ScrewdrivingPointByScrewdrivingPoint"
                        )}{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ mt: 2 }}>
                    <Information
                      text={getTranslatedText(
                        "ParamProcessOnlineHelpProcessingOrderMessage"
                      )}
                      size={24}
                      placement="right"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={
                    processConfiguration.amountOfScrewdrivingPoints <= 1
                      ? "none"
                      : "flex"
                  }
                  justifyContent={"center"}
                  alignItems={"start"}
                  gap={1}
                  mt={2}
                >
                  <FormControl fullWidth>
                    <InputLabel color="success">
                      {getTranslatedText("TorqueSuggestion")}{" "}
                    </InputLabel>
                    <Select
                      color="success"
                      labelId="select-label-groupByTighteningPosition"
                      id="select-groupByTighteningPosition"
                      className="onlinehelp-paramProcess-groupByTighteningPosition"
                      value={processConfiguration.groupByTighteningPosition}
                      label={getTranslatedText("TorqueSuggestion")}
                      fullWidth
                      onChange={(e) => {
                        dispatch(setGroupByTighteningPosition(e.target.value));
                      }}
                    >
                      <MenuItem value={true as any}>
                        {getTranslatedText("IndividualTorqueSuggestion")}{" "}
                      </MenuItem>
                      <MenuItem value={false as any}>
                        {getTranslatedText("CombinedTorqueSuggestion")}{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ mt: 2 }}>
                    <Information
                      text={getTranslatedText(
                        "ParamProcessOnlineHelpGroupByTighteningPositionMessage"
                      )}
                      size={24}
                      placement="right"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    className="onlinehelp-paramProcess-demandTorquesAvailable"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      ml: 0,
                      my: 1,
                    }}
                    labelPlacement="start"
                    control={
                      <Switch
                        color="success"
                        checked={processConfiguration.withDemandTorques}
                        onClick={() => {
                          dispatch(
                            setWithDemandTorques(
                              !processConfiguration.withDemandTorques
                            )
                          );
                        }}
                      />
                    }
                    label={getTranslatedText("DemandTorquesAvailable")}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display={
                    processConfiguration.withDemandTorques ? "flex" : "none"
                  }
                  sx={{ gap: 1, alignItems: "center" }}
                >
                  <Button
                    className="onlinehelp-paramProcess-demandTorque"
                    color="success"
                    variant="contained"
                    fullWidth
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      dispatch(setDemandTorquesDialogOpen(true));
                    }}
                  >
                    {getTranslatedText("ConfigureDemandTorques")}
                  </Button>
                  <Information
                    text={getTranslatedText(
                      "ParamProcessOnlineHelpDemandTorqueMessage"
                    )}
                    size={24}
                    placement="right"
                  />
                </Grid>
              </Grid>

              {!amountOfTotalScrewdrivingPointsValid && (
                <Typography color={"error"} sx={{ mt: 2, mb: 4 }}>
                  {amountOfTotalScrewdrivingPointsInValidText}
                </Typography>
              )}

              {processConfiguration.demandTorques.filter(
                (x) => x.isValid === false
              ).length !== 0 && (
                <Typography color={"error"} sx={{ mt: 2, mb: 4 }}>
                  {getTranslatedText("OneOrMoreDemandTorquesInvalid")}
                </Typography>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="dark"
                  variant="contained"
                  sx={{ margin: 2 }}
                  onClick={() => dispatch(decreaseCurrentStepId())}
                  className="BackButtonWizardContent"
                  startIcon={<FiArrowLeft />}
                >
                  {getTranslatedText("PreviousStepButtonText")}
                </Button>

                <Button
                  color="success"
                  variant="contained"
                  sx={{ margin: 2, color: "#fff" }}
                  onClick={() => {
                    if (!checkSubmit()) return;
                    setNextSecurityDialogOpen(true);
                  }}
                  disabled={
                    !canSubmit ||
                    !amountOfTotalScrewdrivingPointsValid ||
                    mqttConnectionState !==
                      MqttConnectionState.ConnectionWithCloudConnectorActive ||
                    processConfiguration.demandTorques.filter(
                      (x) => x.isValid === false
                    ).length !== 0
                  }
                  className="NextButtonWizardContent"
                  endIcon={<FiArrowRight />}
                >
                  {getTranslatedText("NextStepButtonText")}
                </Button>

                <YesNoDialog
                  title={getTranslatedText("AcceptSecurityHeader")}
                  titleIcon={<FiAlertTriangle color="#D32F2F" size={28} />}
                  content={getTranslatedText("SecurityText")}
                  titleYesButton={getTranslatedText("AcceptAndContinue")}
                  iconYesButton={<FiAlertTriangle color="#D32F2F" size={22} />}
                  titleNoButton={getTranslatedText("Cancel")}
                  open={getNextSecurityDialogOpen}
                  onNoClick={() => setNextSecurityDialogOpen(false)}
                  onYesClick={() => {
                    setNextSecurityDialogOpen(false);
                    dispatch(increaseCurrentStepId());
                    dispatch(
                      analyse_navigateWizard({
                        currentStep: WizardItems.ConfigureProcess,
                      })
                    );
                  }}
                  dangerRight={true}
                />
                <SetDemandTorquesDialog />
              </Box>
            </CardContent>
          </Card>
        </Grow>
      )}
    </LanguageConsumer>
  );
};

export default ParamProcess;
