import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  PreloadedState,
} from "@reduxjs/toolkit";
import notificationsReducer from "../../Layout/Notifications/Redux/NotificationsSlice";
import settingsReducer from "../../Features/Settings/Redux/SettingsSlice";
import storage from "redux-persist/lib/storage";
import loadingIndicatorReducer from "../../Layout/LoadingIndicator/Redux/LoadingIndicatorSlice";
import languageFileReducer from "../Internationalisation/Redux/LanguageFileSlice";
import { persistReducer, createMigrate } from "redux-persist";
import wizardReducer from "../../Features/Home/Wizard/Redux/WizardSlice";
import mqttReducer from "../Mqtt/Redux/MqttStateSlice";
import astConfigurationReducer from "../Configurations/AstConfiguration/Redux/AstConfigurationSlice";
import processConfigurationReducer from "../Configurations/ProcessConfiguration/Redux/ProcessConfigurationSlice";
import feedbackReducer from "../../Features/Feedback/Redux/FeedbackSlice";
import popoverReducer from "../../Infrastructure/Popover/Redux/PopoverSlice";
import executeProcessReducer from "../../Infrastructure/Configurations/ExecuteProcess/Redux/ExecuteProcessSlice";
import licenceServiceReducer from "../LicenceService/Redux/LicenceServiceSlice";
import analyticsReducer from "../Analytics/Redux/AnalyticsSlice";
import customerDetailsConfigurationReducer from "../Configurations/CustomerDetailsConfiguration/Redux/CustomerDetailsConfigurationSlice";
import elementDetailsReducer from "../Configurations/ElementDetails/Redux/ElementDetailsSlice";
import createReportReducer from "../CreateReport/Redux/CreateReportSlice";
import navigationReducer from "../../Layout/Navigation/Redux/NavigationSlice";
import startConfigurationReducer from "../../Infrastructure/Configurations/StartConfiguration/StartConfigurationSlice";

const reducers = combineReducers({
  settings: settingsReducer,
  notifications: notificationsReducer,
  languageFile: languageFileReducer,
  loadingIndicator: loadingIndicatorReducer,
  wizard: wizardReducer,
  mqttState: mqttReducer,
  astConfiguration: astConfigurationReducer,
  processConfiguration: processConfigurationReducer,
  feedback: feedbackReducer,
  popover: popoverReducer,
  executeProcess: executeProcessReducer,
  licenceService: licenceServiceReducer,
  analytics: analyticsReducer,
  customerDetailsConfiguration: customerDetailsConfigurationReducer,
  elementDetails: elementDetailsReducer,
  createReport: createReportReducer,
  navigation: navigationReducer,
  startConfiguration: startConfigurationReducer,
});

const migrations = {
  1: (state) => {
    return {
      ...state,
    };
  },
};

const persistConfig = {
  key: "root",
  version: 2,
  storage,
  whitelist: ["settings", "feedback", "popover", "licenceService"],
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //  serializableCheck: {
      //    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //  }
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: reducers,
    preloadedState,
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
