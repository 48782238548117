import Lottie, { Options } from "react-lottie";
import successIcon from "./JSON/Success.json";
import Zoom from "@mui/material/Zoom";

export const SuccessAnimation = ({ size, speed = 0.8, loop }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  } as Options;

  return (
    <div>
      <Zoom in={true} unmountOnExit>
        <span>
          <Lottie
            speed={speed}
            options={{ ...defaultOptions, loop: loop }}
            height={size}
            width={size}
          />
        </span>
      </Zoom>
    </div>
  );
};
