import { Grow, Grid, Card, CardContent, Container } from "@mui/material";
import React from "react";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { Helmet } from "react-helmet";
import { ImprintLanguage } from "./Languages/ImprintLanguage";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";

export const Imprint = () => {
  const language = useAppSelector((store) => store.settings.language);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
          <React.Fragment>
            <Helmet>
              <title>{getTranslatedText("ImprintSfaTitle")}</title>
            </Helmet>
            <Grow in={true} appear={true}>
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12} md={12}>
                  <Card elevation={12}>
                    <CardContent>
                      <ImprintLanguage lang={language} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grow>
          </React.Fragment>
        </Container>
      )}
    </LanguageConsumer>
  );
};
