import { useEffect } from "react";
import {
  setNotification,
  NotificationType,
} from "../../../Layout/Notifications/Redux/NotificationsSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { MqttResultDto } from "../Models/MqttResultDto";
import { analyse_mqttError } from "../../Analytics/Redux/AnalyticsSlice";
import {
  AnalyseMqttSourceItems,
  AnalyseMqttCommandItems,
} from "../../Analytics/Model/Categories/MqttEnums";
import { removeGuidToIgnore } from "../Redux/MqttStateSlice";
import { useHandleUploadProgramCommandResult } from "./Commands/useHandleUploadProgramCommandResult";
import { useHandleSetProgramCommandResult } from "./Commands/useHandleSetProgramCommandResult";
import { useHandleMonitorNextTighteningCommandResult } from "./Commands/useHandleMonitorNextTighteningCommandResult";
import { useHandleDetectDeviceTypeCommandResult } from "./Commands/useHandleDetectDeviceTypeCommandResult";
import { useHandleGetToolDataCommandResult } from "./Commands/useHandleGetToolDataCommandResult";
import { useHandleLockDeviceCommandResult } from "./Commands/useHandleLockDeviceCommandResult";
import { useHandleValidateAstSettingsCommandResult } from "./Commands/useHandleValidateAstSettingsCommandResult";
import { useHandleGetScrewdrivingRelatedSettingsCommandResult } from "./Commands/useHandleGetScrewdrivingRelatedSettingsCommandResult";

interface UseHandleMqttResultArgs {
  currentResult: MqttResultDto | undefined;
  mqttPublish: (payload: {}, topic?: string) => void;
}

export const useHandleMqttResult = ({
  currentResult,
  mqttPublish,
}: UseHandleMqttResultArgs) => {
  const dispatch = useAppDispatch();
  const { guidsToIgnore, currentTransactionGuid } = useAppSelector(
    (store) => store.mqttState
  );
  const {
    handleValidateAstSettingsCommandResultSuccess,
    handleValidateAstSettingsCommandResultFailed,
  } = useHandleValidateAstSettingsCommandResult();
  const {
    handleDetectDeviceTypeCommandResultSuccess,
    handleDetectDeviceTypeCommandResultFailed,
  } = useHandleDetectDeviceTypeCommandResult({ mqttPublish });
  const {
    handleGetToolDataCommandResultSuccess,
    handleGetToolDataCommandResultFailed,
  } = useHandleGetToolDataCommandResult({ mqttPublish });
  const { handleLockDeviceCommandResultSuccess } =
    useHandleLockDeviceCommandResult({ mqttPublish });
  const { handleUploadProgramCommandResultSuccess } =
    useHandleUploadProgramCommandResult({ mqttPublish });
  const { handleSetProgramCommandResultSuccess } =
    useHandleSetProgramCommandResult({ mqttPublish });
  const {
    handleMonitorNextTighteningCommandResultSuccess,
    handleMonitorNextTighteningCommandResultFailed,
  } = useHandleMonitorNextTighteningCommandResult({ mqttPublish });
  const { handleGetScrewdrivingRelatedSettingsCommandResultSuccess } =
    useHandleGetScrewdrivingRelatedSettingsCommandResult({ mqttPublish });

  useEffect(() => {
    if (currentResult === undefined) {
      return;
    }

    if (
      guidsToIgnore.filter((x) => x === currentResult.RespondToTransactionGuid)
        .length > 0
    ) {
      dispatch(removeGuidToIgnore(currentResult.RespondToTransactionGuid));
      return;
    }

    if (currentResult.RespondToTransactionGuid !== currentTransactionGuid) {
      console.log(`Current TransactionGuid: ${currentTransactionGuid}`);
      console.log("Received Result:");
      console.log(currentResult);
      dispatch(
        setNotification({
          type: NotificationType.error,
          text: "LastTransactionGuidUnequalResultTransactionGuid",
        })
      );
      return;
    }

    let decryptedPayload = { ...currentResult.Payload };

    switch (currentResult.Command) {
      case "ValidateAstSettings": {
        if (currentResult.WasSuccessful) {
          handleValidateAstSettingsCommandResultSuccess(decryptedPayload);
          return;
        }
        handleValidateAstSettingsCommandResultFailed();
        analyseAndDisplayError(currentResult);

        break;
      }
      case "DetectDeviceType": {
        if (currentResult.WasSuccessful) {
          handleDetectDeviceTypeCommandResultSuccess(decryptedPayload);
          return;
        }
        handleDetectDeviceTypeCommandResultFailed();
        analyseAndDisplayError(currentResult);
        break;
      }
      case "GetToolData": {
        if (currentResult.WasSuccessful) {
          handleGetToolDataCommandResultSuccess(decryptedPayload);
          return;
        }
        handleGetToolDataCommandResultFailed();
        analyseAndDisplayError(currentResult);

        break;
      }
      case "LockDevice": {
        if (currentResult.WasSuccessful) {
          handleLockDeviceCommandResultSuccess();
          return;
        }
        analyseAndDisplayError(currentResult);

        break;
      }
      case "UploadProgram": {
        if (currentResult.WasSuccessful) {
          handleUploadProgramCommandResultSuccess();
          return;
        }
        analyseAndDisplayError(currentResult);

        break;
      }
      case "SetProgram": {
        if (currentResult.WasSuccessful) {
          handleSetProgramCommandResultSuccess();
          return;
        }
        analyseAndDisplayError(currentResult);
        break;
      }
      case "MonitorNextTightening": {
        if (currentResult.WasSuccessful) {
          handleMonitorNextTighteningCommandResultSuccess(decryptedPayload);
          return;
        }
        handleMonitorNextTighteningCommandResultFailed();
        analyseAndDisplayError(currentResult);
        break;
      }
      case "GetScrewdrivingRelatedSettings": {
        if (currentResult.WasSuccessful) {
          handleGetScrewdrivingRelatedSettingsCommandResultSuccess(
            decryptedPayload
          );
          return;
        }
        analyseAndDisplayError(currentResult);
        break;
      }
      case "SetScrewdrivingRelatedSettings": {
        if (currentResult.WasSuccessful) {
          return;
        }
        analyseAndDisplayError(currentResult);
        break;
      }
      case "UnlockDevice": {
        if (currentResult.WasSuccessful) {
          return;
        }
        analyseAndDisplayError(currentResult);
        break;
      }
      default: {
        dispatch(
          setNotification({
            type: NotificationType.error,
            text: "ResultCommandNotFound",
          })
        );
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResult]);

  const analyseAndDisplayError = (errorResult) => {
    dispatch(
      analyse_mqttError({
        source: AnalyseMqttSourceItems.CloudConnector,
        errorMessage: "ErrorResponse",
        command: (AnalyseMqttCommandItems as any)[errorResult.Command],
      })
    );
    dispatch(
      setNotification({
        type: NotificationType.error,
        text: errorResult.ErrorDetails,
        extendedNotification: { text: errorResult.ErrorDetailsDeveloper },
      })
    );
  };
};
