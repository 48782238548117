import {
  Grid,
  Typography,
  Card,
  CardContent,
  Container,
  useMediaQuery,
} from "@mui/material";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";

export const ResponsiveDesign = ({ children }) => {
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const isRunningOnExtraSmallDevice = useMediaQuery("(max-width: 600px)");

  if (isRunningOnExtraSmallDevice) {
    return (
      <LanguageConsumer>
        {({ getTranslatedText }) => (
          <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
            <Card>
              <CardContent>
                <Grid container direction={"row"} spacing={2}>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <MobileScreenShareIcon
                      sx={{
                        color: isDarkMode ? "#fff" : "#999",
                        fontSize: "200px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Typography>
                      {getTranslatedText("RunningOnExtraSmallDeviceText")}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>
        )}
      </LanguageConsumer>
    );
  } else {
    return <>{children}</>;
  }
};
