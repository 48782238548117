import Lottie, { Options } from "react-lottie";
import animLight from "./JSON/DownloadCC_Light.json";
import animDark from "./JSON/DownloadCC_Dark.json";
import { SxProps, Box } from "@mui/material";
import { useAppSelector } from "../Redux/hooks";

interface DownloadCCAnimationProps {
  size: number;
  speed?: number;
  sx?: SxProps;
}

export const DownloadCCAnimation = ({
  size,
  speed = 1,
  sx,
}: DownloadCCAnimationProps) => {
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: isDarkMode ? animDark : animLight,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  } as Options;

  return (
    <Box sx={sx}>
      <span>
        <Lottie
          speed={speed}
          options={defaultOptions}
          height={size}
          width={size}
        />
      </span>
    </Box>
  );
};
