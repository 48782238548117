import {
  useAppDispatch,
  useAppSelector,
} from "../../../Infrastructure/Redux/hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  setIsLicensed,
  setRefreshToken,
  setToken,
} from "../../../Infrastructure/LicenceService/Redux/LicenceServiceSlice";
import { baseUrl } from "../../../Infrastructure/Api/BaseApi";
import { StringParam, useQueryParams } from "use-query-params";

interface CheckApiKeyHookResponse {
  isExternalUser: boolean;
}

interface FreeTrialResponseDto {
  token: string;
  refreshToken: string;
}

export const useCheckApiKey = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const apiKey = useAppSelector(
    (store) => store.licenceService.licenceAuthentication.myDepragApiKey
  );
  const auth = useAppSelector(
    (store) => store.licenceService.licenceAuthentication
  );
  const [isExternalUser, setIsExternalUser] = useState<boolean>(
    apiKey.trim().length > 0 && auth.isLicenced
  );

  const [apiKeyQuery] = useQueryParams({
    apiKey: StringParam,
  });

  useEffect(() => {
    const checkApiKey = async () => {
      const url = baseUrl + "freetrial/check";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          FreeTrialKey: apiKeyQuery.apiKey ? apiKeyQuery.apiKey : apiKey,
        }),
      });

      var jsonResponse = await response.json();

      var jsonResponsePayload = jsonResponse.payload as FreeTrialResponseDto;

      if (jsonResponse && jsonResponse.success) {
        setIsExternalUser(true);
        dispatch(setIsLicensed(true));
        dispatch(setToken(jsonResponsePayload.token));
        dispatch(setRefreshToken(jsonResponsePayload.refreshToken));
      } else {
        setIsExternalUser(false);
        dispatch(setIsLicensed(false));
      }
    };

    if (apiKey.trim().length > 0) {
      checkApiKey();
    }
  }, [location, apiKey, apiKeyQuery.apiKey, dispatch]);

  return {
    isExternalUser: isExternalUser,
  } as CheckApiKeyHookResponse;
};
