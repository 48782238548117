import { HardwareType } from "../../../Enums/HardwareType";

export interface GetToolDataRequestPayloadDto {
  IpAddressOrDns: string;
  Username: string;
  Password: string;
  TimeOutInMilliseconds: number;
  AstType: HardwareType;
}

export const DefaultGetToolDataRequestPayloadDto: GetToolDataRequestPayloadDto =
  {
    IpAddressOrDns: "",
    Username: "",
    Password: "",
    TimeOutInMilliseconds: 30000,
    AstType: HardwareType.None,
  };
