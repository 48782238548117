import "./Style/Navigation.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { NavLink } from "react-router-dom";
import PageBar from "./Views/Pages";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useState } from "react";
import { CouldNotConnectToCloudConnectorDialog } from "./Dialogs/CouldNotConnectToCloudConnectorDialog";

const Navigation = () => {
  const [
    couldNotConnectToCloudConnectorDialogOpen,
    setCouldNotConnectToCloudConnectorDialogOpen,
  ] = useState<boolean>(false);

  const handleCloseCouldNotConnectToCloudConnectorDialog = () => {
    setCouldNotConnectToCloudConnectorDialogOpen(false);
  };

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <>
          <CouldNotConnectToCloudConnectorDialog
            open={couldNotConnectToCloudConnectorDialogOpen}
            onClose={handleCloseCouldNotConnectToCloudConnectorDialog}
          />
          <AppBar position={"sticky"} enableColorOnDark={false} elevation={4}>
            <Container maxWidth="xl" sx={{ px: { xs: 0, md: 3 } }}>
              <Toolbar>
                <Box
                  component={"div"}
                  sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}
                >
                  <Typography
                    fontFamily={"Dancing Script"}
                    fontSize={30}
                    sx={{ position: "relative", ml: 0, mt: -2.2 }}
                  >
                    digital
                  </Typography>
                  <Typography
                    component={NavLink}
                    to={"/"}
                    variant="h4"
                    fontFamily={"Fira Sans"}
                    fontWeight={900}
                    sx={{ color: "#32992c", mt: 0, ml: -1 }}
                    color="success"
                  >
                    SCREW JOINT LABORATORY
                  </Typography>
                </Box>

                <Box
                  component={"div"}
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex", md: "none" },
                    alignSelf: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    fontFamily={"Dancing Script"}
                    fontSize={20}
                    sx={{ position: "relative", ml: 0, mt: -1.5 }}
                  >
                    digital
                  </Typography>
                  <Typography
                    component={NavLink}
                    to={"/"}
                    variant="h5"
                    fontFamily={"Fira Sans"}
                    fontWeight={900}
                    sx={{ color: "#32992c", mt: 0, ml: -1 }}
                    color="success"
                  >
                    SCREW JOINT LABORATORY
                  </Typography>
                </Box>
                <PageBar />
              </Toolbar>
            </Container>
          </AppBar>
        </>
      )}
    </LanguageConsumer>
  );
};

export default Navigation;
