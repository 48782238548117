import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { HardwareType } from "../../Mqtt/Enums/HardwareType";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { ExecuteProcessEvent } from "../Model/Categories/ExecuteProcessEnums";
import {
  reset_analyse_finishExecuteProcess,
  reset_analyse_startExecuteProcess,
} from "../Redux/AnalyticsSlice";

export const usePublishExecuteProcessAnalytics = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const startExecuteProcessState = useAppSelector(
    (store) => store.analytics.startExecuteProcess
  );
  const finishExecuteProcessState = useAppSelector(
    (store) => store.analytics.finishExecuteProcess
  );

  const TrackEvent = (eventName: string, properties: any) => {
    return useTrackEvent(appInsights, eventName, properties, true);
  };

  const trackStartExecuteProcess = TrackEvent(
    ExecuteProcessEvent.StartExecuteProcess.toString(),
    {}
  );
  const trackFinishExecuteProcess = TrackEvent(
    ExecuteProcessEvent.FinishExecuteProcess.toString(),
    {}
  );

  useEffect(() => {
    if (startExecuteProcessState.toggle) {
      try {
        trackStartExecuteProcess({
          ControllerType:
            HardwareType[startExecuteProcessState.controllerType].toString(),
          AmountOfScrewdrivingPoints:
            startExecuteProcessState.amountOfScrewdrivingPoints.toString(),
        });
      } catch {}
      dispatch(reset_analyse_startExecuteProcess());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startExecuteProcessState.toggle, dispatch]);

  useEffect(() => {
    if (finishExecuteProcessState.toggle) {
      try {
        trackFinishExecuteProcess({
          ControllerType:
            HardwareType[finishExecuteProcessState.controllerType].toString(),
          AmountOfScrewdrivingPoints:
            finishExecuteProcessState.amountOfScrewdrivingPoints.toString(),
        });
      } catch {}
      dispatch(reset_analyse_finishExecuteProcess());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishExecuteProcessState.toggle, dispatch]);
};
