import { Card, CardContent, Grow, Typography, Box } from "@mui/material";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";
import "./Styles/AnimationHandlerStyles.css";
import { HardwareType } from "../../../Infrastructure/Mqtt/Enums/HardwareType";
import { MqttConnectionState } from "../../../Infrastructure/Mqtt/Redux/MqttStateSlice";
import { ScrewerAnimation } from "../../../Infrastructure/Animations/ScrewerAnimation";
import { DownloadCCAnimation } from "../../../Infrastructure/Animations/DownloadCCAnimation";
import React from "react";

interface ImageProps {
  file: string;
  height?: number;
  width?: number | string;
  zIndex?: number;
  right?: number;
  left?: number;
  top?: number;
  bottom?: number;
  transition?: string;
  transform?: string;
  style?: React.CSSProperties;
}
const Image = ({
  file,
  height,
  width,
  zIndex,
  top,
  bottom,
  left,
  right,
  transition,
  transform,
  style,
}: ImageProps) => (
  <img
    src={`/Images/Animation/${file}`}
    alt="computer"
    height={height}
    width={width}
    style={{
      zIndex: zIndex,
      top: top,
      bottom: bottom,
      right: right || 0,
      left: left || 0,
      marginLeft: "auto",
      marginRight: "auto",
      position: "absolute",
      pointerEvents: "none",
      animation: "fadeIn .5s",
      transition: transition,
      transform: transform,
      ...style,
    }}
  />
);
interface AnimationTextProps {
  text: string;
  fontSize?: number;
  fontWeight?: number;
  zIndex?: number;
  right?: number;
  left?: number;
  top?: number;
  bottom?: number;
}

const AnimationText = ({
  text,
  fontSize,
  fontWeight,
  zIndex,
  right,
  left,
  top,
  bottom,
}: AnimationTextProps) => (
  <Typography
    sx={{
      zIndex: zIndex,
      top: top,
      bottom: bottom,
      right: right || 0,
      left: left || 0,
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      position: "absolute",
      pointerEvents: "none",
      animation: "fadeIn .5s",
      color: "#fff",
      fontSize: fontSize,
      fontWeight: fontWeight || 700,
    }}
  >
    {text}
  </Typography>
);
export const AnimationHandler = () => {
  const { checked } = useAppSelector((store) => store.startConfiguration);
  const { currentStep } = useAppSelector((store) => store.executeProcess);
  const {
    isConfigured,
    parameterInformation,
    loading: astConnectionLoading,
  } = useAppSelector((store) => store.astConfiguration);
  const { amountOfScrewdrivingPoints, amountOfComponents } = useAppSelector(
    (store) => store.processConfiguration
  );
  const { connectionState } = useAppSelector((store) => store.mqttState);
  const { currentStepId } = useAppSelector((store) => store.wizard);
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );

  return (
    <Grow in={true} appear={true}>
      <Card
        square={true}
        elevation={isDarkMode ? 0 : 12}
        sx={{
          height: "599px",
          minWidth: "312px",
          maxWidth: "312px",
          bgcolor: isDarkMode ? "#272727" : "#EFF1EF",
          padding: "16px",
          display: { xs: "none", lg: "block" },
        }}
      >
        <CardContent sx={{ height: "100%", position: "relative", padding: 0 }}>
          <Box
            sx={{
              backgroundColor: isDarkMode ? "#5c5c5c" : "#fff",
              borderRadius: 2,
              width: "214px",
              alignItems: "center",
              height:
                currentStepId === 1
                  ? 223.5
                  : currentStepId === 2
                    ? 262
                    : currentStepId === 3
                      ? 138
                      : 182,
              position: "absolute",
              left: 34,
              top:
                currentStepId === 1
                  ? -7
                  : currentStepId === 2
                    ? 100
                    : currentStepId === 3
                      ? 224
                      : 395,
              display:
                connectionState !==
                  MqttConnectionState.ConnectionWithCloudConnectorActive ||
                currentStepId === 0 ||
                currentStepId >= 5
                  ? "none"
                  : "flex",
              transition: "all .5s linear",
              transitionProperty: "top, height",
            }}
          >
            <Box
              sx={{
                height: 25,
                width: 25,
                ml: 2,
                backgroundColor: "#32922C",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ color: "#fff", fontSize: 14 }}>
                {currentStepId + 1}
              </Typography>
            </Box>
          </Box>
          <Image file="computer.svg" top={0} zIndex={3} />
          {checked.includes(1) && (
            <>
              <Image file="ast-raw.svg" top={210} zIndex={5} right={6} />
              <AnimationText
                text={
                  isConfigured
                    ? HardwareType[
                        parameterInformation.controllerType
                      ].toUpperCase()
                    : "AST"
                }
                top={259}
                zIndex={6}
              />
            </>
          )}
          {(currentStepId !== 0 ||
            checked.length === 5 ||
            connectionState ===
              MqttConnectionState.ConnectionWithCloudConnectorActive) && (
            <>
              {connectionState ===
              MqttConnectionState.ConnectionWithCloudConnectorActive ? (
                <Image
                  file="cloudconnector-connected.svg"
                  top={80}
                  right={11}
                />
              ) : currentStepId === 1 ? (
                <DownloadCCAnimation
                  size={600}
                  sx={{
                    position: "absolute",
                    left: -111,
                    marginLeft: "auto",
                    marginRight: "auto",
                    top: -78,
                    zIndex: 0,
                  }}
                />
              ) : (
                currentStepId !== 0 && (
                  <Image
                    file="cloudconnector-disconnected.svg"
                    top={80}
                    right={11}
                  />
                )
              )}
              {isConfigured ? (
                connectionState !==
                MqttConnectionState.ConnectionWithCloudConnectorActive ? (
                  currentStepId >= 2 && (
                    <Image
                      file="connectortoscrewer-disconnected.svg"
                      top={195}
                      left={117}
                    />
                  )
                ) : (
                  <Image
                    file="connectortoscrewer-connected.svg"
                    top={195}
                    left={118}
                  />
                )
              ) : (
                astConnectionLoading && (
                  <Image
                    file="connectortoscrewer-connecting.svg"
                    top={199}
                    left={98}
                  />
                )
              )}
            </>
          )}
          {currentStepId === 4 && (
            <>
              <AnimationText
                text={`${currentStep.ScrewdrivingPointNr}/${amountOfScrewdrivingPoints}`}
                top={425}
                zIndex={5}
                fontSize={14}
                fontWeight={500}
                right={1}
              />
              <AnimationText
                text={`${currentStep.ComponentNr}`}
                top={535}
                zIndex={6}
                fontSize={14}
                fontWeight={500}
              />
              <ScrewerAnimation
                size={500}
                sx={{
                  position: "absolute",
                  left: -111,
                  marginLeft: "auto",
                  marginRight: "auto",
                  top: 201,
                  zIndex: 4,
                }}
              />
            </>
          )}
          {checked.includes(2) && currentStepId !== 4 && (
            <Image file="screwer-default.svg" top={332} zIndex={1} left={4} />
          )}
          {checked.includes(3) && currentStepId !== 4 && (
            <Image file="holder.svg" top={405} zIndex={2} right={2} />
          )}
          {checked.includes(4) && (
            <Image
              file="products.svg"
              bottom={10}
              left={105}
              right={undefined}
              transform={`translateX(${(currentStep.ComponentNr - 1) * -63.37}px)`}
              zIndex={5}
              transition="transform .5s linear"
              height={23}
              width={
                currentStepId >= 4 ? `${amountOfComponents * 63.36}px` : 300
              }
              style={{
                objectFit: "none",
                objectPosition: "0",
                marginLeft: undefined,
                marginRight: undefined,
              }}
            />
          )}
          {checked.includes(5) && (
            <Image file="fasteningoption.svg" bottom={0} />
          )}
        </CardContent>
      </Card>
    </Grow>
  );
};
