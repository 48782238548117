import { createSlice } from "@reduxjs/toolkit";

export interface CustomerDetailsState {
  companyName: string;
  contactPersonSureName: string;
  contactPersonLastName: string;
  street: string;
  houseNr: string;
  city: string;
  zipCode: string;
}

export const initialState: CustomerDetailsState = {
  companyName: "",
  contactPersonSureName: "",
  contactPersonLastName: "",
  street: "",
  houseNr: "",
  city: "",
  zipCode: "",
};

export const customerDetailsConfigurationSlice = createSlice({
  name: "customerDetailsConfiguration",
  initialState: initialState,
  reducers: {
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setContactPersonSureName: (state, action) => {
      state.contactPersonSureName = action.payload;
    },
    setContactPersonLastName: (state, action) => {
      state.contactPersonLastName = action.payload;
    },
    setStreet: (state, action) => {
      state.street = action.payload;
    },
    setHouseNr: (state, action) => {
      state.houseNr = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload;
    },
    resetCustomerDetails: (state) => {
      state.companyName = initialState.companyName;
      state.contactPersonSureName = initialState.contactPersonSureName;
      state.contactPersonLastName = initialState.contactPersonLastName;
      state.street = initialState.street;
      state.houseNr = initialState.houseNr;
      state.city = initialState.city;
      state.zipCode = initialState.zipCode;
    },
  },
});

export const {
  setCompanyName,
  setContactPersonSureName,
  setContactPersonLastName,
  setStreet,
  setHouseNr,
  setCity,
  setZipCode,
  resetCustomerDetails,
} = customerDetailsConfigurationSlice.actions;

export default customerDetailsConfigurationSlice.reducer;
