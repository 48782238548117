import { useEffect, useState } from "react";
import { setCurrentStep } from "../Configurations/ExecuteProcess/Redux/ExecuteProcessSlice";
import { ProcessingOrder } from "../Configurations/ProcessConfiguration/Redux/ProcessConfigurationSlice";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";

export interface Step {
  ComponentNr: number;
  ScrewdrivingPointNr: number;
}

export const useAlgorithm = () => {
  const dispatch = useAppDispatch();

  const current = useAppSelector((store) => store.executeProcess.currentStep);
  const { amountOfComponents, amountOfScrewdrivingPoints, processingOrder } =
    useAppSelector((store) => store.processConfiguration);

  const [amount, setAmount] = useState<number>(
    amountOfComponents * amountOfScrewdrivingPoints
  );
  const [lastStepId, setLastStepId] = useState<number>();
  const count = useAppSelector(
    (store) => store.executeProcess.tighteningResults.length
  );

  const finished = count >= amount;

  const isComponentOrder = () => {
    return processingOrder === ProcessingOrder.ComponentAfterComponent;
  };

  useEffect(() => {
    const amount = amountOfComponents * amountOfScrewdrivingPoints;
    setAmount(amount);
  }, [amountOfComponents, amountOfScrewdrivingPoints]);

  useEffect(() => {
    setLastStepId(GetLastStepId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, finished]);

  function NextStep() {
    const nextStep = { ...current } as Step;

    if (finished) {
      return;
    }

    if (!isComponentOrder()) {
      if (nextStep.ComponentNr === amountOfComponents) {
        nextStep.ComponentNr = 1;
        nextStep.ScrewdrivingPointNr++;
      } else {
        nextStep.ComponentNr++;
      }
    } else {
      if (nextStep.ScrewdrivingPointNr === amountOfScrewdrivingPoints) {
        nextStep.ComponentNr++;
        nextStep.ScrewdrivingPointNr = 1;
      } else {
        nextStep.ScrewdrivingPointNr++;
      }
    }

    dispatch(setCurrentStep(nextStep));
  }

  function LastStep() {
    const lastStep = GetLastStep();
    if (lastStep) {
      dispatch(setCurrentStep(lastStep));
    }
  }

  function GetLastStepId() {
    const lastStep = GetLastStep();
    if (!lastStep) {
      return 0;
    } else {
      return Number(`${lastStep.ComponentNr}${lastStep.ScrewdrivingPointNr}`);
    }
  }

  function GetLastStep() {
    const lastStep = { ...current } as Step;

    if (lastStep.ComponentNr === 1 && lastStep.ScrewdrivingPointNr === 1)
      return;

    if (finished) {
      return lastStep;
    }

    if (!isComponentOrder()) {
      if (lastStep.ComponentNr === 1) {
        lastStep.ComponentNr = amountOfComponents;
        lastStep.ScrewdrivingPointNr--;
      } else {
        lastStep.ComponentNr--;
      }
    } else {
      if (lastStep.ScrewdrivingPointNr === 1) {
        lastStep.ComponentNr--;
        lastStep.ScrewdrivingPointNr = amountOfScrewdrivingPoints;
      } else {
        lastStep.ScrewdrivingPointNr--;
      }
    }
    return lastStep;
  }

  return { amount, count, finished, lastStepId, NextStep, LastStep };
};
