import { createSlice } from "@reduxjs/toolkit";

export interface StartConfigurationState {
  checked: number[];
}

export const initialState: StartConfigurationState = {
  checked: [],
};

export const startConfigurationSlice = createSlice({
  name: "startConfiguration",
  initialState: initialState,
  reducers: {
    toggleChecked: (state, action) => {
      if (state.checked.includes(action.payload)) {
        state.checked = state.checked.filter((x) => x !== action.payload);
      } else {
        state.checked = [...state.checked, action.payload];
      }
    },
    resetStartConfiguration: (state) => {
      state.checked = initialState.checked;
    },
  },
});

export const { toggleChecked, resetStartConfiguration } =
  startConfigurationSlice.actions;

export default startConfigurationSlice.reducer;
