export enum AnalyseMqttSourceItems {
  Broker = "Broker",
  CloudConnector = "CloudConnector",
}

export enum AnalyseMqttCommandItems {
  Disconnect = "Disconnect",
  Reconnect = "Reconnect",
  Subscribe = "Subscribe",
  Unsubscribe = "Unsubscribe",
  Publish = "Publish",
  DetectDeviceType = "DetectDeviceType",
  GetToolData = "GetToolData",
  LockDevice = "LockDevice",
  UploadProgram = "UploadProgram",
  SetProgram = "SetProgram",
  MonitorNextTightening = "MonitorNextTightening",
}
