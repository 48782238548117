import { Grid, IconButton, Tooltip } from "@mui/material";
import { Facebook, Instagram, LinkedIn, YouTube } from "@mui/icons-material";

export const SocialMediaLinks = () => {
  return (
    <Grid
      container
      direction={"row"}
      spacing={0}
      sx={{ display: { xs: "none", lg: "flex" } }}
    >
      <Grid
        item
        xs={12}
        sx={{
          textAlign: { md: "center", xs: "center", lg: "left", xl: "left" },
        }}
      >
        <Tooltip title="LinkedIn" sx={{ mr: 1 }}>
          <IconButton
            color={"default"}
            href="https://de.linkedin.com/company/deprag"
            target="_blank"
          >
            <LinkedIn />
          </IconButton>
        </Tooltip>

        <Tooltip title="Instagram" sx={{ mr: 1 }}>
          <IconButton
            color={"default"}
            href="https://www.instagram.com/deprag_amberg/"
            target="_blank"
          >
            <Instagram />
          </IconButton>
        </Tooltip>

        <Tooltip title="Facebook" sx={{ mr: 1 }}>
          <IconButton
            color={"default"}
            href="https://www.facebook.com/people/DEPRAG/100057522254996/"
            target="_blank"
          >
            <Facebook />
          </IconButton>
        </Tooltip>

        <Tooltip title="Youtube" sx={{ mr: 1 }}>
          <IconButton
            color={"default"}
            href="https://www.youtube.com/DEPRAGInternational"
            target="_blank"
          >
            <YouTube />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
