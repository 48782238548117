import { useState, useEffect } from "react";
import {
  getTranslation,
  LanguageConsumer,
} from "../../../../Infrastructure/Internationalisation/TranslationService";
import { MqttConnectionState } from "../../../../Infrastructure/Mqtt/Redux/MqttStateSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../Infrastructure/Redux/hooks";
import { setCurrentStepId } from "../Redux/WizardSlice";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

const HandleMqttConnectionLostDialog = () => {
  const dispatch = useAppDispatch();
  const activeStep = useAppSelector((store) => store.wizard.currentStepId);
  const stepList = useAppSelector((store) => store.wizard.stepList);

  const mqttConnectionState = useAppSelector(
    (store) => store.mqttState.connectionState
  );
  const [cancelProcessDialogOpen, setCancelProcessDialogOpen] =
    useState<boolean>(false);
  const [cancelProcessMessage, setCancelProcessMessage] = useState<string>("");

  const handleCloseCancelProcessDialog = async () => {
    await navigator.clipboard.writeText("");
    setCancelProcessDialogOpen(false);
    const stepNumberDownloadAndStartClientStep = stepList.filter(
      (x) => x.componentIdentifier === "DownloadAndStartClient"
    )[0].id;
    dispatch(setCurrentStepId(stepNumberDownloadAndStartClientStep));
  };

  useEffect(() => {
    if (
      stepList.filter((x) => x.id === activeStep)[0].componentIdentifier ===
        "Start" ||
      stepList.filter((x) => x.id === activeStep)[0].componentIdentifier ===
        "DownloadAndStartClient"
    ) {
      return;
    }

    if (mqttConnectionState === MqttConnectionState.ConnectedWithMqttBroker) {
      setCancelProcessMessage(
        getTranslation("CloudConnectorLostConnectionCancelProcessMessage")
      );
      setCancelProcessDialogOpen(true);
    } else if (
      mqttConnectionState === MqttConnectionState.Connecting ||
      mqttConnectionState === MqttConnectionState.Disconnected ||
      mqttConnectionState === MqttConnectionState.Reconnecting
    ) {
      setCancelProcessMessage(
        getTranslation("WebsiteLostConnectionCancelProcessMessage")
      );
      setCancelProcessDialogOpen(true);
    } else {
      setCancelProcessDialogOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqttConnectionState]);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Dialog
          open={cancelProcessDialogOpen}
          onClose={handleCloseCancelProcessDialog}
        >
          <DialogTitle>
            {getTranslatedText("CancelProcessDialogTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{cancelProcessMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="success"
              variant="contained"
              sx={{ color: "#fff" }}
              onClick={async () => {
                await handleCloseCancelProcessDialog();
              }}
            >
              {getTranslatedText("AgreeCancelProcessDialog")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </LanguageConsumer>
  );
};

export default HandleMqttConnectionLostDialog;
