import { Grid, Stack, Typography } from "@mui/material";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { NavLink } from "react-router-dom";

export const ImportantLinks = () => {
  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <>
          <Grid container direction={"row"}>
            <Grid item xs={12}>
              <Stack
                spacing={4}
                direction={{ xs: "column", sm: "row" }}
                sx={{ alignItems: "center" }}
              >
                <Typography
                  fontWeight={400}
                  component={NavLink}
                  to="/imprint"
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  {getTranslatedText("Imprint")}
                </Typography>
                <Typography
                  fontWeight={400}
                  component={NavLink}
                  to="/privacy"
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  {getTranslatedText("Privacy")}
                </Typography>
                <Typography
                  fontWeight={400}
                  component={NavLink}
                  to="/thirdpartylicensenotes"
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  {getTranslatedText("ThirdPartyLicenseNotesLinkText")}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </LanguageConsumer>
  );
};
