import {
  setDemandTorque,
  setDemandTorqueValidationToggle,
  setDemandTorquesDialogOpen,
  setUseDemandTorque,
} from "../../../../../../Infrastructure/Configurations/ProcessConfiguration/Redux/ProcessConfigurationSlice";
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Infrastructure/Redux/hooks";
import {
  Table,
  TableBody,
  Paper,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Box,
  Button,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TorqueUnit } from "../../../../../Settings/Enum/TorqueUnit";
import InputAdornment from "@mui/material/InputAdornment";

export const SetDemandTorquesDialog = () => {
  const dispatch = useAppDispatch();
  const { demandTorquesDialogOpen, demandTorques } = useAppSelector(
    (store) => store.processConfiguration
  );
  const processConfiguration = useAppSelector(
    (store) => store.processConfiguration
  );
  const astConfiguration = useAppSelector((store) => store.astConfiguration);
  const currentTorqueUnit = useAppSelector(
    (store) => store.settings.torqueUnit
  );

  const demandTorqueOnBlur = (value, elementIndex) => {
    dispatch(
      setDemandTorque({
        screwdrivingPointNr: elementIndex + 1,
        torque: value,
        useDemandTorque: true,
      })
    );
    dispatch(setDemandTorqueValidationToggle());
  };

  const handleOnChangeSelect = (shouldUseDemandTorque, elementIndex) => {
    if (shouldUseDemandTorque === false) {
      dispatch(
        setUseDemandTorque({
          screwdrivingPointNr: elementIndex + 1,
          useDemandTorque: false,
        })
      );
      dispatch(setDemandTorqueValidationToggle());

      return;
    }
    dispatch(
      setUseDemandTorque({
        screwdrivingPointNr: elementIndex + 1,
        useDemandTorque: true,
      })
    );
    dispatch(setDemandTorqueValidationToggle());
  };

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Dialog
          open={demandTorquesDialogOpen}
          onClose={() => dispatch(setDemandTorquesDialogOpen(false))}
          PaperProps={{ sx: { borderRadius: 2, maxWidth: "690px" } }}
        >
          <DialogTitle>
            {getTranslatedText("SetDemandTorquesDialogTitle")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              dispatch(setDemandTorquesDialogOpen(false));
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {getTranslatedText("WithDemandTorque")}
                    </TableCell>
                    <TableCell>
                      {getTranslatedText("TighteningPosition")}
                    </TableCell>
                    <TableCell>
                      {getTranslatedText("DemandTorque")}[
                      {getTranslatedText(
                        TorqueUnit[currentTorqueUnit].toString()
                      )}
                      ]
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {demandTorques.map((element, elementIndex) => {
                    return (
                      <TableRow
                        key={element.screwdrivingPointNr}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Switch
                            checked={element.useDemandTorque}
                            color="success"
                            onChange={(e) => {
                              handleOnChangeSelect(
                                e.target.checked,
                                elementIndex
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {element.screwdrivingPointNr}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            error={!demandTorques[elementIndex].isValid}
                            helperText={
                              demandTorques[elementIndex].isValid
                                ? ""
                                : getTranslatedText(
                                    "ProcessDemandTorqueInvalid",
                                    [
                                      astConfiguration.parameterInformation.torqueLowerLimit.toString(),
                                      processConfiguration.maxTorque.toString(),
                                      getTranslatedText(
                                        TorqueUnit[currentTorqueUnit].toString()
                                      ),
                                    ]
                                  )
                            }
                            autoFocus
                            sx={{ width: "200px" }}
                            className="onlinehelp-paramProcess-demandTorque"
                            defaultValue={demandTorques[elementIndex].torque}
                            variant="outlined"
                            color="success"
                            disabled={
                              demandTorques[elementIndex].useDemandTorque ===
                              false
                            }
                            onChange={(e) => {
                              demandTorqueOnBlur(e.target.value, elementIndex);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {getTranslatedText(
                                    TorqueUnit[currentTorqueUnit].toString()
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button
                color="success"
                variant="contained"
                sx={{ color: "#fff" }}
                onClick={() => {
                  dispatch(setDemandTorquesDialogOpen(false));
                }}
              >
                {getTranslatedText("Apply")}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </LanguageConsumer>
  );
};
