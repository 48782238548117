import { MainCategories } from "../MainCategories";

export enum SettingsCategories {
  Default = MainCategories.Settings + "Default",
  GraphViewer = MainCategories.Settings + "GraphViewer",
}

export enum DefaultSettingsItems {
  Language = "Language",
  TorqueUnit = "TorqueUnit",
  AppTheme = "AppTheme",
}

export enum GraphViewerSettingsItems {
  GraphViewerUrl = "GraphViewerUrl",
  ShowInQuickView = "ShowInQuickView",
}
