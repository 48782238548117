import { MainCategories } from "../MainCategories";

export enum OnlineHelpEvents {
  Show = MainCategories.OnlineHelp + "Show",
}

export enum OnlineHelpItems {
  GeneralSettingsOnlineHelp = "GeneralSettingsOnlineHelp",
  GraphViewerSettingsOnlineHelp = "GraphViewerSettingsOnlineHelp",
  LicenseSettingsOnlineHelp = "LicenseSettingsOnlineHelp",
  ReportValuesSettingsOnlineHelp = "ReportValuesSettingsOnlineHelp",
}
