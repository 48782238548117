import { createSlice } from "@reduxjs/toolkit";

export interface ElementAttachment {
  imageAsBase64: string;
  imagedescription: string | null;
  id: string;
}

export interface ElementDetailsState {
  elementDescription: string;
  elementAttachments: ElementAttachment[];
  enterImageWithDescriptionDialogOpen: boolean;
  currentImage: string | null;
}

export const initialStateElementDetails: ElementDetailsState = {
  elementDescription: "",
  elementAttachments: [],
  enterImageWithDescriptionDialogOpen: false,
  currentImage: null,
};

export const elementDetailsSlice = createSlice({
  name: "elementDetailsConfiguration",
  initialState: initialStateElementDetails,
  reducers: {
    setElementDescription: (state, action) => {
      state.elementDescription = action.payload;
    },
    setElementAttachments: (state, action) => {
      state.elementAttachments =
        state.elementAttachments && state.elementAttachments.length > 0
          ? [...state.elementAttachments, action.payload]
          : [action.payload];
    },
    removeElementAttachments: (state, action) => {
      state.elementAttachments =
        state.elementAttachments && state.elementAttachments.length > 0
          ? [
            ...state.elementAttachments.filter(
              (x) => x.id !== action.payload
            ),
          ]
          : [];
    },
    setEnterImageWithDescriptionDialogOpen: (state, action) => {
      state.enterImageWithDescriptionDialogOpen = action.payload;
    },
    setCurrentImage: (state, action) => {
      state.currentImage = action.payload;
    },
  },
});

export const {
  setElementDescription,
  setElementAttachments,
  removeElementAttachments,
  setEnterImageWithDescriptionDialogOpen,
  setCurrentImage,
} = elementDetailsSlice.actions;

export default elementDetailsSlice.reducer;
