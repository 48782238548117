import { createSlice } from "@reduxjs/toolkit";
import { TorqueUnit } from "../../../../Features/Settings/Enum/TorqueUnit";
import { DetectedType } from "../../../Mqtt/Enums/DetectedType";
import { HardwareType } from "../../../Mqtt/Enums/HardwareType";

export interface IAstParameterInformation {
  controllerType: HardwareType;
  controllerSerialNumber: string;
  deviceType: DetectedType;
  screwDriverType: string;
  screwDriverSerialNumber: string;
  speedLowerLimit: number;
  speedUpperLimit: number;
  torqueLowerLimit: number;
  torqueUpperLimit: number;
  torqueUnit: TorqueUnit;
}

export interface IAstConfiguration {
  ipAddressOrDns: string;
  isConfigured: boolean;
  username: string;
  password: string;
  loading: boolean;
  initialProgramNumber: number;
  initialProgramChoice: string;
  initialStartEnable: string;
  inititalStartEnableMode: string;
  parameterInformation: IAstParameterInformation;
}

export const initialStateAstConfiguration: IAstConfiguration = {
  ipAddressOrDns: "",
  isConfigured: false,
  username: "admin",
  password: "0000",
  loading: false,
  initialProgramNumber: -1,
  initialProgramChoice: "",
  initialStartEnable: "",
  inititalStartEnableMode: "",
  parameterInformation: {
    controllerType: HardwareType.None,
    controllerSerialNumber: "",
    deviceType: DetectedType.Unknown,
    screwDriverType: "",
    screwDriverSerialNumber: "",
    speedLowerLimit: 0,
    speedUpperLimit: 1000,
    torqueLowerLimit: 0,
    torqueUpperLimit: 1000,
    torqueUnit: TorqueUnit.Nm,
  },
};

export const astConfigurationSlice = createSlice({
  name: "astConfiguration",
  initialState: initialStateAstConfiguration,
  reducers: {
    setIpAddress: (state, action) => {
      state.ipAddressOrDns = action.payload;
      state.isConfigured = false;
    },
    setIsConfigured: (state, action) => {
      state.isConfigured = action.payload;
    },
    setParameterInformation: (state, action) => {
      state.parameterInformation = action.payload as IAstParameterInformation;
    },
    clearParameterInformation: (state) => {
      state.parameterInformation = {
        ...initialStateAstConfiguration.parameterInformation,
      };
    },
    setControllerType: (state, action) => {
      state.parameterInformation.controllerType = action.payload;
    },
    setControllerSerialNumber: (state, action) => {
      state.parameterInformation.controllerSerialNumber = action.payload;
    },
    setDeviceType: (state, action) => {
      state.parameterInformation.deviceType = action.payload;
    },
    setscrewDriverType: (state, action) => {
      state.parameterInformation.screwDriverType = action.payload;
    },
    setscrewDriverSerialNumber: (state, action) => {
      state.parameterInformation.screwDriverSerialNumber = action.payload;
    },
    setspeedLowerLimit: (state, action) => {
      state.parameterInformation.speedLowerLimit = action.payload;
    },
    setspeedUpperLimit: (state, action) => {
      state.parameterInformation.speedUpperLimit = action.payload;
    },
    settorqueLowerLimit: (state, action) => {
      state.parameterInformation.torqueLowerLimit = action.payload;
    },
    settorqueUpperLimit: (state, action) => {
      state.parameterInformation.torqueUpperLimit = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTorqueUnit: (state, action) => {
      state.parameterInformation.torqueUnit = action.payload;
    },
    setInitialProgramNumber: (state, action) => {
      state.initialProgramNumber = action.payload;
    },
    setInitialProgramChoice: (state, action) => {
      state.initialProgramChoice = action.payload;
    },
    setInititalStartEnable: (state, action) => {
      state.initialStartEnable = action.payload;
    },
    setInitialStartEnableMode: (state, action) => {
      state.inititalStartEnableMode = action.payload;
    },
    resetAstConfiguration: (state) => {
      state.isConfigured = initialStateAstConfiguration.isConfigured;
      state.loading = initialStateAstConfiguration.loading;
      state.initialProgramNumber =
        initialStateAstConfiguration.initialProgramNumber;
      state.initialProgramChoice =
        initialStateAstConfiguration.initialProgramChoice;
      state.initialStartEnable =
        initialStateAstConfiguration.initialStartEnable;
      state.inititalStartEnableMode =
        initialStateAstConfiguration.inititalStartEnableMode;
      state.parameterInformation = {
        ...initialStateAstConfiguration.parameterInformation,
      };
    },
  },
});

export const {
  setIpAddress,
  setIsConfigured,
  setParameterInformation,
  clearParameterInformation,
  setControllerType,
  setControllerSerialNumber,
  setDeviceType,
  setUsername,
  setPassword,
  setscrewDriverSerialNumber,
  setscrewDriverType,
  setspeedLowerLimit,
  setspeedUpperLimit,
  settorqueLowerLimit,
  settorqueUpperLimit,
  setLoading,
  setTorqueUnit,
  resetAstConfiguration,
  setInitialProgramNumber,
  setInitialProgramChoice,
  setInititalStartEnable,
  setInitialStartEnableMode,
} = astConfigurationSlice.actions;

export default astConfigurationSlice.reducer;
