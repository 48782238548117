import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { MqttConnectionState } from "../../../Infrastructure/Mqtt/Redux/MqttStateSlice";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";

export interface CouldNotConnectToCloudConnectorProps {
  open: boolean;
  onClose: () => void;
}

export const CouldNotConnectToCloudConnectorDialog = ({
  open,
  onClose,
}: CouldNotConnectToCloudConnectorProps) => {
  const mqttConnectionState = useAppSelector(
    (store) => store.mqttState.connectionState
  );

  useEffect(() => {
    if (mqttConnectionState !== MqttConnectionState.ConnectedWithMqttBroker) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqttConnectionState]);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Dialog
          open={open}
          keepMounted
          onClose={onClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            {getTranslatedText("CouldNotConnectToCloudConnectorDialogTitle")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {
            <DialogContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: getTranslatedText(
                    "CouldNotConnectToCloudConnectorDialogContent"
                  ),
                }}
              ></div>
            </DialogContent>
          }
        </Dialog>
      )}
    </LanguageConsumer>
  );
};
