import {
  IconButton,
  CardMedia,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Grow,
} from "@mui/material";
import { WizardItems } from "../../../../../Infrastructure/Analytics/Model/Categories/WizardEnums";
import { analyse_navigateWizard } from "../../../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Infrastructure/Redux/hooks";
import {
  decreaseCurrentStepId,
  increaseCurrentStepId,
} from "../../Redux/WizardSlice";
import {
  removeElementAttachments,
  setElementDescription,
  setEnterImageWithDescriptionDialogOpen,
  setCurrentImage,
} from "../../../../../Infrastructure/Configurations/ElementDetails/Redux/ElementDetailsSlice";
import { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { fileDialog } from "file-select-dialog";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { EnterImageWithDescriptionDialog } from "./Components/EnterImageWithDescriptionDialog";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

const toBase64 = (file) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const EnterElementDetails = () => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const [currentElementDescription, setCurrentElementDescription] = useState(
    useAppSelector((store) => store.elementDetails.elementDescription)
  );
  const { elementAttachments } = useAppSelector(
    (store) => store.elementDetails
  );

  const selectFiles = async () => {
    const image = await fileDialog({
      multiple: false,
      accept: [".jpg", ".jpeg", ".png"],
      strict: true,
    });
    var fileEnding = image.name.split(".").pop();
    if (
      fileEnding === "jpg" ||
      fileEnding === "png" ||
      fileEnding === "JPG" ||
      fileEnding === "PNG" ||
      fileEnding === "jpeg" ||
      fileEnding === "JPEG"
    ) {
      var imageBase64 = await toBase64(image);
      dispatch(setCurrentImage(imageBase64));
      dispatch(setEnterImageWithDescriptionDialogOpen(true));
    }
  };

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <>
          <EnterImageWithDescriptionDialog />
          <Grow in={true} appear={true}>
            <Card square={true} elevation={12} sx={{ height: "100%" }}>
              <CardContent sx={{ height: "100%", position: "relative" }}>
                <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
                  {getTranslatedText("EnterElementDetailsHeading")}
                </Typography>
                <div
                  style={{ color: isDarkMode ? "#fff" : "#444" }}
                  dangerouslySetInnerHTML={{
                    __html: getTranslatedText("EnterElementDetailsText"),
                  }}
                ></div>

                <Grid container spacing={2} sx={{ mt: 2, pb: 4 }}>
                  <Grid item xs={12} alignContent="center" textAlign={"center"}>
                    <TextField
                      autoFocus
                      fullWidth
                      id="outlined-basic"
                      value={currentElementDescription}
                      label={getTranslatedText("Elementdescription")}
                      variant="outlined"
                      color="success"
                      multiline
                      rows={6}
                      onChange={(e) => {
                        setCurrentElementDescription(e.target.value);
                      }}
                      onBlur={() => {
                        dispatch(
                          setElementDescription(currentElementDescription)
                        );
                      }}
                    />
                  </Grid>

                  {elementAttachments &&
                    elementAttachments.length > 0 &&
                    elementAttachments.map((att, attIdx) => {
                      return (
                        <Grid item xs={4} key={attIdx}>
                          <Card>
                            <div className="cardWrapper">
                              <CardMedia
                                alt="My cool img"
                                component="img"
                                sx={{ width: "100%", maxHeight: "100px" }}
                                image={att.imageAsBase64}
                              />
                              <div className="layer">
                                <IconButton
                                  title={att.imagedescription as string}
                                  color="primary"
                                  onClick={() =>
                                    dispatch(
                                      removeElementAttachments(
                                        att.id
                                      )
                                    )
                                  }
                                >
                                  <RemoveCircleOutlineIcon />
                                </IconButton>
                              </div>
                            </div>
                          </Card>
                        </Grid>
                      );
                    })}

                  {(!elementAttachments || elementAttachments.length < 3) && (
                    <Grid item component={"div"} xs={12}>
                      <Button
                        sx={{ mt: 2, mb: 2, width: "300px" }}
                        variant="outlined"
                        color="success"
                        data-testid="addElementAttachment-button"
                        onClick={selectFiles}
                        startIcon={<AttachFileIcon />}
                      >
                        {getTranslatedText("AddImageToReport")}
                      </Button>
                    </Grid>
                  )}
                </Grid>

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="dark"
                    variant="contained"
                    sx={{ margin: 2 }}
                    onClick={() => dispatch(decreaseCurrentStepId())}
                    className="BackButtonWizardContent"
                    startIcon={<FiArrowLeft />}
                  >
                    {getTranslatedText("PreviousStepButtonText")}
                  </Button>

                  <Button
                    color="success"
                    variant="contained"
                    sx={{ margin: 2, color: "#fff" }}
                    onClick={() => {
                      dispatch(increaseCurrentStepId());
                      dispatch(
                        analyse_navigateWizard({
                          currentStep: WizardItems.EnterElementDetails,
                        })
                      );
                    }}
                    className="NextButtonWizardContent"
                    endIcon={<FiArrowRight />}
                  >
                    {getTranslatedText("NextStepButtonText")}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grow>
        </>
      )}
    </LanguageConsumer>
  );
};

export default EnterElementDetails;
