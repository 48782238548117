import { RootState } from "../../../Infrastructure/Redux/store";
import { FeedbackRequest } from "../Models/FeedbackRequest";
import { FeedbackResponse } from "../Models/FeedbackResponse";
import { postFeedback } from "./FeedbackPostApi";

export const postFeedbackToBackend = async (
  feedbackRequest: FeedbackRequest, dispatch: (action: { payload: undefined; type: string }) => {
    payload: undefined;
    type: string;
  },
  rootState: RootState
): Promise<FeedbackResponse> => {
  try {
    var feddbackResponse = await postFeedback(feedbackRequest, dispatch, rootState);
    return Promise.resolve(feddbackResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
