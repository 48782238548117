import { Grid, Box, Stack } from "@mui/material";
import { ImportantLinks } from "./Components/ImportantLinks";
import { SocialMediaLinks } from "./Components/SocialMediaLinks";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  return !location.search.includes("standalone") ? (
    <Box
      component="footer"
      sx={{
        height: { xs: "auto", sm: "80px" },
        py: { xs: 2, sm: 0 },
        px: 6,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? "rgba(255,255,255,.7)"
            : theme.palette.grey[900],
      }}
    >
      <Grid
        container
        direction="row"
        maxWidth={"xl"}
        sx={{
          mx: "auto",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          px: { xs: 0, md: 3 },
        }}
      >
        <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
          <Box
            component={"div"}
            sx={{
              maxWidth: "100%",
              boxSizing: "content-box",
              overflowX: "hidden",
              height: "100%",
            }}
          >
            <>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={"center"}
                justifyItems={"center"}
                display={"flex"}
                justifyContent={"space-between"}
                spacing={2}
                sx={{ height: "100%" }}
              >
                <ImportantLinks />
                <SocialMediaLinks />
                <a href="https://deprag.com/" target="_blank" rel="noreferrer">
                  <img
                    alt="DEPRAG LOGO"
                    style={{ maxHeight: 40, height: "100%" }}
                    src={"./deprag_machines.png"}
                  />
                </a>
              </Stack>
            </>
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <></>
  );
};

export default Footer;
