/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  NotificationType,
  setNotification,
} from "../../Layout/Notifications/Redux/NotificationsSlice";
import { getTranslation } from "../Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { useHandleMqttResult } from "./Hooks/useHandleMqttResult";
import { MqttRequestDto } from "./Models/MqttRequestDto";
import { MqttResultDto } from "./Models/MqttResultDto";
import {
  MqttConnectionState,
  setConnectionState,
  setCurrentTransactionGuid,
  subscribeToTopic,
  unsubscribeToTopic,
} from "./Redux/MqttStateSlice";
import { setLoading } from "../Configurations/AstConfiguration/Redux/AstConfigurationSlice";
import mqtt from "precompiled-mqtt";
import { analyse_mqttError } from "../Analytics/Redux/AnalyticsSlice";
import {
  AnalyseMqttCommandItems,
  AnalyseMqttSourceItems,
} from "../Analytics/Model/Categories/MqttEnums";
import { ExpectedCloudConnectorVersion } from "../../Infrastructure/Versioninfo/Version";

export interface IMqttContext {
  connectMqtt: () => void;
  disconnectMqtt: () => void;
  subscribeToTopic: (topic: string) => void;
  unsubscribeToTopic: (topic: string) => void;
  publishMqtt: (payload: {}, topic?: string) => void;
}

export const MqttContext = React.createContext<IMqttContext>(undefined!);

export const MqttConsumer = MqttContext.Consumer;

export let SoftwareVersionOutdated = false;

export const checkIfSoftwareVersionIsOutdated = (
  currentVersion: string
): boolean => {
  if (currentVersion === undefined || currentVersion === "") {
    SoftwareVersionOutdated = true;
    return true;
  }

  const currentVersionSplit = currentVersion.split(".").map(Number);
  const expectedVersionSplit =
    ExpectedCloudConnectorVersion.split(".").map(Number);

  for (
    let i = 0;
    i < Math.min(currentVersionSplit.length, expectedVersionSplit.length);
    i++
  ) {
    if (currentVersionSplit[i] < expectedVersionSplit[i]) {
      SoftwareVersionOutdated = true;
      return true;
    } else if (currentVersionSplit[i] > expectedVersionSplit[i]) {
      SoftwareVersionOutdated = false;
      return false;
    }
  }

  if (currentVersionSplit.length < expectedVersionSplit.length) {
    SoftwareVersionOutdated = true;
    return true;
  }

  SoftwareVersionOutdated = false;
  return false;
};

interface MqttProviderProps {
  children: any;
}

export const MqttProvider = (props: MqttProviderProps) => {
  const dispatch = useAppDispatch();
  const clientId = useAppSelector((store) => store.mqttState.clientId);

  const [client, setClient] = useState<any>(null);
  const [currentResult, setCurrentResult] = useState<MqttResultDto>();

  let timeoutHandler = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (clientId !== "") {
      mqttConnect();
    }
  }, [clientId]);

  const handleUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (clientId) {
      window.addEventListener("beforeunload", handleUnload);
      return () => window.removeEventListener("beforeunload", handleUnload);
    }
  }, [handleUnload, clientId]);

  const mqttConnect = () => {
    dispatch(setConnectionState(MqttConnectionState.Connecting));

    setClient(
      mqtt.connect("wss://cloud-connector.deprag-software.com:443/mqtt", {
        username: "sfaclientuser",
        password: "nawtQwfqFGMvttBN69MRjVm92aPBeNuh",
        clientId: clientId + "_Sfa",
        protocolVersion: 5,
        keepalive: 0,
      })
    );
  };

  const mqttDisconnect = () => {
    if (client) {
      client.end(() => {
        dispatch(setConnectionState(MqttConnectionState.Disconnected));
      });
    }
  };

  const mqttSubscribeToTopic = (topic: string) => {
    if (client) {
      client.subscribe(topic, (error) => {
        if (error) {
          dispatch(
            setNotification({
              type: NotificationType.error,
              text: "Error while subscribe: ",
              error,
            })
          );
          dispatch(
            analyse_mqttError({
              source: AnalyseMqttSourceItems.Broker,
              errorMessage: error.toString(),
              command: AnalyseMqttCommandItems.Subscribe,
            })
          );
        }
        dispatch(subscribeToTopic(topic));
      });
    }
  };

  const mqttUnsubscribeToTopic = (topic: string) => {
    if (client) {
      client.unsubscribe(topic, (error) => {
        if (error) {
          dispatch(
            setNotification({
              type: NotificationType.error,
              text: "Error while unsubscribe: ",
              error,
            })
          );
          dispatch(
            analyse_mqttError({
              source: AnalyseMqttSourceItems.Broker,
              errorMessage: error.toString(),
              command: AnalyseMqttCommandItems.Unsubscribe,
            })
          );
        }
        dispatch(unsubscribeToTopic(topic));
      });
    }
  };

  const mqttPublish = (message: {}, topic?: string) => {
    let messageToPublish = { ...message };
    let topicToPublish;
    if (!topic) {
      const parsedMessage = { ...message } as MqttRequestDto;
      topicToPublish = clientId + "/Commands/" + parsedMessage.Command;
      dispatch(setCurrentTransactionGuid(parsedMessage.TransactionGuid));
    } else {
      topicToPublish = topic;
    }

    if (client) {
      client.publish(
        topicToPublish,
        JSON.stringify(messageToPublish),
        (error) => {
          if (error) {
            dispatch(
              setNotification({
                type: NotificationType.error,
                text: "Error while publish: ",
                error,
              })
            );
            dispatch(
              analyse_mqttError({
                source: AnalyseMqttSourceItems.Broker,
                errorMessage: error.toString(),
                command: AnalyseMqttCommandItems.Publish,
              })
            );
          } else if (!topic) {
            const parsedMessage = { ...message } as MqttRequestDto;
            if (
              (parsedMessage.Payload as any).TimeOutInMilliseconds !== undefined
            ) {
              timeoutHandler.current = setTimeout(
                () => {
                  dispatch(
                    setNotification({
                      type: NotificationType.error,
                      text: "TimeoutMQTTNoResponse",
                    })
                  );
                  dispatch(
                    analyse_mqttError({
                      source: AnalyseMqttSourceItems.Broker,
                      errorMessage: "TimeoutError",
                      command: (AnalyseMqttCommandItems as any)[
                        parsedMessage.Command
                      ],
                      parameterName: "TimeOutInMilliseconds",
                      parameter: (
                        parsedMessage.Payload as any
                      ).TimeOutInMilliseconds.toString(),
                    })
                  );
                  if (
                    parsedMessage.Command === "DetectDeviceType" ||
                    parsedMessage.Command === "GetToolData"
                  ) {
                    dispatch(setLoading(false));
                  }
                },
                (parsedMessage.Payload as any).TimeOutInMilliseconds
              );
            }
          }
        }
      );
    }
  };
  useHandleMqttResult({ currentResult, mqttPublish });

  useEffect(() => {
    if (client) {
      client.on("connect", async () => {
        dispatch(
          setConnectionState(MqttConnectionState.ConnectedWithMqttBroker)
        );
        mqttSubscribeToTopic(clientId + "/CommandResults/#");
        mqttSubscribeToTopic(clientId + "/connect");
        mqttSubscribeToTopic(clientId + "/disconnect");

        await navigator.clipboard
          .writeText("DepragCloud:" + clientId)
          .then(() => {
            dispatch(
              setNotification({
                type: NotificationType.success,
                text: "CopiedClientIdSuccessfull",
              })
            );
          })
          .catch(() => {
            dispatch(
              setNotification({
                type: NotificationType.success,
                text: "CopyClientIdFailed",
              })
            );
          });
      });
      client.on("error", (error) => {
        dispatch(
          setNotification({ type: NotificationType.error, text: "ErrorCode1" })
        );
        client.end();
        dispatch(
          analyse_mqttError({
            source: AnalyseMqttSourceItems.Broker,
            errorMessage: error.toString(),
            command: AnalyseMqttCommandItems.Disconnect,
          })
        );
        dispatch(setConnectionState(MqttConnectionState.Disconnected));
      });
      client.on("reconnect", () => {
        dispatch(
          analyse_mqttError({
            source: AnalyseMqttSourceItems.Broker,
            errorMessage: getTranslation("ErrorCode1"),
            command: AnalyseMqttCommandItems.Reconnect,
          })
        );
        dispatch(setConnectionState(MqttConnectionState.Reconnecting));
      });
      client.on("disconnect", (packet) => {
        console.log(packet);
      });
      client.on("close", () => {
        console.log("Disconnected");
      });
      client.on("message", (topic, message) => {
        if (topic === clientId + "/CommandResults/Hello") {
          checkIfSoftwareVersionIsOutdated(
            JSON.parse(String.fromCharCode.apply(null, message)).SoftwareVersion
          );
        } else if (topic === clientId + "/connect") {
          dispatch(
            setConnectionState(
              MqttConnectionState.ConnectionWithCloudConnectorActive
            )
          );
          dispatch(
            setNotification({
              type: NotificationType.success,
              text: "ClientConnectionActive",
            })
          );
        } else if (topic === clientId + "/disconnect") {
          dispatch(
            setConnectionState(MqttConnectionState.ConnectedWithMqttBroker)
          );
          dispatch(
            analyse_mqttError({
              source: AnalyseMqttSourceItems.CloudConnector,
              errorMessage: getTranslation("CloudConnectorLastWillRaised"),
              command: AnalyseMqttCommandItems.Disconnect,
            })
          );
          dispatch(
            setNotification({
              type: NotificationType.error,
              text: "CloudConnectorLastWillRaised",
            })
          );
        } else if (message) {
          clearTimeout(timeoutHandler.current);

          setCurrentResult(
            JSON.parse(new TextDecoder().decode(message)) as MqttResultDto
          );
        }
      });
    }
  }, [client]);

  return (
    <MqttContext.Provider
      value={{
        connectMqtt: mqttConnect,
        disconnectMqtt: mqttDisconnect,
        subscribeToTopic: mqttSubscribeToTopic,
        unsubscribeToTopic: mqttUnsubscribeToTopic,
        publishMqtt: mqttPublish,
      }}
    >
      {props.children}
    </MqttContext.Provider>
  );
};
