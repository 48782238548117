import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../Infrastructure/Redux/hooks";
import { hideNotification } from "./Redux/NotificationsSlice";

export const Notifications = () => {
  const { isExtended, extendedText, currentToastId } = useAppSelector(
    (store) => store.notifications
  );
  const dispatch = useAppDispatch();

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <React.Fragment>
          <Dialog
            onClose={() => {
              dispatch(hideNotification());
            }}
            open={isExtended}
            maxWidth={"lg"}
            PaperProps={{ sx: { height: "auto" } }}
          >
            <DialogTitle>{getTranslatedText("ExtraInformation")}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                dispatch(hideNotification());
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              {getTranslatedText(
                extendedText.filter((x) => x.toastId === currentToastId)[0]
                  ?.text
              )}
            </DialogContent>
          </Dialog>
        </React.Fragment>
      )}
    </LanguageConsumer>
  );
};
