import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { OnlineHelpEvents } from "../Model/Categories/OnlineHelpEnums";
import { reset_analyse_showOnlineHelp } from "../Redux/AnalyticsSlice";

export const usePublishOnlineHelpAnalytics = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const showOnlineHelpState = useAppSelector(
    (store) => store.analytics.showOnlineHelp
  );

  const TrackEvent = (eventName: string, properties: any) => {
    return useTrackEvent(appInsights, eventName, properties, true);
  };

  const trackShowOnlineHelp = TrackEvent(OnlineHelpEvents.Show.toString(), {});

  useEffect(() => {
    if (showOnlineHelpState.toggle) {
      try {
        trackShowOnlineHelp({ Source: showOnlineHelpState.source.toString() });
      } catch {}
      dispatch(reset_analyse_showOnlineHelp());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnlineHelpState.toggle, dispatch]);
};
