import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Infrastructure/Redux/hooks";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Card,
  CardMedia,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  ElementAttachment,
  setCurrentImage,
  setElementAttachments,
  setEnterImageWithDescriptionDialogOpen,
} from "../../../../../../Infrastructure/Configurations/ElementDetails/Redux/ElementDetailsSlice";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";


export const EnterImageWithDescriptionDialog = () => {
  const dispatch = useAppDispatch();
  const { enterImageWithDescriptionDialogOpen, currentImage } = useAppSelector(
    (store) => store.elementDetails
  );
  const [currentImageDescription, setCurrentImageDescription] =
    useState<string>("");

  const handleCancelAddAttachment = () => {
    dispatch(setCurrentImage(null));
    setCurrentImageDescription("");
    dispatch(setEnterImageWithDescriptionDialogOpen(false));
  };

  const handleAddAttachment = () => {
    dispatch(
      setElementAttachments({
        imageAsBase64: currentImage,
        imagedescription: currentImageDescription,
        id: uuidv4()
      } as ElementAttachment)
    );
    setCurrentImageDescription("");
    dispatch(setEnterImageWithDescriptionDialogOpen(false));
  };

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Dialog
          open={enterImageWithDescriptionDialogOpen}
          onClose={handleCancelAddAttachment}
          maxWidth={"xl"}
        >
          <DialogTitle>
            {getTranslatedText("EnterImageWithDescriptionDialogTitle")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              dispatch(setEnterImageWithDescriptionDialogOpen(false));
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Card>
              <div className="cardWrapper">
                <CardMedia
                  alt="My cool img"
                  component="img"
                  sx={{ width: "100%", maxHeight: "500px" }}
                  image={currentImage!}
                />
              </div>
            </Card>
            <TextField
              autoFocus
              fullWidth
              id="outlined-basic"
              value={currentImageDescription}
              variant="outlined"
              color="success"
              sx={{ mt: 2, mb: 4 }}
              label={getTranslatedText("ImageDescription")}
              onChange={(e) => {
                setCurrentImageDescription(e.target.value);
              }}
            />

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="success"
                variant="outlined"
                sx={{ margin: 2 }}
                onClick={handleCancelAddAttachment}
                className="BackButtonWizardContent"
              >
                {getTranslatedText("CancelAddAttachment")}
              </Button>

              <Button
                color="success"
                variant="contained"
                sx={{ margin: 2, color: "#fff" }}
                onClick={handleAddAttachment}
                className="NextButtonWizardContent"
              >
                {getTranslatedText("ConfirmAddAttachment")}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </LanguageConsumer>
  );
};
