import {
  Backdrop,
  Box,
  BoxProps,
  Button,
  Grid,
  Paper,
  Slide,
  StyledComponentProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";

interface CustomImageProps {
  src: string;
  overlayEnabled?: boolean;
  borderCustomRadius?: number[];
  hasOverlay?: boolean;
  overlayTitle?: string;
  overlayDescription?: string;
  initialDescription?: string;
  toLink?: string;
  clipPath?: "right" | "left";
  centerOverlayText?: boolean;
  blurIntensity?: number;
  slideDirection?: "left" | "up" | "down" | "right";
  slidePosition?: "left" | "right";
}

interface CustomImageProps extends BoxProps, StyledComponentProps {}

/**
 * Custom image component by DEPRAG
 * @param {string} props.src Path to image file
 * @param {boolean} props.hasOverlay Use overlay on image (optional)
 * @param {boolean} props.overlayEnabled Is overlay active by default (optional)
 * @param {number[]} props.borderCustomRadius Border radius for component [topLeft,topRight,bottomLeft,bottomRight] (optional)
 * @param {string} props.overlayTitle Title of overlay (optional)
 * @param {string} props.initialDescription Description before hovering over element (optional)
 * @param {string} props.overlayDescription Description after hovering over element (optional)
 * @param {string} props.toLink NavLink to route on details button (optional)
 * @param {'right' | 'left'} props.clipPath Clip the image on left or right side (optional)
 * @param {boolean} props.centerOverlayText Center all overlay texts (optional)
 * @param {number} props.blurIntensity Intensity of the blur (optional)
 * @param {number} props.slideDirection Direction of Slide 'left, right, up, down' (optional)
 * @param {number} props.slidePosition Slideposition after hover 'left, right' (optional)
 *
 * @visibleName The Best Image Component by DEPRAG🐙
 */

export const CustomImage = (props: CustomImageProps) => {
  const [overlayActive, setOverlayActive] = useState<boolean>(true);
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const isMobile = useMediaQuery("(max-width:500px)");

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Fragment key={props.overlayTitle}>
          <Box
            elevation={0}
            className={props.className}
            onClick={props.onClick}
            onMouseOver={() => setOverlayActive((old) => !Boolean(old))}
            onMouseOut={() => setOverlayActive((old) => !Boolean(old))}
            onContextMenu={props.onContextMenu}
            {...props}
            component={Paper}
            sx={{
              borderTopLeftRadius:
                props.borderCustomRadius !== undefined
                  ? props.borderCustomRadius[0]
                  : 0,
              borderBottomLeftRadius:
                props.borderCustomRadius !== undefined
                  ? props.borderCustomRadius[2]
                  : 0,
              borderTopRightRadius:
                props.borderCustomRadius !== undefined
                  ? props.borderCustomRadius[1]
                  : 0,
              borderBottomRightRadius:
                props.borderCustomRadius !== undefined
                  ? props.borderCustomRadius[3]
                  : 0,
              background: `url(${props.src})`,
              backgroundSize: "80px 80px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center 40px",
              width: "100%",
              height: props.height,
              display: "flex",
              overflow: "hidden",
              position: "relative",
              alignItems: "flex-end",
              justifyContent:
                props.slidePosition === undefined
                  ? "flex-end"
                  : props.slidePosition === "right"
                    ? "flex-start"
                    : "flex-end",
              alignContent: "center",
              transition: "all 0.3s ease-in-out",
              ...props.sx,
            }}
          >
            {props.hasOverlay && (
              <Slide
                direction={props.slideDirection ? props.slideDirection : "up"}
                in={!overlayActive}
              >
                <Box
                  elevation={0}
                  component={Paper}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    width:
                      props.slideDirection === "left" ||
                      props.slideDirection === "right"
                        ? isMobile
                          ? "100%"
                          : "50%"
                        : "100%",
                    borderRadius: 0,
                    background: isDarkMode
                      ? "rgba(0,0,0,0.9)"
                      : "rgba(255,255,255,0.9)",
                    p: 2,
                    textAlign: "left",
                  }}
                >
                  <Grid
                    container
                    direction={"row"}
                    spacing={2}
                    sx={{
                      textAlign: props.centerOverlayText ? "center" : "left",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight={700}>
                        {props.overlayTitle}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <Typography>{props.overlayDescription}</Typography>
                    </Grid>
                    {props.toLink && (
                      <Grid item xs={12}>
                        <Button
                          component={NavLink}
                          to={props.toLink}
                          variant="outlined"
                        >
                          {getTranslatedText("CommonMore")}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Slide>
            )}

            {props.hasOverlay && (
              <Backdrop
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  p: 0,
                  m: 0,
                  background: "transparent",
                  alignItems: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                open={overlayActive}
              >
                <Grid container direction={"row"} spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ textAlign: "center", mt: 16 }}
                      variant={"h6"}
                      fontWeight={500}
                    >
                      {props.overlayTitle}
                    </Typography>
                  </Grid>
                </Grid>
              </Backdrop>
            )}
          </Box>
        </Fragment>
      )}
    </LanguageConsumer>
  );
};
