import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createReportFromBackend } from "../../../Infrastructure/CreateReport/Redux/CreateReportSlice";

export interface LoadingIndicatorState {
  isLoading: boolean;
}

const initialState: LoadingIndicatorState = {
  isLoading: false,
};

export const loadingIndicatorSlice = createSlice({
  name: "loadingindicator",
  initialState,
  reducers: {
    setLoadingIndicator: (state) => {
      state.isLoading = true;
    },
    resetLoadingIndicator: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(createReportFromBackend.pending), (state) => {
        state.isLoading = true;
      })
      .addMatcher(isAnyOf(createReportFromBackend.fulfilled), (state) => {
        state.isLoading = false;
      })
      .addMatcher(isAnyOf(createReportFromBackend.rejected), (state) => {
        state.isLoading = false;
      });
  },
});

export const { setLoadingIndicator, resetLoadingIndicator } =
  loadingIndicatorSlice.actions;

export default loadingIndicatorSlice.reducer;
