/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import Footer from "./Layout/Footer/Footer";
import { useAppDispatch, useAppSelector } from "./Infrastructure/Redux/hooks";
import LoadingIndicator from "./Layout/LoadingIndicator/LoadingIndicator";
import { themeDark, themeLight } from "./Layout/Theme/ThemeProvider";
import { LanguageConsumer } from "./Infrastructure/Internationalisation/TranslationService";
import { Box } from "@mui/material";
import CookieConsent from "react-cookie-consent";
import { Privacy } from "./Features/Privacy/Privacy";
import { Imprint } from "./Features/Imprint/Imprint";
import ScrollToTop from "./Infrastructure/ScrollTopRouter/ScrollToTop";
import { loadCsv } from "./Infrastructure/Internationalisation/Redux/LanguageFileSlice";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { ThirdPartyLicenceNotes } from "./Features/ThirdPartyLicenceNotes/ThirdPartyLicenceNotes";
import { Home } from "./Features/Home/Home";
import { Notifications } from "./Layout/Notifications/Notifications";
import { Feedback } from "./Features/Feedback/Feedback";
import { LandingPage } from "./Features/LandingPage/LandingPage";
import { usePublishAnalytics } from "./Infrastructure/Analytics/Hooks/usePublishAnalytics";
import { Toaster } from "react-hot-toast";
import { ResponsiveDesign } from "./Layout/ResponsiveDesign/ResponsiveDesign";
import { DefaultLayout } from "./Layout/DefaultLayout/DefaultLayout";
import UseOnlyInMyDepragDialog from "./Features/Dialogs/UseOnlyInMyDepragDialog";

function App() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((store) => store.loadingIndicator.isLoading);
  const appTheme = useAppSelector((store) => store.settings.appTheme);
  const languageFileWasLoaded = useAppSelector(
    (store) => store.languageFile.languageFileWasLoaded
  );
  const isStandalone = useAppSelector((store) => store.navigation.isStandalone);
  const isMyDeprag = useAppSelector((store) => store.navigation.isMyDeprag);

  usePublishAnalytics();

  useEffect(() => {
    dispatch(loadCsv());
  }, []);

  return (
    <Fragment>
      {languageFileWasLoaded && (
        <LanguageConsumer>
          {({ getTranslatedText }) => (
            <ThemeProvider theme={appTheme === "dark" ? themeDark : themeLight}>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                  alignItems: "stretch",
                }}
              >
                <UseOnlyInMyDepragDialog
                  open={window.parent.location === window.location}
                  headerTextKey={"UseOnlyInMyDeprag"}
                  descriptionTextKey={"UseOnlyInMyDepragDescription"}
                />

                <CssBaseline />
                <Notifications />
                <Toaster
                  position="bottom-right"
                  toastOptions={{
                    duration: 5000,
                    style: {
                      maxWidth: "none",
                      color: appTheme === "dark" ? "#fff" : "#333",
                      background: appTheme === "dark" ? "#333" : "#fff",
                    },
                  }}
                />

                <Router>
                  {isLoading && <LoadingIndicator />}
                  <ScrollToTop />

                  <ResponsiveDesign>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                      <Routes>
                        <Route element={<DefaultLayout />}>
                          <Route path="/" element={<LandingPage />}></Route>
                          <Route path="/Home" element={<Home />}></Route>
                          <Route path="/privacy" element={<Privacy />}></Route>
                          <Route path="/imprint" element={<Imprint />}></Route>
                          <Route
                            path="/thirdpartylicensenotes"
                            element={<ThirdPartyLicenceNotes />}
                          ></Route>
                        </Route>
                      </Routes>
                    </QueryParamProvider>
                  </ResponsiveDesign>

                  {!isMyDeprag && <Footer />}
                  <Feedback />

                  {!isStandalone && !isMyDeprag && (
                    <CookieConsent
                      location="bottom"
                      buttonText={getTranslatedText("CookieButtonText")}
                      cookieName="depragDigitalScrewJointLaboratoryCookie"
                      style={{
                        background: "#5f825c",
                        color: "#fff",
                        zIndex: 10001,
                      }}
                      buttonStyle={{
                        border: "1px solid #fff",
                        background: "transparent",
                        color: "#fff",
                        height: "45px",
                        borderRadius: "5px",
                        fontSize: "13px",
                      }}
                      enableDeclineButton={false}
                      setDeclineCookie={false}
                      declineButtonStyle={{
                        border: "1px solid #fff",
                        background: "transparent",
                        color: "#fff",
                        height: "45px",
                        borderRadius: "5px",
                        fontSize: "13px",
                      }}
                      declineButtonText={getTranslatedText("ShowPrivacyText")}
                      hideOnDecline={false}
                      expires={150}
                    >
                      {getTranslatedText("CookieText")}
                    </CookieConsent>
                  )}
                </Router>
              </Box>
            </ThemeProvider>
          )}
        </LanguageConsumer>
      )}
    </Fragment>
  );
}

export default App;
