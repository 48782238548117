interface CountryFlagProps {
  isoCode: string;
  sx?: any;
}

export const CountryFlag = (props: CountryFlagProps) => {
  const { isoCode, sx } = props;

  return (
    <img
      {...props}
      src={`/Images/Language/${isoCode}.svg`}
      alt={isoCode}
      style={{
        display: "inline-block",
        width: "1em",
        height: "1em",
        verticalAlign: "middle",
        ...sx,
      }}
    />
  );
};
