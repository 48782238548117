import { createSlice } from "@reduxjs/toolkit";

export interface PopoverState {
  popoverFeedbackLastShown: string | null;
}

const initialState: PopoverState = {
  popoverFeedbackLastShown: null,
};

export const popoverSlice = createSlice({
  name: "popover",
  initialState,
  reducers: {
    setPopoverFeedbackLastShown: (state) => {
      state.popoverFeedbackLastShown = new Date().toString();
    },
  },
});

export const { setPopoverFeedbackLastShown } = popoverSlice.actions;

export default popoverSlice.reducer;
