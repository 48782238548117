export enum HardwareType {
  None,
  Ast5,
  Ast5S,
  Ast6,
  Asti6,
  Ast10,
  Ast11,
  Ast40,
  Ast12,
  Interface330,
  ComCenterEd,
}
