import { useAppSelector } from "../../../Redux/hooks";
import {
  DefaultMonitorNextTighteningRequestPayloadDto,
  MonitorNextTighteningRequestPayloadDto,
} from "../../Models/Commands/MonitorNextTightening/MonitorNextTighteningRequestPayloadDto";
import { MqttRequestDto } from "../../Models/MqttRequestDto";
import { v4 as uuidv4 } from "uuid";

interface UseHandleUploadProgramCommandArgs {
  mqttPublish: (payload: {}, topic?: string) => void;
}

export const useHandleSetProgramCommandResult = ({
  mqttPublish,
}: UseHandleUploadProgramCommandArgs) => {
  const astConfiguration = useAppSelector((store) => store.astConfiguration);
  const processConfiguration = useAppSelector(
    (store) => store.processConfiguration
  );

  const handleSetProgramCommandResultSuccess = () => {
    const monitorNextTighteningRequest = {
      TransactionGuid: uuidv4(),
      Command: "MonitorNextTightening",
      Payload: {
        ...DefaultMonitorNextTighteningRequestPayloadDto,
        IpAddressOrDns: astConfiguration.ipAddressOrDns,
        Username: astConfiguration.username,
        Password: astConfiguration.password,
        ExpectedProgramNumber: processConfiguration.programNumber,
      } as MonitorNextTighteningRequestPayloadDto,
    } as MqttRequestDto;

    mqttPublish(monitorNextTighteningRequest);
  };

  return { handleSetProgramCommandResultSuccess };
};
