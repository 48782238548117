import { MainCategories } from "../MainCategories";

export enum WizardEvents {
  Next = MainCategories.Wizard + "Next",
}

export enum WizardItems {
  Start = "Start",
  DownloadAndStart = "Download and start ClientConnector",
  ConfigureAst = "Configure your AST",
  ConfigureProcess = "Configure process",
  ExecuteProcess = "Execute process",
  EnterCustomerDetails = "Enter customer details",
  EnterElementDetails = "Enter element details",
  DisplayResults = "Display results",
}
