import { createSlice } from "@reduxjs/toolkit";

export interface LicenceAuthentication {
  isLicenced: boolean;
  myDepragApiKey: string;
  token: string;
  refreshToken: string;
  tokenReceived: Date | undefined;
}

export interface LicenceServiceState {
  licenceAuthentication: LicenceAuthentication;
}

export const licenceInitialState: LicenceServiceState = {
  licenceAuthentication: {
    isLicenced: false,
    myDepragApiKey: "",
    token: "",
    refreshToken: "",
    tokenReceived: undefined,
  },
};

export const licenceServiceSlice = createSlice({
  name: "licenceService",
  initialState: licenceInitialState,
  reducers: {
    setToken: (state, action) => {
      state.licenceAuthentication.token = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.licenceAuthentication.refreshToken = action.payload;
      state.licenceAuthentication.tokenReceived = new Date();
    },
    resetLicence: (state) => {
      state.licenceAuthentication = licenceInitialState.licenceAuthentication;
    },
    setMyDepragApiKey: (state, action) => {
      state.licenceAuthentication.myDepragApiKey = action.payload;
    },
    setIsLicensed: (state, action) => {
      state.licenceAuthentication.isLicenced = action.payload;
    },
  },
});

export const {
  setIsLicensed,
  setMyDepragApiKey,
  resetLicence,
  setToken,
  setRefreshToken,
} = licenceServiceSlice.actions;

export default licenceServiceSlice.reducer;
