import ConfigureAst from "../Content/3_ConfigureAst/ConfigureAst";
import DisplayResults from "../Content/100_DisplayResults/DisplayResults";
import DownloadAndStartClient from "../Content/2_DownloadAndStartClient/DownloadAndStartClient";
import EnterCustomerDetails from "../Content/6_EnterCustomerDetails/EnterCustomerDetails";
import ExecuteProcess from "../Content/5_ExecuteProcess/ExecuteProcess";
import ParamProcess from "../Content/4_ParamProcess/ParamProcess";
import Start from "../Content/1_Start/Start";
import EnterElementDetails from "../Content/7_EnterElementDetails/EnterElementDetails";

export const ComponentIdentifierToComponentMapper = {
  Start: <Start />,
  DownloadAndStartClient: <DownloadAndStartClient />,
  ConfigureAst: <ConfigureAst />,
  ParamProcess: <ParamProcess />,
  ExecuteProcess: <ExecuteProcess />,
  EnterCustomerDetails: <EnterCustomerDetails />,
  EnterElementDetails: <EnterElementDetails />,
  DisplayResults: <DisplayResults />,
};
