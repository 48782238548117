import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { MainCategories } from "../Model/MainCategories";
import { reset_analyse_mqttError } from "../Redux/AnalyticsSlice";

export const usePublishMqttErrorAnalytics = () => {
  const appInsights = useAppInsightsContext();
  const dispatch = useAppDispatch();

  const mqttErrorState = useAppSelector((store) => store.analytics.mqttError);

  const TrackEvent = (eventName: string, properties: any) => {
    return useTrackEvent(appInsights, eventName, properties, true);
  };

  const trackMqttError = TrackEvent(MainCategories.MqttError.toString(), {});

  useEffect(() => {
    if (mqttErrorState.toggle) {
      try {
        if (
          mqttErrorState.parameter === undefined ||
          mqttErrorState.parameterName === undefined
        ) {
          trackMqttError({
            Source: mqttErrorState.source.toString(),
            ErrorMessage: mqttErrorState.errorMessage.toString(),
            Command: mqttErrorState.command.toString(),
          });
        } else {
          trackMqttError({
            Source: mqttErrorState.source.toString(),
            ErrorMessage: mqttErrorState.errorMessage.toString(),
            Command: mqttErrorState.command.toString(),
            Parameter: mqttErrorState.parameter?.toString(),
            ParameterName: mqttErrorState.parameterName?.toString(),
          });
        }
      } catch {}
      dispatch(reset_analyse_mqttError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqttErrorState.toggle, dispatch]);
};
