import { DefaultSetProgramRequestPayloadDto } from "../SetProgram/SetProgramRequestPayloadDto";

export interface MonitorNextTighteningRequestPayloadDto {
  IpAddressOrDns: string;
  Username: string;
  Password: string;
  TimeOutInMilliseconds: number;
  EnableTool: boolean;
  DisableToolAfterTightening: boolean;
  TransferGraphFile: boolean;
  TransferFinalResult: boolean;
  ExpectedProgramNumber: number;
}

export const DefaultMonitorNextTighteningRequestPayloadDto: MonitorNextTighteningRequestPayloadDto =
  {
    IpAddressOrDns: "",
    Username: "",
    Password: "",
    TimeOutInMilliseconds: 21600000, // 6h
    EnableTool: true,
    DisableToolAfterTightening: true,
    TransferGraphFile: true,
    TransferFinalResult: true,
    ExpectedProgramNumber:
      DefaultSetProgramRequestPayloadDto.SetProgramWithNumber,
  };
