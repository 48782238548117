import { Typography } from "@mui/material";
import React from "react";

export const ImprintLanguage = ({ lang }) => {
  if (lang === "de") {
    return (
      <React.Fragment>
        <p>
          <strong>DEPRAG SCHULZ GMBH u. CO. KG</strong>
          <br />
          <br />
          Carl-Schulz-Platz 1 <br />
          D-92224 Amberg i.d. Opf.
          <br />
          <br />
          Telefon: +49 9621 / 371 - 0 <br />
          Telefax: +49 9621 / 371 - 120
          <br />
          E-Mail: <a href="mailto:info@deprag.de">info@deprag.de</a>
        </p>
        <Typography fontSize={"large"} sx={{ color: "#32992c" }}>
          Impressum
        </Typography>
        <p>
          <strong>Angaben gemäß § 5 TMG:</strong>
          <br />
          <br />
          DEPRAG SCHULZ GMBH u. CO. KG
          <br />
          Carl-Schulz-Platz 1<br />
          92224 Amberg
          <br />
          <br />
          <strong>Vertreten durch:</strong>
          <br />
          <br />
          Dr. Erik Hallmann
          <br />
          Dr.-Ing. Rolf Pfeiffer
          <br />
          <br />
          <strong>Kontakt:</strong>
          <br />
          <br />
          Telefon: +49 (0) 9621-371/0
          <br />
          Telefax: +49 (0) 9621-371/120
          <br />
          E-Mail: info@deprag.de
          <br />
          <br />
          <strong>Registereintrag:</strong>
          <br />
          <br />
          Eintragung im Handelsregister.
          <br />
          Registergericht:Amberg
          <br />
          Registernummer: HRA 312
          <br />
          <br />
          <strong>Umsatzsteuer:</strong>
          <br />
          <br />
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
          <br />
          DE131826117
          <br />
          <br />
          <strong>Streitschlichtung</strong>
          <br />
          <br />
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer
          <br />
          Verbraucherschlichtungsstelle teilzunehmen.
          <br />
          <br />
          <br />
          <strong>Haftung für Inhalte</strong>
          <br />
          <br />
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen.
          <br />
          <br />
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
          <br />
          <br />
          <strong>Haftung für Links</strong>
          <br />
          <br />
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
          <br />
          <br />
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
          <br />
          <br />
          <strong>Urheberrecht</strong>
          <br />
          <br />
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          <br />
          <br />
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
          <br />
          <br />
          Quelle:{" "}
          <a
            style={{ color: "#444" }}
            href="https://www.e-recht24.de/impressum-generator.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.e-recht24.de/impressum-generator.html
          </a>
        </p>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <p>
          <strong>DEPRAG SCHULZ GMBH u. CO. KG</strong>
          <br />
          <br />
          Carl-Schulz-Platz 1 <br />
          D-92224 Amberg i.d. Opf.
          <br />
          <br />
          Phone: +49 9621 / 371 - 0 <br />
          Fax: +49 9621 / 371 - 120
          <br />
          E-Mail: <a href="mailto:info@deprag.de">info@deprag.de</a>
        </p>
        <Typography fontSize={"large"} sx={{ color: "#32992c" }}>
          Legal Notices
        </Typography>
        <p>
          <strong>Information in accordance with § 5 TMG:</strong>
          <br />
          <br />
          DEPRAG SCHULZ GMBH u. CO. KG
          <br />
          Carl-Schulz-Platz 1<br />
          92224 Amberg
          <br />
          <br />
          <strong>Represented by:</strong>
          <br />
          <br />
          Dr. Erik Hallmann
          <br />
          Dr.-Ing. Rolf Pfeiffer
          <br />
          <br />
          <strong>Contact:</strong>
          <br />
          <br />
          Telephone: +49 (0) 9621-371/0
          <br />
          Telefax: +49 (0) 9621-371/120
          <br />
          E-Mail: info@deprag.de
          <br />
          <br />
          <strong>Register entry:</strong>
          <br />
          <br />
          Entry in the commercial register.
          <br />
          Registration court: Amberg
          <br />
          Registration number: HRA 312
          <br />
          <br />
          <strong>Sales tax:</strong>
          <br />
          <br />
          Sales tax identification number in accordance with sales tax law §27
          a:
          <br />
          DE131826117
          <br />
          <br />
          <strong>Settlement of disputes</strong>
          <br />
          <br />
          We are not willing or obligated to participate in dispute settlement
          proceedings at a consumer arbitration court.
          <br />
          <br />
          <br />
          <strong>Content liability</strong>
          <br />
          <br />
          As a service provider, in accordance with general law, we are
          responsible for our own content on these pages in conformity with § 7
          paragraph 1 TMG. In accordance with §§ 8 to 10 TMG we, as a service
          provider, are, however, not obligated to monitor transmitted or stored
          third-party information, nor are we obligated to research in
          circumstances of illegal activities.
          <br />
          <br />
          This does not affect obligations under general law to remove or block
          information usage. A liability in this regard is however, only
          applicable from the time of the establishment of a concrete violation
          of the law. Should such a violation become apparent, we will
          immediately remove this content.
          <br />
          <br />
          <strong>Liability for links</strong>
          <br />
          <br />
          Our website contains links to external third-party websites. We have
          no influence over the content on these websites. Therefore, we do not
          accept liability for the content of external websites. In all cases,
          the respective website provider or operator is responsible for the
          content of linked pages. The linked pages were checked for potential
          legal violations at the time of linkage and illegal contents were not
          apparent at that time.
          <br />
          <br />
          Continuous content-related monitoring of linked websites cannot be
          reasonably expected, unless there is concrete evidence of legal
          violations. Should legal violations become apparent, such links will
          be removed immediately.
          <br />
          <br />
          <strong>Copyright</strong>
          <br />
          <br />
          The contents and works of these pages created by the website operator
          are subject to German copyright law. Reproduction, editing,
          distribution and any kind of use outside the limits of copyright law
          require the written consent of the author or creator. Downloads or
          copies of this page are only permissible for private, non-commercial
          use.
          <br />
          <br />
          Insofar as content on these pages is not created by the operator,
          third party copyrights are respected. In particular, third-party
          content is labelled as such. If you however, become aware of a
          copyright violation, we ask that you contact us with this information.
          Should legal violations become apparent, such content will be removed
          immediately.
          <br />
          <br />
          Source:{" "}
          <a
            style={{ color: "#444" }}
            href="https://www.e-recht24.de/impressum-generator.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.e-recht24.de/impressum-generator.html
          </a>
        </p>
      </React.Fragment>
    );
  }
};
