export interface SetScrewdrivingRelatedSettingsRequestPayloadDto {
  IpAddressOrDns: string;
  Username: string;
  Password: string;
  TimeOutInMilliseconds: number;
  ProgramNumber?: number;
  ProgramChoice?: string;
  StartEnable?: string;
  StartEnableMode?: string;
}

export const DefaultSetScrewdrivingRelatedSettingsRequestPayloadDto: SetScrewdrivingRelatedSettingsRequestPayloadDto =
  {
    IpAddressOrDns: "",
    Username: "",
    Password: "",
    TimeOutInMilliseconds: 30000,
  };
