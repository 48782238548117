import { checkIfTokenIsStillValidAndRefreshIfNecessary } from "./RefreshToken";
import { RootState } from "../Redux/store";

export const baseUrl =
  (window.location.hostname === "localhost"
    ? "https:"
    : window.location.protocol) +
  "//" +
  (window.location.hostname === "localhost"
    ? window.location.hostname
    : globalThis.Settings
      ? globalThis.Settings.sfaApiHostName
      : window.location.hostname) +
  ":" +
  (window.location.hostname === "localhost"
    ? "9051"
    : globalThis.Settings
      ? globalThis.Settings.sfaApiPort
      : "9051") +
  "/api/v1.0/";

export const fetchBaseApi = async (
  requestBody: any | null = null,
  apiRoute: string,
  apiMethod: string,
  dispatch: (action: { payload: undefined; type: string }) => {
    payload: undefined;
    type: string;
  },
  rootState: RootState,
  signal?: AbortSignal | undefined
): Promise<Response> => {
  let newToken = null as string | null;
  newToken = await checkIfTokenIsStillValidAndRefreshIfNecessary(
    dispatch,
    rootState
  );

  var authenticationToken =
    newToken !== undefined && newToken !== null
      ? newToken
      : rootState.licenceService.licenceAuthentication.token;

  var response = await fetch(baseUrl + apiRoute, {
    body:
      apiMethod === "GET" || requestBody === null
        ? undefined
        : JSON.stringify(requestBody),
    method: apiMethod,
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: !authenticationToken
        ? "No-Auth"
        : `Bearer ${authenticationToken}`,
    }),
    signal: signal,
  });

  return response;
};
