import { fetchBaseApi } from "../../Api/BaseApi";
import { RootState } from "../../Redux/store";
import { CreateReportRequestDto } from "../Models/CreateReportRequestDto";

const simpleFetchCall = async (
  request: CreateReportRequestDto,
  abortController: AbortController,
  dispatch: (action: { payload: undefined; type: string }) => {
    payload: undefined;
    type: string;
  },
  rootState: RootState
) => {
  var response = await fetchBaseApi(
    {
      TighteningInformations: request.TighteningInformations,
      Date: request.Date,
      Language: request.Language,
      CustomerInformations: {
        CustomerSurename: request.CustomerInformations.CustomerSurename,
        CustomerLastname: request.CustomerInformations.CustomerLastname,
        CustomerCompany: request.CustomerInformations.CustomerCompany,
        CustomerStreet: request.CustomerInformations.CustomerStreet,
        CustomerHouseNumber: request.CustomerInformations.CustomerHouseNumber,
        CustomerZipCode: request.CustomerInformations.CustomerZipCode,
        CustomerCity: request.CustomerInformations.CustomerCity,
      },
      ElementDetails: {
        ElementDescription: request.ElementDetails.ElementDescription,
        ElementAttachments: request.ElementDetails.ElementAttachments,
      },
      ProcessorInformations: {
        ProcessorFirstname: request.ProcessorInformations.ProcessorFirstname,
        ProcessorLastname: request.ProcessorInformations.ProcessorLastname,
        ProcessorCompany: request.ProcessorInformations.ProcessorCompany,
        ProcessorEmailAdress:
          request.ProcessorInformations.ProcessorEmailAdress,
      },
      HardwareSerialNumber: request.HardwareSerialNumber,
      ScrewdriverType: request.ScrewdriverType,
      ScrewdriverSerialNumber: request.ScrewdriverSerialNumber,
      ScrewdriverSpeedMin: request.ScrewdriverSpeedMin,
      ScrewdriverSpeedMax: request.ScrewdriverSpeedMax,
      ScrewdriverTorqueMin: request.ScrewdriverTorqueMin,
      ScrewdriverTorqueMax: request.ScrewdriverTorqueMax,
      ConfiguredTorque: request.ConfiguredTorque,
      ConfiguredRotationalSpeed: request.ConfiguredRotationalSpeed,
      TorqueUnit: request.TorqueUnit,
      ControllerType: request.ControllerType,
      GroupByTighteningPosition: request.GroupByTighteningPosition,
      AmountOfComponents: request.AmountOfComponents,
      AmountOfScrewdrivingPoints: request.AmountOfScrewdrivingPoints,
      DemandTorques: request.DemandTorques,
      WithDemandTorques: request.WithDemandTorques,
    },
    "createReport",
    "POST",
    dispatch,
    rootState,
    abortController.signal
  );

  return response;
};

export const createReport = async (
  request: CreateReportRequestDto,
  abortController: AbortController,
  dispatch: (action: { payload: undefined; type: string }) => {
    payload: undefined;
    type: string;
  },
  rootState: RootState
) => {
  try {
    var response = (await simpleFetchCall(
      request,
      abortController,
      dispatch,
      rootState
    )) as any;

    return new Promise<any>(async (resolve, reject) => {
      if (response && response.status !== 400) {
        resolve(await response.blob());
      } else {
        reject(await response.json());
      }
    });
  } catch (error: any) {
    return Promise.reject(
      error.response && error.response.data
        ? error.response.data.MessageText
        : error
    );
  }
};
