import { Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { Information } from "../../../../../../Layout/Information/Information";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Infrastructure/Redux/hooks";
import { toggleChecked } from "../../../../../../Infrastructure/Configurations/StartConfiguration/StartConfigurationSlice";
import { MqttConnectionState } from "../../../../../../Infrastructure/Mqtt/Redux/MqttStateSlice";

const list = [
  { desc: "StartCheckboxDesc1", info: "StartCheckboxInfo1", id: 1 },
  { desc: "StartCheckboxDesc2", info: "StartCheckboxInfo2", id: 2 },
  { desc: "StartCheckboxDesc3", info: "StartCheckboxInfo3", id: 3 },
  { desc: "StartCheckboxDesc4", info: "StartCheckboxInfo4", id: 4 },
  { desc: "StartCheckboxDesc5", info: "StartCheckboxInfo5", id: 5 },
];

export const StartCheckboxList = () => {
  const dispatch = useAppDispatch();
  const { checked } = useAppSelector((store) => store.startConfiguration);
  const { connectionState } = useAppSelector((store) => store.mqttState);
  const { isConfigured } = useAppSelector((store) => store.astConfiguration);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <FormGroup sx={{ mt: 2 }}>
          {list.map((item) => (
            <FormControlLabel
              key={`checkbox${item.id}`}
              control={
                <Checkbox
                  color="success"
                  disabled={
                    connectionState ===
                      MqttConnectionState.ConnectionWithCloudConnectorActive &&
                    isConfigured
                  }
                  checked={checked.includes(item.id)}
                  onChange={() => {
                    dispatch(toggleChecked(item.id));
                  }}
                />
              }
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {getTranslatedText(item.desc)}
                  <Information
                    text={getTranslatedText(item.info)}
                    placement="right"
                  />
                </Box>
              }
              disableTypography
              sx={{ fontWeight: 500, width: "fit-content" }}
            />
          ))}
        </FormGroup>
      )}
    </LanguageConsumer>
  );
};
