import React from "react";
import { Tooltip, Box, SxProps, Theme } from "@mui/material";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";
import { MdInfo } from "react-icons/md";

interface InformationProps {
  text: string;
  size?: number;
  sx?: SxProps<Theme>;
  placement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
}

export const Information = ({
  text,
  placement,
  sx,
  size = 22,
}: InformationProps) => {
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );

  return (
    <Tooltip
      title={
        <React.Fragment>
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        </React.Fragment>
      }
      placement={placement}
      sx={sx}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <MdInfo color={isDarkMode ? "#c2c2c2" : "#656565"} size={size} />
      </Box>
    </Tooltip>
  );
};
