import { TorqueUnit } from "../../../Features/Settings/Enum/TorqueUnit";

const DefaultSFAScrewdrivingSequence = {
  Units: "Nm",
  SequenceId: null,
  SequenceNumber: 0,
  SequenceTitle: "SFA",
  SequenceDescription: "",
  DirectionChange: "inactive",
  Reload: "always",
  Plausibility: "inactive",
  IsValid: true,
  Errors: [],
  ServiceParameters: [],
  SequenceSteps: [
    {
      StepType: "FastenTorque",
      Errors: [],
      ProgParamMaxScrewTime: 60000,
      ProgParamOffTorque: 0,
      ProgParamMinTorque: 0,
      ProgParamMaxTorque: 0,
      ProgParamSpeed: 0,
      ProgParamHoldTime: 0,
      ProgParamAngleSupervision: "inactive",
      ProgParamSwitchTorque: 0.0,
      ProgParamMinAngle: 0.0,
      ProgParamMaxAngle: 1800.0,
      ProgParamAccRamp: 100,
    },
    {
      StepType: "SaveValues",
      Errors: [],
    },
    {
      StepType: "DisplayValues",
      Errors: [],
    },
  ],
};

interface CreateScrewdrivingSequenceArgs {
  torqueUnit: TorqueUnit;
  sequenceNumber: number;
  maxTorque: number;
  rotationalSpeed: number;
}

export const CreateScrewdrivingSequence = ({
  torqueUnit,
  sequenceNumber,
  maxTorque,
  rotationalSpeed,
}: CreateScrewdrivingSequenceArgs): string => {
  let screwdrivingSequenceAsJson = { ...DefaultSFAScrewdrivingSequence };

  screwdrivingSequenceAsJson.Units = TorqueUnit[torqueUnit];
  screwdrivingSequenceAsJson.SequenceNumber = sequenceNumber;
  screwdrivingSequenceAsJson.SequenceSteps[0].ProgParamOffTorque = maxTorque;
  screwdrivingSequenceAsJson.SequenceSteps[0].ProgParamMaxTorque =
    maxTorque * 1.2;
  screwdrivingSequenceAsJson.SequenceSteps[0].ProgParamSpeed = rotationalSpeed;

  const returnValue = JSON.stringify(screwdrivingSequenceAsJson);

  return returnValue;
};
