import DownloadIcon from "@mui/icons-material/Download";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Grow,
} from "@mui/material";
import { WizardItems } from "../../../../../Infrastructure/Analytics/Model/Categories/WizardEnums";
import { analyse_navigateWizard } from "../../../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { resetAstConfiguration } from "../../../../../Infrastructure/Configurations/AstConfiguration/Redux/AstConfigurationSlice";
import { resetProcessValues } from "../../../../../Infrastructure/Configurations/ExecuteProcess/Redux/ExecuteProcessSlice";
import { resetProcessConfiguration } from "../../../../../Infrastructure/Configurations/ProcessConfiguration/Redux/ProcessConfigurationSlice";
import { CreateReportRequestDto } from "../../../../../Infrastructure/CreateReport/Models/CreateReportRequestDto";
import { CustomerInformationsDto } from "../../../../../Infrastructure/CreateReport/Models/CustomerInformationsDto";
import { TighteningInformationDto } from "../../../../../Infrastructure/CreateReport/Models/TighteningInformationDto";
import { createReportFromBackend } from "../../../../../Infrastructure/CreateReport/Redux/CreateReportSlice";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Infrastructure/Redux/hooks";
import { TorqueUnit } from "../../../../Settings/Enum/TorqueUnit";
import { resetWizard, decreaseCurrentStepId } from "../../Redux/WizardSlice";
import { ElementDetailsDto } from "../../../../../Infrastructure/CreateReport/Models/ElementDetailsDto";
import { DemandTorqueDto } from "../../../../../Infrastructure/CreateReport/Models/DemandTorqueDto";
import { ElementAttachmentDto } from "../../../../../Infrastructure/CreateReport/Models/ElementAttachmentDto";
import { v4 as uuidv4 } from "uuid";
import { MqttRequestDto } from "../../../../../Infrastructure/Mqtt/Models/MqttRequestDto";
import { useContext, useState } from "react";
import { MqttContext } from "../../../../../Infrastructure/Mqtt/MqttService";
import {
  DefaultSetScrewdrivingRelatedSettingsRequestPayloadDto,
  SetScrewdrivingRelatedSettingsRequestPayloadDto,
} from "../../../../../Infrastructure/Mqtt/Models/Commands/SetScrewdrivingRelatedSettings/SetScrewdrivingRelatedSettingsRequestPayloadDto";
import { ProcessorInformationsDto } from "../../../../../Infrastructure/CreateReport/Models/ProcessorInformationsDto";
import { YesNoDialog } from "../../../../../Infrastructure/YesNoDialog/YesNoDialog";
import { FiArrowLeft, FiX } from "react-icons/fi";
import { resetStartConfiguration } from "../../../../../Infrastructure/Configurations/StartConfiguration/StartConfigurationSlice";
import { HardwareType } from "../../../../../Infrastructure/Mqtt/Enums/HardwareType";

const DisplayResults = () => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const { publishMqtt } = useContext(MqttContext);

  const customerDetailsConfiguration = useAppSelector(
    (store) => store.customerDetailsConfiguration
  );
  const elementDetails = useAppSelector((store) => store.elementDetails);
  const currentLanguage = useAppSelector((store) => store.settings.language);
  const reportValues = useAppSelector((store) => store.settings.reportValues);
  const astConfiguration = useAppSelector((store) => store.astConfiguration);
  const processConfiguration = useAppSelector(
    (store) => store.processConfiguration
  );
  const torqueUnit = useAppSelector((store) => store.settings.torqueUnit);
  const tighteningResults = useAppSelector(
    (store) => store.executeProcess.tighteningResults
  );
  const [getNextSecurityDialogOpen, setNextSecurityDialogOpen] =
    useState<boolean>(false);

  const createReport = () => {
    dispatch(
      createReportFromBackend({
        TighteningInformations: tighteningResults.map((x) => ({
          GraphFile: x.GraphFile,
          DurationInMilliseconds: x.DurationInMilliseconds,
          TighteningStatus: x.TighteningStatus,
          HeadRestMoment: x.HeadRestMomentNm,
          DestructiveMoment: x.DestructiveMomentNm,
          ComponentNr: x.Step.ComponentNr,
          ScrewdrivingPointNr: x.Step.ScrewdrivingPointNr,
        })) as TighteningInformationDto[],
        Date: new Date().toISOString(),
        Language: currentLanguage,
        CustomerInformations: {
          CustomerSurename: customerDetailsConfiguration.contactPersonSureName,
          CustomerLastname: customerDetailsConfiguration.contactPersonLastName,
          CustomerCompany: customerDetailsConfiguration.companyName,
          CustomerStreet: customerDetailsConfiguration.street,
          CustomerHouseNumber: customerDetailsConfiguration.houseNr,
          CustomerZipCode: customerDetailsConfiguration.zipCode,
          CustomerCity: customerDetailsConfiguration.city,
        } as CustomerInformationsDto,
        ElementDetails: {
          ElementDescription: elementDetails.elementDescription,
          ElementAttachments: elementDetails.elementAttachments.map((x) => ({
            ImageAsBase64: x.imageAsBase64,
            Imagedescription: x.imagedescription,
          })) as ElementAttachmentDto[],
        } as ElementDetailsDto,
        ProcessorInformations: {
          ProcessorFirstname: reportValues.processorFirstname
            ? reportValues.processorFirstname
            : "",
          ProcessorLastname: reportValues.processorLastname
            ? reportValues.processorLastname
            : "",
          ProcessorCompany: reportValues.processorCompany
            ? reportValues.processorCompany
            : "",
          ProcessorEmailAdress: reportValues.processorEmailAdress
            ? reportValues.processorEmailAdress
            : "",
        } as ProcessorInformationsDto,
        HardwareSerialNumber:
          astConfiguration.parameterInformation.controllerSerialNumber,
        ScrewdriverType: astConfiguration.parameterInformation.screwDriverType,
        ScrewdriverSerialNumber:
          astConfiguration.parameterInformation.screwDriverSerialNumber,
        ScrewdriverSpeedMin:
          astConfiguration.parameterInformation.speedLowerLimit,
        ScrewdriverSpeedMax:
          astConfiguration.parameterInformation.speedUpperLimit,
        ScrewdriverTorqueMin:
          astConfiguration.parameterInformation.torqueLowerLimit,
        ScrewdriverTorqueMax:
          astConfiguration.parameterInformation.torqueUpperLimit,
        ConfiguredTorque: processConfiguration.maxTorque,
        ConfiguredRotationalSpeed: processConfiguration.rotationalSpeed,
        TorqueUnit: TorqueUnit[torqueUnit],
        ControllerType: astConfiguration.parameterInformation.controllerType,
        GroupByTighteningPosition:
          processConfiguration.groupByTighteningPosition,
        AmountOfComponents: processConfiguration.amountOfComponents,
        AmountOfScrewdrivingPoints:
          processConfiguration.amountOfScrewdrivingPoints,
        DemandTorques: processConfiguration.demandTorques.map((x) => ({
          ScrewdrivingPointNr: x.screwdrivingPointNr,
          Torque: x.useDemandTorque ? Number(x.torque.replace(",", ".")) : null,
        })) as DemandTorqueDto[],
        WithDemandTorques: processConfiguration.withDemandTorques,
      } as CreateReportRequestDto)
    );
  };

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <Grow in={true} appear={true}>
          <Card square={true} elevation={12} sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%", position: "relative" }}>
              <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
                {getTranslatedText("DisplayResultsHeading")}
              </Typography>

              <div
                style={{ color: isDarkMode ? "#fff" : "#444" }}
                dangerouslySetInnerHTML={{
                  __html: getTranslatedText("DisplayResultsText"),
                }}
              ></div>

              <Button
                sx={{ mt: 2, mb: 2, width: "300px" }}
                variant="outlined"
                color="success"
                startIcon={<DownloadIcon />}
                onClick={() => {
                  createReport();
                }}
              >
                {getTranslatedText("CreateReport")}
              </Button>

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="dark"
                  variant="contained"
                  sx={{ margin: 2 }}
                  onClick={() => dispatch(decreaseCurrentStepId())}
                  className="BackButtonWizardContent"
                  startIcon={<FiArrowLeft />}
                >
                  {getTranslatedText("PreviousStepButtonText")}
                </Button>

                <Button
                  color="success"
                  variant="contained"
                  sx={{ margin: 2, color: "#fff" }}
                  onClick={() => setNextSecurityDialogOpen(true)}
                  className="NextButtonWizardContent"
                  endIcon={<FiX />}
                >
                  {getTranslatedText("FinishButtonText")}
                </Button>

                <YesNoDialog
                  title={getTranslatedText("IsReportSavedHeader")}
                  content={getTranslatedText("IsReportSavedContent")}
                  titleYesButton={getTranslatedText("ISavedTheReport")}
                  titleNoButton={getTranslatedText("Cancel")}
                  open={getNextSecurityDialogOpen}
                  onNoClick={() => setNextSecurityDialogOpen(false)}
                  onYesClick={() => {
                     //Wenn Programmwahl ungleich Handschrauber ist oder ControllerType gleich Ast11 ist, wird SetProgram übersprungen
                      var controllerTypeIsAst11 =
                      astConfiguration.parameterInformation.controllerType ===
                      HardwareType.Ast11;
                    var controllerTypeIsAst40 =
                      astConfiguration.parameterInformation.controllerType ===
                      HardwareType.Ast40;
                    if (
                      !controllerTypeIsAst11 &&
                      !controllerTypeIsAst40 &&
                      astConfiguration.initialProgramChoice === "PgChoiceSetup"
                    ) {
                        const request = {
                        TransactionGuid: uuidv4(),
                        Command: "SetScrewdrivingRelatedSettings",
                        Payload: {
                          ...DefaultSetScrewdrivingRelatedSettingsRequestPayloadDto,
                          IpAddressOrDns: astConfiguration.ipAddressOrDns,
                          Username: astConfiguration.username,
                          Password: astConfiguration.password,
                          ProgramNumber: astConfiguration.initialProgramNumber,
                          ProgramChoice: astConfiguration.initialProgramChoice,
                          StartEnable: astConfiguration.initialStartEnable,
                          StartEnableMode:
                            astConfiguration.inititalStartEnableMode,
                        } as SetScrewdrivingRelatedSettingsRequestPayloadDto,
                      } as MqttRequestDto;

                      publishMqtt(request);
                    }

                    dispatch(resetWizard());
                    dispatch(resetAstConfiguration());
                    dispatch(resetProcessValues());
                    dispatch(resetProcessConfiguration());
                    dispatch(resetStartConfiguration());
                    dispatch(
                      analyse_navigateWizard({
                        currentStep: WizardItems.DisplayResults,
                      })
                    );
                  }}
                  dangerRight={true}
                />
              </Box>
            </CardContent>
          </Card>
        </Grow>
      )}
    </LanguageConsumer>
  );
};

export default DisplayResults;
