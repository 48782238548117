import {
  Box,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import {
  LanguageConsumer,
  getTranslation,
} from "../../../Infrastructure/Internationalisation/TranslationService";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Infrastructure/Redux/hooks";
import {
  setLanguage,
  setTorqueUnit,
  toggleAppTheme,
} from "../../../Features/Settings/Redux/SettingsSlice";
import LockIcon from "@mui/icons-material/Lock";
import { Error } from "@mui/icons-material";
import { FiSun, FiMoon, FiGlobe } from "react-icons/fi";
import { CountryFlag } from "./Components/CountryFlag";
import { TorqueUnit } from "../../../Features/Settings/Enum/TorqueUnit";
import {
  settorqueLowerLimit,
  settorqueUpperLimit,
} from "../../../Infrastructure/Configurations/AstConfiguration/Redux/AstConfigurationSlice";
import { ConvertTorqueUnit } from "../../../Infrastructure/UnitConverter/TorqueUnitConverter";
import { UnitConvertStrategy } from "../../../Infrastructure/UnitConverter/Enum/UnitConvertStrategy";
import { setMaxTorque } from "../../../Infrastructure/Configurations/ProcessConfiguration/Redux/ProcessConfigurationSlice";
import { setTorqueUnitOfTightenings } from "../../../Infrastructure/Configurations/ExecuteProcess/Redux/ExecuteProcessSlice";
import { analyse_changeDefaultSettings } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { DefaultSettingsItems } from "../../../Infrastructure/Analytics/Model/Categories/SettingsEnums";
import { useCheckApiKey } from "../../../Infrastructure/LicenceService/Hooks/useCheckApiKey";

interface CustomIconProps {
  page: any;
  isMobile: boolean;
  isDarkMode: boolean;
  getTranslatedText: (t: string) => string;
  dispatch: (func: any) => any;
}

const CustomIcon = ({
  page,
  isMobile,
  isDarkMode,
  getTranslatedText,
  dispatch,
}: CustomIconProps) => {
  return (
    <Tooltip title={getTranslatedText(page.title)} arrow>
      <span className="tooltipSpan">
        {page.disabled && (
          <span className="tool notLicencedIcon">
            <LockIcon htmlColor="#fcba03" fontSize="small" />
          </span>
        )}
        {page.warning && (
          <span className="tool notLicencedIcon">
            <Error color="error" fontSize="small" />
          </span>
        )}
        <IconButton
          size={isMobile ? "small" : "medium"}
          disabled={page.disabled}
          onClick={page.onClick ? page.onClick : undefined}
          color="default"
          sx={{
            my: 2,
            color: isDarkMode ? "#c2c0c0" : "#444",
            background: isDarkMode
              ? "#424242 !important"
              : "#ebf2ee !important",
            borderRadius: "50%",
            minHeight: isMobile ? 28 : 40,
            minWidth: isMobile ? 28 : 40,
          }}
        >
          {page.icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};

const PageBar = () => {
  const dispatch = useAppDispatch();
  const { isExternalUser } = useCheckApiKey();
  const isDarkMode = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const isLangDe = useAppSelector((store) => store.settings.language === "de");
  const { torqueUnit } = useAppSelector((store) => store.settings);
  const astConfigParams = useAppSelector(
    (store) => store.astConfiguration.parameterInformation
  );
  const maxTorque = useAppSelector(
    (store) => store.processConfiguration.maxTorque
  );
  const isLicenced = useAppSelector(
    (store) => store.licenceService.licenceAuthentication.isLicenced
  );

  const isMobile = useMediaQuery("(max-width:500px)");
  const isRunningOnExtraSmallDevice = useMediaQuery("(max-width: 600px)");

  const [languageAnchorEl, setLanguageAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const openLanguageMenu = Boolean(languageAnchorEl);

  const [torqueUnitAnchorEl, setTorqueUnitAnchorEl] =
    useState<null | HTMLElement>(null);
  const openTorqueUnitMenu = Boolean(torqueUnitAnchorEl);

  const handleLanguageClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleTorqueUnitClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    setTorqueUnitAnchorEl(event.currentTarget);
  };

  const handleTorqueUnitClose = () => {
    setTorqueUnitAnchorEl(null);
  };

  const setLanguageFromPopup = (lang: string) => {
    dispatch(setLanguage(lang));
    dispatch(
      analyse_changeDefaultSettings({
        setting: DefaultSettingsItems.Language,
        newValue: lang,
      })
    );
    handleLanguageClose();
  };

  const setTorqueUnitFromPopup = (unit: TorqueUnit) => {
    const value = unit as number;

    dispatch(
      settorqueLowerLimit(
        ConvertTorqueUnit({
          sourceTorqueUnit: torqueUnit,
          sourceTorque: astConfigParams.torqueLowerLimit,
          targetTorqueUnit: value,
          convertStrategy: UnitConvertStrategy.RoundUp,
        })
      )
    );
    dispatch(
      settorqueUpperLimit(
        ConvertTorqueUnit({
          sourceTorqueUnit: torqueUnit,
          sourceTorque: astConfigParams.torqueUpperLimit,
          targetTorqueUnit: value,
          convertStrategy: UnitConvertStrategy.RoundDown,
        })
      )
    );

    dispatch(
      setMaxTorque(
        ConvertTorqueUnit({
          sourceTorqueUnit: torqueUnit,
          sourceTorque: maxTorque,
          targetTorqueUnit: value,
          convertStrategy: UnitConvertStrategy.RoundDown,
        })
      )
    );
    dispatch(
      setTorqueUnitOfTightenings({
        sourceTorqueUnit: torqueUnit,
        targetTorqueUnit: value,
      })
    );
    dispatch(setTorqueUnit(value));
    dispatch(
      analyse_changeDefaultSettings({
        setting: DefaultSettingsItems.TorqueUnit,
        newValue: value.toString(),
      })
    );
    handleTorqueUnitClose();
  };

  const pages = [
    {
      title: "ChangeLanguage",
      route: null,
      icon: <FiGlobe />,
      disabled: false,
      warning: false,
      hideForExternal: true,
      onClick: handleLanguageClick,
    },
    {
      title: "ChangeTorqueUnit",
      route: null,
      icon: (
        <Typography
          fontWeight={500}
          fontSize={
            torqueUnit === TorqueUnit.Nm
              ? "0.55em"
              : torqueUnit === TorqueUnit.Kgm
                ? "0.43em"
                : torqueUnit === TorqueUnit.Kgcm
                  ? "0.359em"
                  : "0.44em"
          }
        >
          {getTranslation(TorqueUnit[torqueUnit].toString())}
        </Typography>
      ),
      disabled: !isLicenced,
      warning: false,
      onClick: handleTorqueUnitClick,
    },
    {
      title: isDarkMode ? "LightMode" : "DarkMode",
      route: null,
      icon: isDarkMode ? <FiSun /> : <FiMoon />,
      disabled: !isLicenced,
      warning: false,
      hideForExternal: true,
      onClick: () => {
        dispatch(toggleAppTheme());
        dispatch(
          analyse_changeDefaultSettings({
            setting: DefaultSettingsItems.AppTheme,
            newValue: isDarkMode ? "light" : "dark",
          })
        );
      },
    },
  ];

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <React.Fragment>
          {!isRunningOnExtraSmallDevice && (
            <Box
              component="div"
              sx={{
                flexGrow: 0,
                display: "flex",
                alignItems: "center",
                gap: isMobile ? 1 : 2,
                justifyContent: "center",
                ml: "auto",
                mr: 0,
              }}
            >
              {pages.map((page, pageKeyBig) =>
                page.hideForExternal && isExternalUser ? null : page.route ===
                  null ? (
                  <Box>
                    <CustomIcon
                      page={page}
                      dispatch={dispatch}
                      getTranslatedText={getTranslatedText}
                      isMobile={isMobile}
                      isDarkMode={isDarkMode}
                      key={pageKeyBig}
                    />
                  </Box>
                ) : (
                  <NavLink
                    end
                    to={page.route}
                    key={pageKeyBig}
                    className={({ isActive }) =>
                      isActive ? "link-active" : ""
                    }
                  >
                    <CustomIcon
                      page={page}
                      dispatch={dispatch}
                      getTranslatedText={getTranslatedText}
                      isMobile={isMobile}
                      isDarkMode={isDarkMode}
                      key={pageKeyBig}
                    />
                  </NavLink>
                )
              )}
              <Menu
                anchorEl={languageAnchorEl}
                open={openLanguageMenu}
                onClose={handleLanguageClose}
                slotProps={{ root: { style: { zIndex: 9999 } } }}
                sx={{ borderRadius: 3 }}
              >
                <MenuItem onClick={() => setLanguageFromPopup("de")}>
                  <CountryFlag isoCode={"de"} sx={{ fontSize: "20px" }} />
                  <Typography sx={{ ml: 1, fontWeight: isLangDe ? 500 : 400 }}>
                    {getTranslatedText("German")}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => setLanguageFromPopup("en")}>
                  <CountryFlag isoCode={"us"} sx={{ fontSize: "20px" }} />
                  <Typography sx={{ ml: 1, fontWeight: !isLangDe ? 500 : 400 }}>
                    {getTranslatedText("English")}
                  </Typography>
                </MenuItem>
              </Menu>

              <Menu
                anchorEl={torqueUnitAnchorEl}
                open={openTorqueUnitMenu}
                onClose={handleTorqueUnitClose}
                slotProps={{ root: { style: { zIndex: 9999 } } }}
                sx={{ borderRadius: 3 }}
              >
                <MenuItem
                  onClick={() => setTorqueUnitFromPopup(TorqueUnit.Nm)}
                  id="TorqueUnit0"
                  sx={{ fontWeight: torqueUnit === TorqueUnit.Nm ? 500 : 400 }}
                >
                  {getTranslatedText("TorqueUnitNm")}{" "}
                </MenuItem>
                <MenuItem
                  onClick={() => setTorqueUnitFromPopup(TorqueUnit.Ncm)}
                  id="TorqueUnit1"
                  sx={{ fontWeight: torqueUnit === TorqueUnit.Ncm ? 500 : 400 }}
                >
                  {getTranslatedText("TorqueUnitNcm")}{" "}
                </MenuItem>
                <MenuItem
                  onClick={() => setTorqueUnitFromPopup(TorqueUnit.Ftlb)}
                  id="TorqueUnit2"
                  sx={{
                    fontWeight: torqueUnit === TorqueUnit.Ftlb ? 500 : 400,
                  }}
                >
                  {getTranslatedText("TorqueUnitFtlb")}{" "}
                </MenuItem>
                <MenuItem
                  onClick={() => setTorqueUnitFromPopup(TorqueUnit.Inlb)}
                  id="TorqueUnit3"
                  sx={{
                    fontWeight: torqueUnit === TorqueUnit.Inlb ? 500 : 400,
                  }}
                >
                  {getTranslatedText("TorqueUnitInlb")}{" "}
                </MenuItem>
                <MenuItem
                  onClick={() => setTorqueUnitFromPopup(TorqueUnit.Kgcm)}
                  id="TorqueUnit4"
                  sx={{
                    fontWeight: torqueUnit === TorqueUnit.Kgcm ? 500 : 400,
                  }}
                >
                  {getTranslatedText("TorqueUnitKgcm")}{" "}
                </MenuItem>
                <MenuItem
                  onClick={() => setTorqueUnitFromPopup(TorqueUnit.Kgm)}
                  id="TorqueUnit5"
                  sx={{ fontWeight: torqueUnit === TorqueUnit.Kgm ? 500 : 400 }}
                >
                  {getTranslatedText("TorqueUnitKgm")}{" "}
                </MenuItem>
              </Menu>
            </Box>
          )}
        </React.Fragment>
      )}
    </LanguageConsumer>
  );
};

export default PageBar;
