import { MainCategories } from "../MainCategories";

export enum GraphViewerActionEvent {
  GraphViewerActionOpen = MainCategories.GraphViewerAction + "Open",
  GraphViewerActionNavigate = MainCategories.GraphViewerAction + "Navigate",
}

export enum GraphViewerOpenTarget {
  QuickView = "QuickView",
  NewTab = "NewTab",
}

export enum GraphViewerNavigateDirection {
  Next = "Next",
  Back = "Back",
}
