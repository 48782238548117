import { createSlice } from "@reduxjs/toolkit";

export enum MqttConnectionState {
  Disconnected,
  ConnectedWithMqttBroker,
  Connecting,
  Reconnecting,
  ConnectionWithCloudConnectorActive,
}

export interface IMqttState {
  clientId: string;
  connectionState: MqttConnectionState;
  subscribtedTopics: string[];
  guidsToIgnore: string[];
  currentTransactionGuid: string;
}

export const initialStateMqtt: IMqttState = {
  clientId: "",
  connectionState: MqttConnectionState.Disconnected,
  subscribtedTopics: [],
  guidsToIgnore: [],
  currentTransactionGuid: "",
};

export const mqttStateSlice = createSlice({
  name: "mqttState",
  initialState: initialStateMqtt,
  reducers: {
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    subscribeToTopic: (state, action) => {
      state.subscribtedTopics.push(action.payload);
    },
    unsubscribeToTopic: (state, action) => {
      state.subscribtedTopics = state.subscribtedTopics.filter(
        (x) => x !== action.payload
      );
    },
    setConnectionState: (state, action) => {
      state.connectionState = action.payload;
    },
    addGuidToIgnore: (state, action) => {
      state.guidsToIgnore.push(action.payload);
    },
    removeGuidToIgnore: (state, action) => {
      state.guidsToIgnore = state.guidsToIgnore.filter(
        (x) => x !== action.payload
      );
    },
    setCurrentTransactionGuid: (state, action) => {
      state.currentTransactionGuid = action.payload;
    },
    removeCurrentTransactionGuid: (state) => {
      state.currentTransactionGuid = initialStateMqtt.currentTransactionGuid;
    },
  },
});

export const {
  setClientId,
  subscribeToTopic,
  unsubscribeToTopic,
  setConnectionState,
  addGuidToIgnore,
  removeGuidToIgnore,
  setCurrentTransactionGuid,
  removeCurrentTransactionGuid,
} = mqttStateSlice.actions;

export default mqttStateSlice.reducer;
