import Lottie, { Options } from "react-lottie";
import anim from "./JSON/Screwer.json";
import { SxProps, Box } from "@mui/material";

interface ScrewerAnimationProps {
  size: number;
  speed?: number;
  sx?: SxProps;
}

export const ScrewerAnimation = ({
  size,
  speed = 1,
  sx,
}: ScrewerAnimationProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  } as Options;

  return (
    <Box sx={sx}>
      <span>
        <Lottie
          speed={speed}
          options={defaultOptions}
          height={size}
          width={size}
        />
      </span>
    </Box>
  );
};
