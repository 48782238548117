import {
  setNotification,
  NotificationType,
} from "../../../../Layout/Notifications/Redux/NotificationsSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { SetProgramRequestPayloadDto } from "../../Models/Commands/SetProgram/SetProgramRequestPayloadDto";
import { DefaultUploadProgramRequestPayloadDto } from "../../Models/Commands/UploadProgram/UploadProgramRequestPayloadDto";
import { MqttRequestDto } from "../../Models/MqttRequestDto";
import { v4 as uuidv4 } from "uuid";
import { useHandleSetProgramCommandResult } from "./useHandleSetProgramCommandResult";
import { HardwareType } from "../../Enums/HardwareType";

interface UseHandleUploadProgramCommandArgs {
  mqttPublish: (payload: {}, topic?: string) => void;
}

export const useHandleUploadProgramCommandResult = ({
  mqttPublish,
}: UseHandleUploadProgramCommandArgs) => {
  const dispatch = useAppDispatch();
  const astConfiguration = useAppSelector((store) => store.astConfiguration);
  const processConfiguration = useAppSelector(
    (store) => store.processConfiguration
  );
  const { handleSetProgramCommandResultSuccess } =
    useHandleSetProgramCommandResult({ mqttPublish });

  const handleUploadProgramCommandResultSuccess = () => {
    dispatch(
      setNotification({
        type: NotificationType.success,
        text: "ProgramUploadSuccessfully",
      })
    );

    //Wenn Programmwahl ungleich Handschrauber ist oder ControllerType gleich Ast11 ist, wird SetProgram übersprungen
    var controllerTypeIsAst11 =
      astConfiguration.parameterInformation.controllerType ===
      HardwareType.Ast11;
    var controllerTypeIsAst40 =
      astConfiguration.parameterInformation.controllerType ===
      HardwareType.Ast40;
    var controllerTypeIsAst12 =
      astConfiguration.parameterInformation.controllerType ===
      HardwareType.Ast12;
    if (
      (controllerTypeIsAst12 && astConfiguration.initialProgramChoice !== "PgChoiceSetup") ||
      (controllerTypeIsAst11 && astConfiguration.initialProgramChoice !== "0") ||
      controllerTypeIsAst40
    ) {
      dispatch(
        setNotification({
          type: NotificationType.success,
          text:
            controllerTypeIsAst11 || controllerTypeIsAst40
              ? "SetProgramSkippedBecauseHardwareTypeIsAst11"
              : "SetProgramSkippedBecausePgChoiceUnequalHandScrewdriver",
        })
      );
      handleSetProgramCommandResultSuccess();
      return;
    }

    const setProgramRequest = {
      TransactionGuid: uuidv4(),
      Command: "SetProgram",
      Payload: {
        ...DefaultUploadProgramRequestPayloadDto,
        IpAddressOrDns: astConfiguration.ipAddressOrDns,
        Username: astConfiguration.username,
        Password: astConfiguration.password,
        SetProgramWithNumber: processConfiguration.programNumber,
      } as SetProgramRequestPayloadDto,
    } as MqttRequestDto;

    mqttPublish(setProgramRequest);
  };

  return { handleUploadProgramCommandResultSuccess };
};
