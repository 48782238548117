import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../Infrastructure/Redux/store";
import { TorqueUnit } from "../Enum/TorqueUnit";

export enum ShowCurrentValue {
  InChart,
  InSeriesLegend,
}

export interface IReportValues {
  processorFirstname: string;
  processorLastname: string;
  processorCompany: string;
  processorEmailAdress: string;
}

export interface SettingsState {
  language: string;
  appTheme: string;
  torqueUnit: TorqueUnit;
  graphViewerUrl: string;
  reportValues: IReportValues;
}

const getBrowserLanguage = () => {
  if (navigator.language.toLowerCase().startsWith("de")) {
    return "de";
  } else {
    return "en";
  }
};

export const initialStateSettings: SettingsState = {
  language: getBrowserLanguage(),
  appTheme: "light",
  torqueUnit: TorqueUnit.Nm,
  graphViewerUrl: "https://graphviewer.deprag.com",
  reportValues: {
    processorFirstname: "",
    processorLastname: "",
    processorCompany: "DEPRAG SCHULZ GMBH u. CO. KG",
    processorEmailAdress: "",
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialStateSettings,
  reducers: {
    setAllSettings: (_, action) => {
      return {
        ...(action.payload as SettingsState),
      };
    },
    setAppTheme: (state, action) => {
      state.appTheme = action.payload;
    },
    toggleAppTheme: (state) => {
      state.appTheme = state.appTheme === "dark" ? "light" : "dark";
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setTorqueUnit: (state, action) => {
      state.torqueUnit = action.payload;
    },
    setReportProcessorFirstname: (state, action) => {
      state.reportValues.processorFirstname = action.payload;
    },
    setReportProcessorLastname: (state, action) => {
      state.reportValues.processorLastname = action.payload;
    },
    setReportProcessorCompany: (state, action) => {
      state.reportValues.processorCompany = action.payload;
    },
    setReportProcessorEmailAdress: (state, action) => {
      state.reportValues.processorEmailAdress = action.payload;
    },
    resetReportValuesSettings: (state) => {
      state.reportValues = initialStateSettings.reportValues;
    },
  },
});

export const {
  setAllSettings,
  setLanguage,
  setTorqueUnit,
  setAppTheme,
  toggleAppTheme,
  setReportProcessorLastname,
  setReportProcessorFirstname,
  resetReportValuesSettings,
  setReportProcessorCompany,
  setReportProcessorEmailAdress,
} = settingsSlice.actions;

export const selectLanguage = (state: RootState) => state.settings.language;

export default settingsSlice.reducer;
