import { TorqueUnit } from "../../../../Features/Settings/Enum/TorqueUnit";
import {
  setControllerSerialNumber,
  setLoading,
  setscrewDriverSerialNumber,
  setscrewDriverType,
  setspeedLowerLimit,
  setspeedUpperLimit,
  settorqueLowerLimit,
  settorqueUpperLimit,
} from "../../../Configurations/AstConfiguration/Redux/AstConfigurationSlice";
import {
  setMaxTorque,
  setRotationalSpeed,
} from "../../../Configurations/ProcessConfiguration/Redux/ProcessConfigurationSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { ConvertTorqueUnit } from "../../../UnitConverter/TorqueUnitConverter";
import { GetToolDataResultPayloadDto } from "../../Models/Commands/GetToolData/GetToolDataResultPayloadDto";
import {
  DefaultValidateAstSettingsRequestPayloadDto,
  ValidateAstSettingsRequestPayloadDto,
} from "../../Models/Commands/ValidateAstSettings/ValidateAstSettingsRequestPayloadDto";
import { MqttRequestDto } from "../../Models/MqttRequestDto";
import { v4 as uuidv4 } from "uuid";
import { UnitConvertStrategy } from "../../../UnitConverter/Enum/UnitConvertStrategy";

interface UseHandleGetToolDataCommandArgs {
  mqttPublish: (payload: {}, topic?: string) => void;
}

export const useHandleGetToolDataCommandResult = ({
  mqttPublish,
}: UseHandleGetToolDataCommandArgs) => {
  const dispatch = useAppDispatch();
  const currentTorqueUnit = useAppSelector(
    (store) => store.settings.torqueUnit
  );
  const astConfiguration = useAppSelector((store) => store.astConfiguration);

  const handleGetToolDataCommandResultSuccess = (result: {}) => {
    const parsedResult = result as GetToolDataResultPayloadDto;

    dispatch(setControllerSerialNumber(parsedResult.ControllerSerialNumber));
    dispatch(setscrewDriverType(parsedResult.ScrewdriverType));
    dispatch(setscrewDriverSerialNumber(parsedResult.ScrewdriverSerialNumber));
    dispatch(setspeedLowerLimit(parsedResult.SpeedLowerLimit));
    dispatch(setspeedUpperLimit(parsedResult.SpeedUpperLimit));
    dispatch(
      settorqueLowerLimit(
        ConvertTorqueUnit({
          sourceTorqueUnit: (TorqueUnit as any)[parsedResult.TorqueUnit],
          sourceTorque: parsedResult.TorqueLowerLimit,
          targetTorqueUnit: currentTorqueUnit,
          convertStrategy: UnitConvertStrategy.RoundUp,
        })
      )
    );
    dispatch(
      settorqueUpperLimit(
        ConvertTorqueUnit({
          sourceTorqueUnit: (TorqueUnit as any)[parsedResult.TorqueUnit],
          sourceTorque: parsedResult.TorqueUpperLimit,
          targetTorqueUnit: currentTorqueUnit,
          convertStrategy: UnitConvertStrategy.RoundDown,
        })
      )
    );

    dispatch(
      setMaxTorque(
        ConvertTorqueUnit({
          sourceTorqueUnit: (TorqueUnit as any)[parsedResult.TorqueUnit],
          sourceTorque: parsedResult.TorqueUpperLimit,
          targetTorqueUnit: currentTorqueUnit,
          convertStrategy: UnitConvertStrategy.RoundDown,
        })
      )
    );
    dispatch(setRotationalSpeed(parsedResult.SpeedLowerLimit));

    const request = {
      TransactionGuid: uuidv4(),
      Command: "ValidateAstSettings",
      Payload: {
        ...DefaultValidateAstSettingsRequestPayloadDto,
        Username: astConfiguration.username,
        Password: astConfiguration.password,
        IpAddressOrDns: astConfiguration.ipAddressOrDns,
      } as ValidateAstSettingsRequestPayloadDto,
    } as MqttRequestDto;

    mqttPublish(request);
  };

  const handleGetToolDataCommandResultFailed = () => {
    dispatch(setLoading(false));
  };

  return {
    handleGetToolDataCommandResultSuccess,
    handleGetToolDataCommandResultFailed,
  };
};
