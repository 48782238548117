import {
  setNotification,
  NotificationType,
} from "../../../../Layout/Notifications/Redux/NotificationsSlice";
import {
  setControllerType,
  setDeviceType,
  setLoading,
} from "../../../Configurations/AstConfiguration/Redux/AstConfigurationSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { DetectedType } from "../../Enums/DetectedType";
import { HardwareType } from "../../Enums/HardwareType";
import { DetectDeviceTypeResultPayloadDto } from "../../Models/Commands/DetectDeviceType/DetectDeviceTypeResultPayloadDto";
import { DefaultGetToolDataRequestPayloadDto } from "../../Models/Commands/GetToolData/GetToolDataRequestPayloadDto";
import { MqttRequestDto } from "../../Models/MqttRequestDto";
import { v4 as uuidv4 } from "uuid";

interface UseHandleDetectDeviceTypeCommandArgs {
  mqttPublish: (payload: {}, topic?: string) => void;
}

export const useHandleDetectDeviceTypeCommandResult = ({
  mqttPublish,
}: UseHandleDetectDeviceTypeCommandArgs) => {
  const dispatch = useAppDispatch();
  const astConfiguration = useAppSelector((store) => store.astConfiguration);

  const handleDetectDeviceTypeCommandResultSuccess = (result: {}) => {
    const inputResult = result as DetectDeviceTypeResultPayloadDto;
    const parsedResult = {
      Type: (DetectedType as any)[inputResult.Type],
      AstType: (HardwareType as any)[inputResult.AstType],
    } as DetectDeviceTypeResultPayloadDto;

    dispatch(setControllerType(parsedResult.AstType));
    dispatch(setDeviceType(parsedResult.Type));

    if (
      parsedResult.AstType !== HardwareType.Ast11 &&
      parsedResult.AstType !== HardwareType.Ast12 &&
      parsedResult.AstType !== HardwareType.Ast40
    ) {
      dispatch(
        setNotification({
          type: NotificationType.error,
          text: "SFAOnlyAvailableforAst11AndAst12",
        })
      );
      dispatch(setLoading(false));
      return;
    }

    const getToolDataRequest = {
      TransactionGuid: uuidv4(),
      Command: "GetToolData",
      Payload: {
        ...DefaultGetToolDataRequestPayloadDto,
        IpAddressOrDns: astConfiguration.ipAddressOrDns,
        Username: astConfiguration.username,
        Password: astConfiguration.password,
        AstType: parsedResult.AstType,
      },
    } as MqttRequestDto;

    mqttPublish(getToolDataRequest);
  };

  const handleDetectDeviceTypeCommandResultFailed = () => {
    dispatch(setLoading(false));
  };

  return {
    handleDetectDeviceTypeCommandResultSuccess,
    handleDetectDeviceTypeCommandResultFailed,
  };
};
