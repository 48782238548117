import { CreateScrewdrivingSequence } from "../../../Configurations/Logic/CreateScrewdrivingSequence";
import { useAppSelector } from "../../../Redux/hooks";
import {
  DefaultUploadProgramRequestPayloadDto,
  UploadProgramRequestPayloadDto,
} from "../../Models/Commands/UploadProgram/UploadProgramRequestPayloadDto";
import { MqttRequestDto } from "../../Models/MqttRequestDto";
import { v4 as uuidv4 } from "uuid";

interface UseHandleLockDevieCommandArgs {
  mqttPublish: (payload: {}, topic?: string) => void;
}

export const useHandleLockDeviceCommandResult = ({
  mqttPublish,
}: UseHandleLockDevieCommandArgs) => {
  const astConfiguration = useAppSelector((store) => store.astConfiguration);
  const currentTorqueUnit = useAppSelector(
    (store) => store.settings.torqueUnit
  );
  const processConfiguration = useAppSelector(
    (store) => store.processConfiguration
  );

  const handleLockDeviceCommandResultSuccess = () => {
    const screwdrivingSequenceForSFA = CreateScrewdrivingSequence({
      torqueUnit: currentTorqueUnit,
      sequenceNumber: processConfiguration.programNumber,
      maxTorque: processConfiguration.maxTorque,
      rotationalSpeed: processConfiguration.rotationalSpeed,
    });

    const uploadProgramRequest = {
      TransactionGuid: uuidv4(),
      Command: "UploadProgram",
      Payload: {
        ...DefaultUploadProgramRequestPayloadDto,
        IpAddressOrDns: astConfiguration.ipAddressOrDns,
        Username: astConfiguration.username,
        Password: astConfiguration.password,
        ContentToSet: screwdrivingSequenceForSFA,
      } as UploadProgramRequestPayloadDto,
    } as MqttRequestDto;

    mqttPublish(uploadProgramRequest);
  };

  return { handleLockDeviceCommandResultSuccess };
};
