export interface SetProgramRequestPayloadDto {
  IpAddressOrDns: string;
  Username: string;
  Password: string;
  SetProgramWithNumber: number;
  TimeOutInMilliseconds: number;
}

export const DefaultSetProgramRequestPayloadDto: SetProgramRequestPayloadDto = {
  IpAddressOrDns: "",
  Username: "",
  Password: "",
  SetProgramWithNumber: 0,
  TimeOutInMilliseconds: 30000,
};
