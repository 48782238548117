import { Box, Button, Card, Grid } from "@mui/material";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  useAppDispatch,
  useAppSelector,
} from "../../Infrastructure/Redux/hooks";
import LockIcon from "@mui/icons-material/Lock";
import {
  setIsMyDeprag,
  setStandalone,
  resetIsMyDeprag,
  resetStandalone,
} from "../../Layout/Navigation/Redux/NavigationSlice";
import { CustomImage } from "../../Layout/CustomImage/CustomImage";
import { useCheckApiKey } from "../../Infrastructure/LicenceService/Hooks/useCheckApiKey";
import { useCheckQueryParams } from "./useCheckQueryParams";

const cards = [
  {
    titleKey: "CaptureTorque",
    descriptionKey: "CaptureTorqueDescription",
    imagePath: "Images/LandingPage/landing-screw.svg",
  },
  {
    titleKey: "SecureQuality",
    descriptionKey: "SecureQualityDescription",
    imagePath: "Images/LandingPage/landing-check.svg",
  },
  {
    titleKey: "SaveCosts",
    descriptionKey: "SaveCostsDescription",
    imagePath: "Images/LandingPage/landing-cost.svg",
  },
];

export const LandingPage = () => {
  const isDarkTheme = useAppSelector(
    (store) => store.settings.appTheme === "dark"
  );
  const dispatch = useAppDispatch();
  const curLocation = useLocation();
  const isLicenced = useAppSelector(
    (store) => store.licenceService.licenceAuthentication.isLicenced
  );

  useEffect(() => {
    if (
      curLocation.search.includes("standalone") ||
      curLocation.search.includes("myDeprag")
    ) {
      curLocation.search.includes("myDeprag")
        ? dispatch(setIsMyDeprag())
        : dispatch(setStandalone());
    } else {
      if (window.location === window.parent.location) {
        dispatch(resetIsMyDeprag());
        dispatch(resetStandalone());
      }
    }
  }, [curLocation, dispatch]);

  useCheckQueryParams();
  useCheckApiKey();

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <React.Fragment>
          <Helmet>
            <title>{getTranslatedText("Schraubfallanalyse")}</title>
          </Helmet>
          <Box
            component={"div"}
            sx={{
              flex: 1,
            }}
          >
            <Box
              component={"div"}
              sx={{
                maxWidth: "1440px",
                px: "24px",
                ml: "auto",
                mr: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 3,
                gap: 6,
              }}
            >
              <img
                src={
                  isDarkTheme
                    ? "Images/LandingPage/landing-logo-dark.svg"
                    : "Images/LandingPage/landing-logo.svg"
                }
                alt="landing-logo"
                width={"100%"}
              />
              <Grid container spacing={6}>
                {cards.map((card) => (
                  <Grid
                    item
                    md={4}
                    xs={12}
                    justifyContent={"center"}
                    display={"flex"}
                  >
                    <Card
                      elevation={12}
                      sx={{
                        boxShadow: isDarkTheme
                          ? "-3px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)"
                          : "0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)",
                        height: 230,
                        maxWidth: 400,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <CustomImage
                        blurIntensity={15}
                        hasOverlay
                        centerOverlayText
                        overlayTitle={getTranslatedText(card.titleKey)}
                        overlayDescription={getTranslatedText(
                          card.descriptionKey
                        )}
                        height={"100%"}
                        borderCustomRadius={[5, 5, 5, 5]}
                        style={{ width: "100%" }}
                        src={card.imagePath}
                      />
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Box component={"div"} position="relative" sx={{ pb: 4 }}>
                <span className="tooltipSpan">
                  {!isLicenced && (
                    <span
                      className="tool notLicencedIcon"
                      style={{ right: -6, top: 5 }}
                    >
                      <LockIcon htmlColor="#fcba03" />
                    </span>
                  )}
                  <Button
                    color="success"
                    variant="contained"
                    disabled={!isLicenced}
                    size="large"
                    component={NavLink}
                    to={isLicenced ? "/home" : ""}
                    sx={{
                      mt: 2,
                      display: "flex",
                      gap: 2,
                      minWidth: 170,
                      color: "white",
                    }}
                  >
                    {getTranslatedText("LandingPageButtonText")}
                  </Button>
                </span>
              </Box>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </LanguageConsumer>
  );
};
