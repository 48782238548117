import { createSlice } from "@reduxjs/toolkit";

export interface NavigationState {
  isStandalone: boolean;
  isMyDeprag: boolean;
}

const initialState: NavigationState = {
  isStandalone: false,
  isMyDeprag: false,
};

export const NavigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setStandalone: (state) => {
      state.isStandalone = true;
    },
    resetStandalone: (state) => {
      state.isStandalone = false;
    },
    setIsMyDeprag: (state) => {
      state.isMyDeprag = true;
    },
    resetIsMyDeprag: (state) => {
      state.isMyDeprag = false;
    },
  },
});

export const {
  setIsMyDeprag,
  resetIsMyDeprag,
  setStandalone,
  resetStandalone,
} = NavigationSlice.actions;

export default NavigationSlice.reducer;
